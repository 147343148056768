import ContentsTypes from "shared/enums/contentsTypes";
import DynamicTemplate from "templates/dynamic/DynamicTemplate";
export default {
    key: "DYNAMIC",
    label: "Standard",
    templateImage: "/template-dynamic.png",
    Component: DynamicTemplate,
    initialContents: [
        {
            key: "dynamicPart",
            type: ContentsTypes.DYNAMIC,
            value: "",
        },
    ],
};
