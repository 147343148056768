import types from "shared/enums/contentsTypes";
export var stepV2 = {
    label: "Étape",
    editionModalPosition: "top",
    type: types.STEP_V2,
    children: [
        {
            label: "content",
            key: "title",
            type: types.TEXT,
            value: "",
        },
        {
            label: "content",
            key: "content",
            type: types.STEP_V2_CONTENT,
            value: "",
        },
    ],
};
