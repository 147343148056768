import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Image from "shared/components/contents/image/Image";
import ImageContainer from "shared/components/contents/imageContainer/ImageContainer";
import Link from "shared/components/contents/link/Link";
var ArticleDetailSubpage = function (props) {
    var page = props.page;
    var title = page.title;
    return (_jsxs(Link, { page: page, children: [page.image && (_jsx(Box, { sx: { borderRadius: "10px", overflow: "hidden", position: "relative", display: "block" }, children: _jsx(ImageContainer, { ratio: 41, children: _jsx(Image, { src: page.image.url, alt: page.image.alt, title: page.image.title }) }) })), _jsx(Typography, { variant: "h3", sx: { mt: 1.5, mb: 3 }, children: title })] }, page.id));
};
export default ArticleDetailSubpage;
