var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/system/Box";
import ImageContainer from "shared/components/contents/imageContainer/ImageContainer";
var PresentationBlock = function (props) {
    var id = props.id, image = props.image, imagePosition = props.imagePosition, title = props.title, subtitle = props.subtitle, description = props.description, footnote = props.footnote, backgroundColor = props.backgroundColor, others = __rest(props, ["id", "image", "imagePosition", "title", "subtitle", "description", "footnote", "backgroundColor"]);
    return (_jsxs(Grid, __assign({ container: true, spacing: 2 }, others, { children: [image && imagePosition === "left" && (_jsx(Grid, { item: true, xs: 12, md: 8, children: _jsx(ImageContainer, { ratio: "", children: image }) })), _jsx(Grid, { item: true, xs: 12, md: image ? 4 : 12, sx: { display: "flex", alignItems: "center" }, children: _jsxs(Box, { sx: {
                        bgcolor: backgroundColor,
                        p: 3.75,
                        borderRadius: 6.25,
                        width: "100%",
                    }, children: [title && (_jsx(Typography, { variant: "h3", sx: { mb: 1.25 }, children: title })), subtitle && (_jsx(Typography, { variant: "h4", sx: { color: "#FFC617", fontWeight: "normal" }, children: subtitle })), description && (_jsx(Typography, { variant: "body1", sx: { my: 2, color: "text.secondary", lineHeight: 1.5 }, children: description })), footnote && (_jsx(Typography, { variant: "body1", sx: { color: "#BAB8B8" }, children: footnote }))] }) }), image && imagePosition === "right" && (_jsx(Grid, { item: true, xs: 12, md: 8, children: _jsx(ImageContainer, { ratio: "", children: image }) }))] }), id));
};
PresentationBlock.defaultProps = {
    id: "",
    image: null,
    imagePosition: "left",
    title: "",
    subtitle: "",
    description: "",
    footnote: "",
    backgroundColor: "#FFFFFF",
};
export default PresentationBlock;
