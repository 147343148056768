import { create } from "zustand";
var useAdminPageVersionStore = create(function (set) { return ({
    currentPageVersion: undefined,
    setCurrentPageVersion: function (newPageVersion) {
        set({ currentPageVersion: newPageVersion });
    },
    versionIsCanceled: false,
    setVersionIsCanceled: function (value) {
        set({ versionIsCanceled: value });
    },
    versionHasChanged: false,
    setVersionHasChanged: function (value) {
        set({ versionHasChanged: value });
    },
}); });
export default useAdminPageVersionStore;
