import { jsx as _jsx } from "react/jsx-runtime";
import { Box } from "@mui/material";
var ImageContainer = function (props) {
    var ratio = props.ratio, _a = props.children, children = _a === void 0 ? null : _a;
    if (Number.isNaN(parseFloat(ratio.toString()))) {
        return children;
    }
    return (_jsx(Box, { sx: {
            pt: "".concat(ratio, "%"),
            position: "relative",
            flex: "1 1 0px",
            "@media print": {
                display: "none",
            },
        }, children: _jsx(Box, { sx: {
                position: "absolute",
                top: 0,
                left: 0,
                height: "100%",
                width: "100%",
            }, children: children }) }));
};
export default ImageContainer;
