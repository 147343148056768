import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { getQueryParams } from "shared/utils/urlUtils";
import InfiniteScroll from "react-infinite-scroller";
import Block from "shared/components/Block";
import Icon from "shared/components/Icon";
import Link from "shared/components/contents/link/Link";
import Text from "shared/components/contents/text/Text";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Loader from "shared/components/Loader";
import SearchService from "shared/services/SearchService";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import { useSites } from "shared/hooks/SiteHook";

const linkStyled = {
  border: "2px solid",
  borderColor: "cobalt.bleuDigital100",
  color: "cobalt.bleuDigital100",
  borderRadius: "30px 0",
  display: "block",
  p: 2,
  "&:hover": {
    bgcolor: "cobalt.bleuDigital05",
    color: "cobalt.bleuDigital100",
  },
  "&:active": {
    bgcolor: "cobalt.bleuDigital100",
    borderColor: "cobalt.click",
    color: "cobalt.click",
  },
};

const ResultItem = (props) => {
  const { result } = props;
  const { _source } = result;
  const { breadcrumb, shortDescription, title } = _source || {};

  return (
    <Box sx={linkStyled}>
      <Link page={_source}>
        <Grid container alignItems="center">
          <Grid item sx={{ flexGrow: 1 }}>
            <Typography variant="h3">{title}</Typography>
            <Typography variant="subtitle2">{breadcrumb.map((b) => b.title).join(" / ")}</Typography>
            <Typography variant="body2" component="div" sx={{ mt: 1 }}>
              <Text
                sx={{
                  "&> *": {
                    m: 0,
                  },
                }}
              >
                {shortDescription}
              </Text>
            </Typography>
          </Grid>
          <Grid item>
            <Box component="span" sx={{ mr: 2.5, fontSize: "2.3rem", fontWeight: 300 }}>
              <Icon icon="long-arrow-right" type="fal" />
            </Box>
          </Grid>
        </Grid>
      </Link>
    </Box>
  );
};

const resultPerPage = 6;

const ResultsSearch = (props) => {
  const { location } = props;
  const queryParams = getQueryParams(location.search);
  const { q: search } = queryParams;
  const { currentSite: site } = useSites();

  const [results, setResults] = useState([]);
  const [total, setTotal] = useState(null);
  const [hasMoreResults, setHasMoreResults] = useState(true);

  const loadResults = React.useCallback(
    (page) => {
      if (page !== undefined) {
        setHasMoreResults(false);
        return SearchService.search({
          query: queryParams.q,
          from: resultPerPage * (page - 1),
          size: resultPerPage,
          siteId: site.id,
        }).then((response) => {
          const total = response?.hits?.total?.value;
          const data = response?.hits?.hits || [];
          setResults((prev) => [...prev, ...data]);
          setTotal(total);
          setHasMoreResults(data.length === resultPerPage);
        });
      }
    },
    [queryParams.q, site.id],
  );

  useEffect(() => {
    setResults([]);
    loadResults(1);
  }, [loadResults]);

  const { trackPageView } = useMatomo();

  useEffect(() => {
    trackPageView({ documentTitle: "Recherche" });
  }, [search, trackPageView]);

  return (
    <Container>
      <Block>
        <Typography variant="h3">
          {total} résultat(s) pour la recherche : &quot;{search}&quot;
        </Typography>
      </Block>
      {results.length > 0 && (
        <>
          <Divider sx={{ mt: 4, mb: 6 }} />
          <InfiniteScroll
            loadMore={loadResults}
            hasMore={hasMoreResults}
            loader={<Loader key={0} />}
            pageStart={1}
            style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
          >
            <Grid container justifyContent="space-between" spacing={2}>
              <Grid item xs={12}>
                <Grid container direction="column" spacing={2}>
                  {results &&
                    results.map(
                      (r) =>
                        r._source && (
                          <Grid item key={r._source.id}>
                            <ResultItem result={r} />
                          </Grid>
                        ),
                    )}
                </Grid>
              </Grid>
            </Grid>
          </InfiniteScroll>
        </>
      )}
    </Container>
  );
};

ResultsSearch.propTypes = {
  location: PropTypes.shape().isRequired,
};

export default ResultsSearch;
