export var searchBarStyled = {
    inputStyled: {
        styleOverrides: {
            root: {
                bgcolor: "white",
                border: "1px solid",
                borderColor: "primary.main",
                px: 2,
                py: 0,
                height: "56px",
                fontSize: "1em",
                "& input": {
                    height: "1.4rem",
                },
                "& button": {
                    mr: -1.5,
                    color: "primary.dark",
                },
            },
        },
    },
    suggestionsStyled: {
        styleOverrides: {
            root: {
                borderBottom: "1px solid",
                borderColor: "primary.main",
                "&:hover, &[aria-selected=true]": {
                    bgcolor: "secondary.light",
                },
            },
        },
    },
    autoSuggestStyled: {
        styleOverrides: {
            root: {
                container: {
                    width: "100%",
                    position: "relative",
                    padding: 0,
                },
                suggestionsContainer: {
                    position: "absolute",
                    left: 0,
                    right: 0,
                    backgroundColor: "common.consent.wrap.background",
                    border: "1px solid",
                    borderColor: "primary.main",
                    borderBottom: "none",
                    borderTop: "none",
                    zIndex: 100,
                },
                suggestionsList: {
                    listStyleType: "none",
                    paddingLeft: 0,
                    margin: 0,
                },
            },
        },
    },
};
