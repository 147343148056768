export var scrollableTabsStyled = {
    rootStyled: {
        styleOverrides: {
            root: {
                flexGrow: 1,
                width: "100%",
            },
        },
    },
    indicatorStyled: {
        styleOverrides: {
            root: {
                "&:before": {
                    content: "\"\"",
                    display: "block",
                    height: "100%",
                    width: "30%",
                    m: "0 auto",
                },
            },
        },
    },
    panelStyled: {
        styleOverrides: {
            root: {
                mt: { md: 4 },
            },
        },
    },
    tabStyled: {
        styleOverrides: {
            root: {
                minWidth: 0,
                p: 0,
                mr: { xs: 3, md: 10 },
                fontSize: "0.875rem",
                lineHeight: "1.375rem",
            },
        },
    },
    horizontalContainerStyled: {
        styleOverrides: {
            root: {
                mt: 4,
                mx: { md: -5 },
            },
        },
    },
};
