import React from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import { Box, Breadcrumbs, Typography, useMediaQuery } from "@mui/material";
import Link from "shared/components/contents/link/Link";
import { CobaltIcon } from "@docaposte-agility/da-design-system";
import { usePageVersion } from "frontoffice/hooks/PageVersionHook";

const BreadcrumbItem = (props) => {
  const { item, ...others } = props;
  return (
    <Link underline="none" color="inherit" title={item.title} url={item.fullPath || "/"} {...others}>
      <Typography variant="h5" color="inherit">
        {item.title}
      </Typography>
    </Link>
  );
};

BreadcrumbItem.propTypes = {
  item: PropTypes.shape({
    title: PropTypes.string,
    fullPath: PropTypes.string,
  }).isRequired,
};

const Breadcrumb = () => {
  const { currentPageVersion } = usePageVersion();

  const { breadcrumb = [] } = currentPageVersion || {};

  const theme = useTheme();
  const isMobile = !useMediaQuery(theme.breakpoints.up("md"));

  const parentPage = React.useMemo(() => {
    return breadcrumb && breadcrumb.length > 1 && breadcrumb[breadcrumb.length - 2];
  }, [breadcrumb]);

  return breadcrumb && breadcrumb.length > 1 ? (
    isMobile ? (
      <Breadcrumbs aria-label="Fil d'ariane">
        <Link underline="none" color="inherit" title={parentPage.title} url={parentPage.fullPath || "/"}>
          <Typography variant="h5" color="inherit">
            <Box>
              <CobaltIcon name="chevron-left" sx={{ fontSize: 16, verticalAlign: "bottom" }} />
              &nbsp;
              {parentPage.title}
            </Box>
          </Typography>
        </Link>
      </Breadcrumbs>
    ) : (
      <Breadcrumbs maxItems={3} itemsAfterCollapse={2} aria-label="Fil d'ariane">
        {breadcrumb.map((breadcrumbItem, index) =>
          index !== breadcrumb.length - 1 ? (
            <BreadcrumbItem key={breadcrumbItem.pageId} item={breadcrumbItem} />
          ) : (
            <Typography key={breadcrumbItem.pageId} title={breadcrumbItem.title} variant="h5" color="textSecondary">
              {breadcrumbItem.title}
            </Typography>
          ),
        )}
      </Breadcrumbs>
    )
  ) : (
    <></>
  );
};

Breadcrumb.propTypes = {};

Breadcrumb.defaultProps = {};

export default Breadcrumb;
