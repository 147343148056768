var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/system/Box";
import ImageContainer from "shared/components/contents/imageContainer/ImageContainer";
import BlockTitle from "shared/components/contents/blockTitle/BlockTitle";
var PresentationCard = function (props) {
    var _a = props.id, id = _a === void 0 ? "" : _a, image = props.image, _b = props.imagePosition, imagePosition = _b === void 0 ? "left" : _b, title = props.title, description = props.description, _c = props.colorText, colorText = _c === void 0 ? "#343434" : _c, _d = props.backgroundColor, backgroundColor = _d === void 0 ? "#FFFFFF" : _d, others = __rest(props, ["id", "image", "imagePosition", "title", "description", "colorText", "backgroundColor"]);
    return (_jsxs(Box, { sx: { backgroundColor: backgroundColor }, children: [title && _jsx(BlockTitle, { fontWeight: "bold", text: title }), _jsxs(Grid, __assign({ container: true, spacing: 2, sx: { mt: 1.5, alignItems: "center" } }, others, { children: [image && imagePosition === "left" && (_jsx(Grid, { item: true, xs: 12, md: 3, children: _jsx(ImageContainer, { ratio: "", children: image }) })), _jsx(Grid, { item: true, xs: 12, md: image ? 9 : 12, sx: { display: "flex", alignItems: "center" }, children: _jsx(Box, { sx: {
                                width: "100%",
                            }, children: description && (_jsx(Typography, { variant: "body1", sx: { color: colorText, textOverflow: "ellipsis", overflow: "hidden" }, children: description })) }) }), image && imagePosition === "right" && (_jsx(Grid, { item: true, xs: 12, md: 3, children: _jsx(ImageContainer, { ratio: "", children: image }) }))] }), id)] }));
};
export default PresentationCard;
