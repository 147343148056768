var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Tooltip } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "shared/components/contents/button/Button";
import useEnvironment from "shared/hooks/useEnvironment";
import { recaptchaStyled } from "components/ContactForm";
import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import CaptchaService from "services/CaptchaService";
import ContactService from "services/ContactService";
import useCookieStore from "shared/store/CookieStore";
import useAdminPageVersionStore from "administration/store/AdminPageVersionStore";
import { useShallow } from "zustand/react/shallow";
var buttonStyled = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    pb: 3,
};
var Mailto = function (props) {
    var text = props.text, recipient = props.recipient, subject = props.subject, body = props.body, contentId = props.contentId;
    var recaptcha_site_key_invisible = useEnvironment().recaptcha_site_key_invisible;
    var currentPageVersion = useAdminPageVersionStore(useShallow(function (state) { return ({
        currentPageVersion: state.currentPageVersion,
    }); })).currentPageVersion;
    var pageId = currentPageVersion.id;
    var recaptchaRef = React.createRef();
    var _a = useState(false), recaptcha = _a[0], setRecaptcha = _a[1];
    var _b = useState(false), loader = _b[0], setLoader = _b[1];
    var googleRecaptchaConsent = useCookieStore(function (state) { return ({
        googleRecaptchaConsent: state.googleRecaptchaConsent,
    }); }).googleRecaptchaConsent;
    var sendCandidate = function () { return __awaiter(void 0, void 0, void 0, function () {
        var webmail;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    setLoader(true);
                    if (!recaptcha) return [3 /*break*/, 3];
                    if (!window) return [3 /*break*/, 2];
                    return [4 /*yield*/, ContactService.getWebmail({ contentId: contentId, pageId: pageId })];
                case 1:
                    webmail = _b.sent();
                    if (webmail) {
                        window.location.href = webmail;
                    }
                    setLoader(false);
                    _b.label = 2;
                case 2: return [3 /*break*/, 4];
                case 3:
                    if (!recaptcha_site_key_invisible) {
                        if (recipient) {
                            window.location.href = "mailto:".concat(recipient, "?subject=").concat(subject, "&body=").concat(body);
                        }
                        setLoader(false);
                    }
                    else {
                        (_a = recaptchaRef.current) === null || _a === void 0 ? void 0 : _a.execute();
                    }
                    _b.label = 4;
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var checkUser = function (value) { return __awaiter(void 0, void 0, void 0, function () {
        var recaptchaValidated, webmail;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, CaptchaService.verifyCaptcha(value)];
                case 1:
                    recaptchaValidated = _a.sent();
                    setRecaptcha(recaptchaValidated.success);
                    if (!recaptchaValidated.success) return [3 /*break*/, 4];
                    setTimeout(function () {
                        setRecaptcha(false);
                    }, 60000);
                    if (!window) return [3 /*break*/, 3];
                    return [4 /*yield*/, ContactService.getWebmail({ contentId: contentId, pageId: pageId })];
                case 2:
                    webmail = _a.sent();
                    window.location.href = webmail;
                    setLoader(false);
                    _a.label = 3;
                case 3: return [3 /*break*/, 5];
                case 4:
                    setLoader(false);
                    _a.label = 5;
                case 5: return [2 /*return*/];
            }
        });
    }); };
    return (_jsxs(Box, { sx: buttonStyled, children: [recaptcha_site_key_invisible && googleRecaptchaConsent && (_jsx(Box, { sx: recaptchaStyled, children: _jsx(ReCAPTCHA, { ref: recaptchaRef, size: "invisible", sitekey: recaptcha_site_key_invisible, onChange: function (recaptchaValue) { return checkUser(recaptchaValue); } }) })), _jsx(Tooltip, { title: recaptcha_site_key_invisible && !googleRecaptchaConsent
                    ? 'Vous devez accepter les cookies "Google reCAPTCHA" pour utiliser cette fonctionnalité'
                    : "", children: _jsx(Box, { children: _jsxs(Button, { onClick: function () { return sendCandidate(); }, disabled: loader || (recaptcha_site_key_invisible && !googleRecaptchaConsent), style: { position: "relative" }, children: [text, loader && _jsx(CircularProgress, { size: 25, style: { position: "absolute" } })] }) }) })] }));
};
Mailto.defaultProps = {
    recipient: "",
    subject: "",
    body: "",
};
export default Mailto;
