var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { Box, styled } from "@mui/material";
var HAS_WITH_BO = "true";
export var StyleFlagPicture = styled(Box)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n  justify-content: ", "\n  align-items: center;\n\n  & > .MuiBox-root:first-of-type {\n    padding: 0 10px;\n    width: ", ";\n    max-width:  ", "px;\n    overflow-wrap: break-word;\n  }\n\n  & > .MuiBox-root:last-of-type {\n    width: ", "px;\n  }\n"], ["\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n  justify-content: ", "\n  align-items: center;\n\n  & > .MuiBox-root:first-of-type {\n    padding: 0 10px;\n    width: ", ";\n    max-width:  ", "px;\n    overflow-wrap: break-word;\n  }\n\n  & > .MuiBox-root:last-of-type {\n    width: ", "px;\n  }\n"])), function (_a) {
    var isbo = _a.isbo;
    return (isbo === HAS_WITH_BO ? "flex-start" : "center");
}, function (_a) {
    var width = _a.width, isbo = _a.isbo;
    return (isbo === HAS_WITH_BO ? "calc(100% - ".concat(width, "px)") : "auto");
}, function (_a) {
    var _b = _a.maxwidth, maxwidth = _b === void 0 ? 220 : _b;
    return maxwidth;
}, function (_a) {
    var width = _a.width;
    return width;
});
var templateObject_1;
