export var isString = function (x) {
    return typeof x === "string";
};
export var isNull = function (x) {
    return x === null;
};
export var isBoolean = function (x) {
    return typeof x === "boolean";
};
export var isArray = function (x) {
    return typeof Array.isArray(x) === "boolean";
};
export var isArrayEmpty = function (x) {
    return Array.isArray(x) && !x.length;
};
export var isObjectEmpty = function (x) {
    return typeof x === "object" && !Object.keys(x).length;
};
export var isObject = function (x) {
    return typeof x === "object" && Object.keys(x).length > 0;
};
export var isStringEmpty = function (x) {
    return typeof x === "string" && !x.length;
};
