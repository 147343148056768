var severitiesTypes;
(function (severitiesTypes) {
    severitiesTypes["ERROR"] = "ERROR";
    severitiesTypes["WARNING"] = "WARNING";
    severitiesTypes["INFO"] = "INFO";
    severitiesTypes["SUCCESS"] = "SUCCESS";
})(severitiesTypes || (severitiesTypes = {}));
export var severityConfigs = {
    ERROR: {
        key: severitiesTypes.ERROR,
        label: "Alerte",
        backgroundColor: "#fbccc6",
        icon: "exclamation-triangle",
        iconColor: "#c70000",
    },
    WARNING: {
        key: severitiesTypes.WARNING,
        label: "Attention",
        backgroundColor: "rgb(255, 233, 203)",
        icon: "exclamation-circle",
        iconColor: "#ff9800",
    },
    INFO: {
        key: severitiesTypes.INFO,
        label: "Info",
        backgroundColor: "rgb(225, 242, 255)",
        icon: "info-circle",
        iconColor: "#2196f3",
    },
    SUCCESS: {
        key: severitiesTypes.SUCCESS,
        label: "Succès",
        backgroundColor: "rgb(195, 255, 195)",
        icon: "check-circle",
        iconColor: "#4caf50",
    },
};
export default severitiesTypes;
