import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import PageTitle from "components/PageTitle";
import BlockTitle from "shared/components/contents/blockTitle/BlockTitle";

const ContactTemplate = (props) => {
  const { page } = props;

  const { contents, title } = page;

  const { dynamicPart, dynamicFormPart } = contents;

  return (
    <Container>
      <PageTitle>{title}</PageTitle>
      <Grid container spacing={3} justifyContent="space-between">
        <Grid item md={6} xs={12}>
          {dynamicPart}
        </Grid>
        <Grid item md={5} xs={12}>
          <Box sx={{ px: { xs: 2, md: 0 } }}>
            <BlockTitle
              sx={{
                pt: 2,
                pb: 3,
              }}
              text="Écrivez nous ou postulez !"
            />
            {dynamicFormPart}
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

ContactTemplate.propTypes = {
  page: PropTypes.shape(),
};

ContactTemplate.defaultProps = {
  page: {},
};

export default ContactTemplate;
