var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, ClickAwayListener, Paper, Typography } from "@mui/material";
import Icon from "shared/components/Icon";
import Link from "shared/components/contents/link/Link";
import React from "react";
import { isArray } from "shared/utils/guards";
import { usePageVersion } from "frontoffice/hooks/PageVersionHook";
import useGlobalStore from "shared/store/GlobalStore";
import LinkEditor from "administration/components/LinkEditor";
var styleMenuItemActive = {
    content: '""',
    position: "absolute",
    right: 11,
    bgcolor: "#FFC617",
    zIndex: -1,
    width: 10,
    height: 10,
    borderRadius: "50%",
    bottom: { xs: 10, md: 16 },
};
var styleMenuItem = {
    zIndex: 1,
    textTransform: "none",
    fontSize: "1rem",
    fontWeight: 500,
    color: "#706F6F",
    position: "relative",
    cursor: "pointer",
    p: 2,
    width: "100%",
};
var styleMenuItemLink = {
    fontFamily: '"Montserrat"',
    whiteSpace: "nowrap",
    color: "cobalt.lynch",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
};
var styleSubMenu = function (theme) {
    var _a;
    return (_a = {
            position: { xs: "static", md: "absolute" },
            top: "100%",
            left: 0,
            bgcolor: { xs: "transparent", md: "white" },
            display: "flex",
            flexFlow: "column wrap",
            flexDirection: "column",
            alignItems: { xs: "center", md: "flex-start" },
            borderWidth: { xs: 0, md: "1px" },
            borderStyle: "solid",
            borderColor: "cobalt.grey10"
        },
        _a[theme.breakpoints.down("md")] = {
            boxShadow: "none",
        },
        _a);
};
var NavigationLink = function (_a) {
    var menuItem = _a.menuItem, others = __rest(_a, ["menuItem"]);
    var isBo = useGlobalStore(function (state) { return ({ isBo: state.isBo }); }).isBo;
    var currentPageVersion = usePageVersion({ enabled: !isBo }).currentPageVersion;
    var title = menuItem.title, _b = menuItem.link, link = _b === void 0 ? {} : _b;
    var isActive = React.useMemo(function () {
        var page = (link || {}).page;
        var fullPath = (page || {}).fullPath;
        if (currentPageVersion && fullPath) {
            if (fullPath === "/") {
                return currentPageVersion["fullPath"] === fullPath;
            }
            if (currentPageVersion["fullPath"]) {
                return currentPageVersion["fullPath"].startsWith(fullPath);
            }
        }
        return false;
    }, [link, currentPageVersion]);
    var LinkRender = isBo ? LinkEditor : Link;
    return (_jsx(Box, __assign({}, others, { children: _jsx(Box, { sx: [styleMenuItem, { "&:hover .MuiBox-root": { display: "block" } }], children: _jsxs(LinkRender, __assign({ sx: styleMenuItemLink }, link, { variant: "subtitle2", children: [title, _jsx(Box, { sx: __assign(__assign({}, styleMenuItemActive), { display: isActive ? "block" : "none" }) })] })) }) })));
};
var NavigationGroup = function (_a) {
    var menuItem = _a.menuItem, subMenus = _a.subMenus, others = __rest(_a, ["menuItem", "subMenus"]);
    var title = menuItem.title;
    var _b = React.useState(false), isOpen = _b[0], setIsOpen = _b[1];
    var handleClickGroup = React.useCallback(function () {
        setIsOpen(!isOpen);
    }, [isOpen]);
    var closeMenuGroup = React.useCallback(function () {
        setIsOpen(false);
    }, []);
    return (_jsx(Box, __assign({}, others, { children: _jsx(Box, { sx: styleMenuItem, onClick: handleClickGroup, children: _jsxs(Typography, { sx: styleMenuItemLink, variant: "subtitle2", color: "colors.lynch", component: "div", children: [title, _jsx(Icon, { icon: "caret-down", title: isOpen ? "Fermer" : "Ouvrir", style: {
                            marginLeft: 8,
                            fontSize: "1.14rem",
                            display: "flex",
                            alignItems: "center",
                            fontWeight: 600,
                            transform: "rotate(".concat(isOpen ? 180 : 0, "deg)"),
                            transition: "transform linear 200ms",
                        } }), _jsx(Box, { sx: { flexBasis: "100%" } }), isOpen && (_jsx(ClickAwayListener, { onClickAway: closeMenuGroup, touchEvent: false, children: _jsx(Paper, { sx: styleSubMenu, children: subMenus.map(function (item) {
                                return (_jsx(NavigationItem, { menuItem: item, sx: {
                                        position: "relative",
                                        "&:not(:last-child):after": {
                                            content: '""',
                                            position: "absolute",
                                            height: "1px",
                                            left: 16,
                                            right: 16,
                                            bottom: 0,
                                            bgcolor: "cobalt.grey10",
                                        },
                                    } }, item.id));
                            }) }) }))] }) }) })));
};
var NavigationItem = function (_a) {
    var menuItem = _a.menuItem, others = __rest(_a, ["menuItem"]);
    var subMenus = React.useMemo(function () {
        if (menuItem.subMenus) {
            return isArray(menuItem.subMenus) ? menuItem.subMenus : [menuItem.subMenus];
        }
        return [];
    }, [menuItem]);
    if (subMenus.length) {
        return _jsx(NavigationGroup, __assign({ menuItem: menuItem, subMenus: subMenus }, others));
    }
    return _jsx(NavigationLink, __assign({ menuItem: menuItem }, others));
};
export default NavigationItem;
