var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { find } from "administration/services/generateCRUD";
import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import generateTemplatePropsFromContents from "frontoffice/utils/templates/generateTemplatePropsFromContents";
import useAdminEditorStore from "administration/store/AdminEditorStore";
export var useSites = function (options) {
    if (options === void 0) { options = { params: { sort: "name,ASC" } }; }
    var siteId = useAdminEditorStore(function (state) { return ({ siteId: state.currentSiteId }); }).siteId;
    var _a = useQuery({
        queryKey: ["sites"],
        queryFn: function () { return find("sites", options); },
        staleTime: Infinity,
    }), siteList = _a.data, refetch = _a.refetch, rest = __rest(_a, ["data", "refetch"]);
    // move outside of this hook, should only be in vitrine
    // @fixme
    //const { data: site } = useQuery({ queryKey: ["site"], queryFn: SiteService.get, staleTime: Infinity });
    var currentSite = useMemo(function () {
        if (rest.isLoading) {
            return {};
        }
        return (siteList === null || siteList === void 0 ? void 0 : siteList.find(function (site) { return site.id === siteId; })) || siteList[0];
    }, [siteList, siteId, rest.isLoading]);
    if (currentSite && currentSite.contents) {
        var contentsProps = generateTemplatePropsFromContents(currentSite.contents);
        var settings = (contentsProps || {}).settings;
        currentSite.settings = settings;
    }
    return __assign({ allSites: siteList, currentSite: currentSite, refreshSite: refetch }, rest);
};
