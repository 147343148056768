export var attachmentStyled = {
    containerStyled: {
        styleOverrides: {
            root: {
                p: 3,
                bgcolor: "secondary.light",
                "&:hover": {
                    bgcolor: "secondary.main",
                },
            },
        },
    },
    linkStyled: {
        styleOverrides: {
            root: {
                fontWeight: "600",
                display: "inline-block",
                pt: 1,
                color: "primary.main",
                "& > *": {
                    color: "primary.main",
                },
            },
        },
    },
    iconStyled: {
        styleOverrides: {
            root: {
                top: 16,
                right: 16,
                "&> *": {
                    color: "primary.main",
                    fontSize: "1.5rem",
                },
            },
        },
    },
};
