import React from "react";
import { Box, Grid, Divider, Typography, Container, Link as MuiLink } from "@mui/material";
import Link from "shared/components/contents/link/Link";
import { useFooter } from "shared/services/SectionService";
import Social from "./Social";
import generateTemplatePropsFromContents from "frontoffice/utils/templates/generateTemplatePropsFromContents";
import useCookieStore from "shared/store/CookieStore";
import { useSites } from "shared/hooks/SiteHook";
import useGlobalStore from "shared/store/GlobalStore";
import LinkEditor from "administration/components/LinkEditor";

const footerStyled = {
  width: "100%",
  bgcolor: "#585858",
  mt: 6.25,
  display: "flex",
  py: 3,
};

const logoStyled = {
  mb: 1,
  width: "45px",
  "& img": {
    height: "100%",
    width: "auto",
  },
};

const dividerStyled = {
  display: "flex",
  flexDirection: "column",
  "& a": {
    color: "rgba(255, 255, 255)",
    fontWeight: 500,
    mb: { xs: 2, md: 2.5 },
    fontSize: "0.95rem",
    "&:hover": {
      color: "rgba(224, 224, 224)",
    },
    "&:active": {
      color: "rgba(255, 255, 255)",
    },
  },
};

const rightPartStyled = {
  display: { xs: "flex", md: "grid" },
  justifyContent: { md: "flex-end" },
  order: 2,
};

const addressStyled = {
  maxWidth: { xs: "50%", md: "none" },
  pr: { xs: 2.5, md: 0 },
  mr: { xs: 2.5, md: 0 },
};

const socialsStyled = {
  mt: 1,
  "& a": {
    mr: 3.75,
    fontSize: "1.2rem",
  },
};

const Footer = () => {
  const { handleCookiesModal } = useCookieStore((state) => ({ handleCookiesModal: state.handleCookiesModal }));
  const { currentSite: site } = useSites();
  const { footer } = useFooter(site.id);

  const footerContents = (footer && generateTemplatePropsFromContents(footer.contents)) || {};
  const linkInternals = Object.keys(footerContents);
  const { settings: siteSettings } = site || {};
  let { socialNetworks = [] } = siteSettings || {};
  const { copyright = "", logoFooter } = siteSettings || {};
  const { isBo } = useGlobalStore((state) => ({ isBo: state.isBo }));

  if (socialNetworks && !Array.isArray(socialNetworks)) {
    socialNetworks = [socialNetworks];
  }

  const LinkRender = isBo ? LinkEditor : Link;

  const LinkList =
    (linkInternals.length > 0 &&
      linkInternals.map((linkInternal, key) => (
        <Grid xs={6} md={3} item sx={dividerStyled} key={linkInternal}>
          <Divider
            sx={{
              bgcolor: { xs: "transparent", md: "#F8F8FB" },
              mb: { xs: 2, md: 3 },
            }}
          />
          {(Array.isArray(footerContents[linkInternal])
            ? footerContents[linkInternal]
            : [footerContents[linkInternal]]
          ).map((list1item) => (
            <LinkRender {...list1item.link} key={list1item.id}>
              {list1item.title}
            </LinkRender>
          ))}
          {key === 0 && (
            <MuiLink onClick={() => handleCookiesModal()} underline="none" sx={{ cursor: "pointer" }}>
              Gestion des cookies
            </MuiLink>
          )}
        </Grid>
      ))) ||
    null;

  return (
    <Box sx={footerStyled}>
      <Container>
        <Grid container spacing={4} justifyContent="space-between">
          <Grid item xs={12} md={3}>
            {logoFooter ? (
              <Box sx={logoStyled}>{logoFooter}</Box>
            ) : (
              <Box sx={logoStyled}>
                <img alt="logo docaposte" src="/image_logo_docaposte.svg" />
              </Box>
            )}
            <Typography variant="body1" sx={{ color: "#FFFFFF" }}>
              Docaposte Agility,
            </Typography>
            <Typography variant="body1" sx={{ color: "#FFFFFF" }}>
              une société de Docaposte,
            </Typography>
            <Typography variant="body1" sx={{ color: "#FFFFFF" }}>
              Groupe La Poste
            </Typography>
            <Typography variant="body1" sx={{ color: "#BAB8B8", mt: 2 }}>
              © {copyright}
            </Typography>
          </Grid>
          {LinkList}
          <Grid xs={12} md={linkInternals.length > 1 ? 3 : 6} item sx={rightPartStyled}>
            <Box sx={addressStyled}>
              <Typography variant="h5" sx={{ color: "#BAB8B8", fontSize: "1rem" }}>
                Adresse :
              </Typography>
              <Typography variant="body1" sx={{ color: "#FFFFFF", mt: 0.5, mb: 1.5 }}>
                1 et 2 bis Rue Antoine Laurent Lavoisier <br /> 76300 Sotteville-lés-Rouen
              </Typography>
            </Box>
            <Box>
              <Typography variant="h5" sx={{ color: "#BAB8B8", fontSize: "1rem" }}>
                Nous suivre :
              </Typography>
              <Box sx={socialsStyled}>
                <Social socialNetworks={socialNetworks} />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Footer;
