import categories from "shared/enums/categoriesContents";
import ContentsTypes from "shared/enums/contentsTypes";
export var margin = {
    label: "Espace vertical",
    type: ContentsTypes.MARGIN,
    category: categories.STRUCTURE,
    value: "",
    icon: "arrows-alt-v",
    editionModal: true,
    editionModalPosition: "top",
    children: [
        {
            label: "Hauteur (en px)",
            key: "height",
            type: ContentsTypes.SLIDER,
            value: "10",
            min: "0",
            max: "100",
            step: "5",
        },
        {
            label: "Hauteur (en px) sur mobile",
            key: "mobileHeight",
            type: ContentsTypes.SLIDER,
            value: "10",
            min: "0",
            max: "100",
            step: "5",
        },
    ],
};
