import { useMatomo } from "@datapunt/matomo-tracker-react";
import { usePageVersion } from "frontoffice/hooks/PageVersionHook";
import { useEffect } from "react";
var MatomoCMSPageTracking = function () {
    var currentPageVersion = usePageVersion().currentPageVersion;
    var _a = currentPageVersion || {}, id = _a.id, title = _a.title;
    var trackPageView = useMatomo().trackPageView;
    useEffect(function () {
        if (title) {
            trackPageView({ documentTitle: title });
        }
    }, [id, title, trackPageView]);
    return null;
};
export default MatomoCMSPageTracking;
