import unorm from "unorm";
export var getQueryParams = function (url) {
    if (url === void 0) { url = window.location.href; }
    var params = {};
    var search = decodeURI(url).split("?").slice(1).join("?");
    search.split("&").forEach(function (param) {
        if (param) {
            var _a = param.split("="), key = _a[0], _b = _a[1], value = _b === void 0 ? "" : _b;
            if (!params[key]) {
                if (value && value.indexOf(",") !== -1) {
                    params[key] = value.split(",");
                }
                else {
                    params[key] = value;
                }
            }
        }
    });
    return params;
};
export var createQueryParams = function (params) {
    if (typeof params !== "object") {
        return "";
    }
    return Object.keys(params)
        .map(function (key) {
        if (Array.isArray(params[key])) {
            return "".concat(key, "=").concat(params[key].join(","));
        }
        return params[key] !== undefined ? "".concat(key, "=").concat(params[key]) : "";
    })
        .join("&");
};
export var stringToPath = function (s) {
    if (s === void 0) { s = ""; }
    return "/".concat(unorm
        .nfd(s.toLowerCase())
        .replace(/[\u0300-\u036f]/g, "")
        .replace(/[\s']/g, "-")
        .replace(/[^a-z0-9-]/g, "")
        .replace(/-{2,}/g, "-")
        .replace(/-$/g, ""));
};
export var createUrlWithoutQuery = function (url) {
    if (url === void 0) { url = ""; }
    return url.split("?")[0];
};
