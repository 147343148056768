import { useQuery } from "@tanstack/react-query";
import { useLocation } from "react-router";
import PageService from "shared/services/PageService";
export var usePageVersion = function (options) {
    var location = useLocation();
    var _a = useQuery({
        queryKey: ["pageversion", location.pathname],
        queryFn: function () { return PageService.getPageInfos(location.pathname); },
        enabled: options === null || options === void 0 ? void 0 : options.enabled,
    }), page = _a.data, isFetching = _a.isFetching;
    return { currentPageVersion: page, loading: isFetching };
};
