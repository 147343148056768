import React from "react";
import PropTypes from "prop-types";
import PageTitle from "components/PageTitle";
import ImageBannerVitrine from "components/ImageBannerVitrine";
import Container from "@mui/material/Container";

const DynamicVitrineTemplate = (props) => {
  const { page } = props;

  const { title, contents } = page;

  const { dynamicPart } = contents;

  return (
    <>
      <ImageBannerVitrine page={page} />
      <Container>
        <PageTitle>{title}</PageTitle>
        {dynamicPart}
      </Container>
    </>
  );
};

DynamicVitrineTemplate.propTypes = {
  page: PropTypes.shape(),
};

DynamicVitrineTemplate.defaultProps = {
  page: {},
};

export default DynamicVitrineTemplate;
