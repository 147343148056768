var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Typography from "@mui/material/Typography";
import Box from "@mui/system/Box";
var TextBlockWithTitle = function (props) {
    var id = props.id, title = props.title, text = props.text, others = __rest(props, ["id", "title", "text"]);
    return (_jsxs(Box, __assign({ sx: {
            p: 2.5,
        } }, others, { children: [title && (_jsx(Typography, { variant: "h3", sx: { color: "#D0D0FF", fontSize: "2.14rem", lineHeight: 0.8 }, children: title })), text] }), id));
};
TextBlockWithTitle.defaultProps = {
    id: "",
    title: "",
    text: "",
};
export default TextBlockWithTitle;
