export var timeLineStepStyled = {
    stepStyled: {
        styleOverrides: {
            root: {
                ml: 1,
                borderLeft: "4px solid",
                borderColor: "secondary.dark",
                pl: 3,
                pb: 4,
            },
        },
    },
    bubbleStyled: {
        styleOverrides: {
            root: {
                left: "-12px",
                top: "-1px",
                display: "block",
                height: "20px",
                width: "20px",
                bgcolor: "secondary.dark",
                color: "white",
                borderRadius: "50%",
            },
        },
    },
};
