import React from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Block from "shared/components/Block";
import PageTitle from "components/PageTitle";

const OnePageTemplate = (props) => {
  const { page } = props;

  const { shortDescription, title, contents } = page;

  const { dynamicPart } = contents;

  return (
    <Container>
      <Grid container direction="column">
        <Grid item>
          <Block>
            <PageTitle>{title}</PageTitle>
          </Block>
          {shortDescription && shortDescription !== "" && <Block>{shortDescription}</Block>}
        </Grid>
        <Grid item>
          <div>{dynamicPart}</div>
        </Grid>
      </Grid>
    </Container>
  );
};

OnePageTemplate.propTypes = {
  page: PropTypes.shape(),
};

OnePageTemplate.defaultProps = {
  page: {},
};

export default OnePageTemplate;
