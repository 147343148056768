var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { adminContents as contents } from "shared/components/contents";
import Categories from "shared/enums/categoriesContents";
import { default as ContentsTypesEnum, default as types } from "shared/enums/contentsTypes";
import { urlRegex } from "shared/utils/commonUtils";
import CmsHooks from "shared/utils/register/CmsHooks";
import { v4 as uuidV4 } from "uuid";
import { HOOKS } from "shared/utils/register/definitions";
var getAllContents = function () {
    var projectContents = (CmsHooks.getHook(HOOKS.adminContentsUtils_contents) || []).map(function (projectContent) { return (__assign(__assign({}, projectContent), { category: projectContent.category || Categories.SPECIFIC })); });
    var projectTypes = projectContents.map(function (content) { return content.type; });
    var contentsFiltered = contents.filter(function (c) { return !projectTypes.includes(c.type); });
    return __spreadArray(__spreadArray([], projectContents, true), contentsFiltered, true);
};
// Get a random number for ids
export var generateContentId = function (content, updatedIds) {
    if (updatedIds === void 0) { updatedIds = {}; }
    var newId = uuidV4();
    if (content.id && typeof updatedIds === "object") {
        updatedIds[content.id] = newId;
    }
    var contentWithId = __assign(__assign({}, content), { id: newId });
    if (content.children) {
        contentWithId.children = content.children.map(function (child) { return generateContentId(child, updatedIds); });
    }
    return contentWithId;
};
export var enhanceContent = function (content) {
    var type = content.type, _a = content.children, children = _a === void 0 ? [] : _a;
    var defaultContent = getAllContents().find(function (c) { return c.type === type; });
    var dynamicChildKey = (defaultContent || {}).dynamicChildKey;
    var enhancedContent = __assign(__assign({}, defaultContent), content);
    // enchanced children
    var enhancedChildren = children.map(function (child) {
        var defaultChild = ((defaultContent && defaultContent.children) || []).find(function (c) { return c.key === child.key; }) || {};
        return enhanceContent(__assign(__assign({}, defaultChild), child));
    });
    // add defaults children not founds
    ((defaultContent && defaultContent.children) || []).forEach(function (defaultChild) {
        var defaultChildAlreadyExists = enhancedChildren.find(function (c) { return c.key === defaultChild.key; });
        if ((defaultChild.value !== undefined || defaultChild.children) && !defaultChildAlreadyExists) {
            enhancedChildren.push(generateContentId(enhanceContent(defaultChild)));
        }
    });
    if (defaultContent && defaultContent.children) {
        enhancedContent.children = enhancedChildren.filter(function (child) {
            return (dynamicChildKey && child.key === dynamicChildKey) ||
                (defaultContent.children || []).find(function (defaultChild) { return child.key === defaultChild.key; });
        });
    }
    else {
        enhancedContent.children = enhancedChildren;
    }
    return __assign(__assign({}, enhancedContent), { value: enhancedContent.value || "" });
};
export var replacePagesByIds = function (content, updatedIds) {
    if (updatedIds === void 0) { updatedIds = {}; }
    var type = content.type, value = content.value, children = content.children;
    var newContent = __assign({}, content);
    if (type && [ContentsTypesEnum.PAGE, ContentsTypesEnum.SUBPAGE].includes(type) && typeof value === "object") {
        var valuePageVersion = value;
        newContent.value = valuePageVersion.page ? valuePageVersion.page.id : "";
    }
    else if (type === types.CONTENTS_GROUP) {
        newContent.children = children === null || children === void 0 ? void 0 : children.map(function (child) {
            if (child.key === "contents") {
                return __assign(__assign({}, child), { value: updatedIds[child.value] || child.value, children: [] });
            }
            return child;
        });
    }
    else if (children && children.length) {
        newContent.children = children.map(function (child) { return replacePagesByIds(child, updatedIds); });
    }
    return newContent;
};
export var getDefaultContentByType = function (type) {
    return enhanceContent(getAllContents().find(function (content) { return content.type === type; }) || {});
};
export var getAllDefaultContents = function () { return getAllContents().map(enhanceContent); };
export var adminProperties = [
    "label",
    "options",
    "editionModal",
    "editionModalPosition",
    "isTemplateSettings",
    "min",
    "max",
    "step",
    "category",
    "icon",
    "requiredByKey",
    "regexValidation",
    "width",
    "tooltip",
    "limit",
    "dynamicChildKey",
    "updatedAt",
];
// Used to check form values. ------------------------------------------------
var checkChildHasFormValidation = function (content) {
    return content.regexValidation || content.requiredByKey;
};
var checkChildRegexValidation = function (content) {
    return !content.regexValidation || !content.value || content.value.match(content.regexValidation);
};
var checkFileUrlIsValid = function (content) {
    if (content.key === "file") {
        var valueParse = content.value ? JSON.parse(content.value) : "";
        var _a = valueParse.url, url = _a === void 0 ? "" : _a;
        return url.match(urlRegex);
    }
    return true;
};
export var isValidContent = function (content, keys) {
    if (content) {
        if (!keys && !checkChildHasFormValidation(content) && (!content.children || !content.children.length))
            return true;
        if (!checkChildRegexValidation(content) || !checkFileUrlIsValid(content))
            return false;
        var hasRequiredKeys = content.requiredByKey || keys;
        if (hasRequiredKeys && hasRequiredKeys.includes(content.key)) {
            return !!content.value;
        }
        if (content.children) {
            var childrenValidate_1 = true;
            content.children.forEach(function (element) {
                if (!isValidContent(element, content.requiredByKey || keys))
                    childrenValidate_1 = false;
            });
            return childrenValidate_1;
        }
    }
    return true;
};
export default {};
