import ContentsTypes from "shared/enums/contentsTypes";
export var accordionPanel = {
    label: "Panneau dépliant",
    type: ContentsTypes.ACCORDION_PANEL,
    value: "",
    editionModal: true,
    editionModalPosition: "top",
    requiredByKey: ["summary"],
    children: [
        {
            label: "Titre du paneau",
            key: "summary",
            type: ContentsTypes.INPUT,
            value: "Titre",
        },
        {
            label: "details",
            key: "details",
            type: ContentsTypes.EXPANSION_PANEL_DETAILS,
            value: "",
        },
    ],
};
