var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import axios from "axios";
import ContentsTypes from "shared/enums/contentsTypes";
import generateCRUD from "administration/services/generateCRUD";
import { adminProperties, generateContentId, replacePagesByIds } from "administration/utils/adminContentsUtils";
import { removeProperties } from "shared/utils/commonUtils";
import FileService from "da-ged-web/services/FileService";
export var pageVersionImageKey = "versionImage";
var name = "page-versions";
var PageVersionsCRUD = generateCRUD(name);
var prepareVersion = function (version, updatedIds) {
    if (updatedIds === void 0) { updatedIds = {}; }
    var _a = version.contents, contents = _a === void 0 ? [] : _a, description = version.description, path = version.path, shortDescription = version.shortDescription, template = version.template, title = version.title, image = version.image, tags = version.tags;
    var cleanContents = contents.map(function (c) { return removeProperties(c, adminProperties); });
    var contentsWithPagesIds = cleanContents.map(function (content) { return replacePagesByIds(content, updatedIds); });
    var contentsWithoutVersionImage = contentsWithPagesIds.filter(function (content) { return content.key !== pageVersionImageKey; });
    if (image) {
        contentsWithoutVersionImage.push(generateContentId({
            key: pageVersionImageKey,
            value: JSON.stringify({
                url: image.url,
                id: image.id,
                name: image.name,
                mimeType: image.mimeType,
                size: image.size,
                publicUrl: image.publicUrl,
                publicUrlBySize: image.publicUrlBySize,
            }),
            type: ContentsTypes.FILE_IMAGE,
        }));
    }
    return {
        contents: contentsWithoutVersionImage,
        description: description,
        path: path,
        shortDescription: shortDescription,
        template: template,
        title: title,
        image: image && {
            url: image.url,
            alt: image.alt,
            title: image.title,
        },
        tags: tags,
    };
};
var AdminPageVersionsService = /** @class */ (function (_super) {
    __extends(AdminPageVersionsService, _super);
    function AdminPageVersionsService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    var _a;
    _a = AdminPageVersionsService;
    AdminPageVersionsService.getPageVersions = function (pageId) {
        return axios
            .get("/getPageVersions", { params: { pageId: pageId } })
            .then(function (r) { return r.data; })
            .catch(function (e) { return console.log("error getPageVersions", e); });
    };
    AdminPageVersionsService.publishImages = function (version) { return __awaiter(void 0, void 0, void 0, function () {
        var contents, image, publishingPromises, newVersion, imageContent, imageFile, versionImagePromise, publishContentFiles;
        return __generator(_a, function (_b) {
            contents = version.contents, image = version.image;
            publishingPromises = [];
            newVersion = version;
            imageContent = contents.find(function (content) { return content.key === pageVersionImageKey; });
            if (imageContent) {
                imageFile = JSON.parse(imageContent.value);
                if (imageFile.id) {
                    versionImagePromise = FileService.publish({ file: imageFile, pageVersion: version }).then(function (response) {
                        var publishedImage = response.data;
                        newVersion.image = __assign(__assign(__assign({}, image), publishedImage), { url: publishedImage.publicUrl });
                    });
                    publishingPromises.push(versionImagePromise);
                }
            }
            publishContentFiles = function (content) {
                var type = content.type, children = content.children, value = content.value, key = content.key;
                try {
                    if ([ContentsTypes.FILE, ContentsTypes.FILE_IMAGE].includes(type) && key !== pageVersionImageKey && value) {
                        var file_1 = JSON.parse(value);
                        if (file_1.id) {
                            var contentFilePromise = FileService.publish({ file: file_1, pageVersion: version }).then(function (response) {
                                var publishedFile = response.data;
                                // eslint-disable-next-line no-param-reassign
                                content.value = JSON.stringify(__assign(__assign(__assign({}, file_1), publishedFile), { url: publishedFile.publicUrl }));
                                return publishedFile;
                            });
                            publishingPromises.push(contentFilePromise);
                        }
                    }
                    else if (children && Array.isArray(children)) {
                        children.forEach(publishContentFiles);
                    }
                }
                catch (e) {
                    //
                }
            };
            newVersion.contents.forEach(publishContentFiles);
            return [2 /*return*/, Promise.all(publishingPromises).then(function (responses) {
                    if (responses.length) {
                        return _a.update(newVersion);
                    }
                    return version;
                })];
        });
    }); };
    AdminPageVersionsService.create = function (version, publishImages) {
        if (publishImages === void 0) { publishImages = true; }
        return __awaiter(void 0, void 0, void 0, function () {
            var _b, contents, page, updatedIds, contentsWithIds, cleanVersion, v;
            return __generator(_a, function (_c) {
                switch (_c.label) {
                    case 0:
                        _b = version.contents, contents = _b === void 0 ? [] : _b, page = version.page;
                        updatedIds = {};
                        contentsWithIds = contents.map(function (c) { return generateContentId(c, updatedIds); });
                        cleanVersion = prepareVersion(__assign(__assign({}, version), { contents: contentsWithIds }), updatedIds);
                        return [4 /*yield*/, PageVersionsCRUD.create(__assign(__assign({}, cleanVersion), { pageId: page.id }))];
                    case 1:
                        v = _c.sent();
                        if (publishImages) {
                            return [2 /*return*/, _a.publishImages(v)];
                        }
                        return [2 /*return*/, v];
                }
            });
        });
    };
    AdminPageVersionsService.update = function (version) {
        var id = version.id, updated = version.updated, versionName = version.versionName;
        var cleanVersion = prepareVersion(version);
        return PageVersionsCRUD.update(__assign(__assign({}, cleanVersion), { id: id, updated: updated, versionName: versionName }));
    };
    AdminPageVersionsService.updateStatus = function (pageVersion, status) {
        return axios.patch("/api/".concat(name, "/").concat(pageVersion.id, "/status"), { updated: pageVersion.updated, status: status }).then(function (r) { return r.data; });
    };
    return AdminPageVersionsService;
}(PageVersionsCRUD));
export default AdminPageVersionsService;
