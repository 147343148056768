import ContentsTypes from "shared/enums/contentsTypes";
import VideosOrigins from "shared/enums/videosOrigins";
export var video = {
    type: ContentsTypes.VIDEO,
    editionModal: true,
    editionModalPosition: "top",
    label: "Vidéo",
    icon: "play-circle",
    requiredByKey: ["src"],
    children: [
        {
            key: "source",
            type: ContentsTypes.SELECT,
            value: VideosOrigins.YOUTUBE,
            label: "Source",
            options: [
                { label: "Dailymotion", value: VideosOrigins.DAILYMOTION },
                { label: "Youtube", value: VideosOrigins.YOUTUBE },
                { label: "URL", value: VideosOrigins.URL },
            ],
        },
        {
            label: "Url",
            type: ContentsTypes.URL,
            key: "src",
            value: "",
        },
    ],
};
