var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createElement as _createElement } from "react";
//import customTypes from "enums/contentsCustomType";
import ContentsTypes from "shared/enums/contentsTypes";
import ArticleTitle from "components/ArticleTitle";
import PresentationBox from "components/PresentationBox";
import SubPage from "shared/components/contents/subPage/SubPage";
import { formatPageData } from "frontoffice/utils/templates/formatPageData";
import GeographicMap from "components/GeographicMap";
import SubpageSearch from "components/SubpageSearch";
import PresentationBlock from "components/PresentationBlock/PresentationBlock";
import PresentationCard from "components/PresentationCard/PresentationCard";
import Trombinoscope from "components/Trombinoscope/Trombinoscope";
import BlockWithTitle from "components/BlockWithTitle/BlockWithTitle";
import TextBlockWithTitle from "components/TextBlockWithTitle/TextBlockWithTitle";
import DescriptionBlock from "components/DescriptionBlock/DescriptionBlock";
import DescriptionCardWithLogo from "components/DescriptionCardWithLogo/DescriptionCardWithLogo";
import ContactForm from "components/ContactForm";
import Mailto from "components/Mailto";
import Iframe from "components/Iframe/Iframe";
import customTypes from "../enums/contentsCustomTypes.enum";
import TabsComponent from "components/TabsComponent/TabsComponent";
var getDynamicComponent = function (_a) {
    var type = _a.type, value = _a.value, id = _a.id, index = _a.index;
    switch (type) {
        case customTypes.ARTICLE_SECTION:
            return _createElement(ArticleTitle, __assign({}, value, { key: id }));
        case customTypes.PRESENTATION_BOX:
            return _createElement(PresentationBox, __assign({}, value, { key: id }));
        case customTypes.PRESENTATION_BLOCK:
            return _createElement(PresentationBlock, __assign({}, value, { key: id }));
        case customTypes.PRESENTATION_CARD:
            return _createElement(PresentationCard, __assign({}, value, { key: id }));
        case customTypes.TROMBINOSCOPE:
            return _createElement(Trombinoscope, __assign({}, value, { key: id }));
        case ContentsTypes.SUBPAGE: {
            var page = (value || {}).page;
            var image = (page || {}).image;
            return (_createElement(SubPage, __assign({}, value, { 
                // eslint-disable-next-line react/prop-types
                page: __assign(__assign({}, formatPageData(page)), { image: image }), pageVersion: page, key: id, index: index })));
        }
        case customTypes.BLOCK_WITH_TITLE:
            return _createElement(BlockWithTitle, __assign({}, value, { key: id }));
        case customTypes.TEXT_BLOCK_WITH_TITLE:
            return _createElement(TextBlockWithTitle, __assign({}, value, { key: id }));
        case customTypes.DESCRIPTION_BLOCK:
            return _createElement(DescriptionBlock, __assign({}, value, { key: id }));
        case customTypes.DESCRIPTION_CARD_WITH_LOGO:
            return _createElement(DescriptionCardWithLogo, __assign({}, value, { key: id }));
        case customTypes.TABS_COMPONENT:
            return _createElement(TabsComponent, __assign({}, value, { key: id }));
        case customTypes.GEOGRAPHIC_MAP:
            return _createElement(GeographicMap, __assign({}, value, { key: id }));
        case customTypes.SUBPAGE_SEARCH:
            return _createElement(SubpageSearch, __assign({}, value, { key: id }));
        case customTypes.CONTACT_FORM:
            return _createElement(ContactForm, __assign({}, value, { key: id }));
        case customTypes.MAILTO: {
            return _createElement(Mailto, __assign({}, value, { contentId: id, key: id }));
        }
        case customTypes.IFRAME:
            return _createElement(Iframe, __assign({}, value, { key: id }));
        default:
            return null;
    }
};
export default getDynamicComponent;
