var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from "react";
import Box from "@mui/material/Box";
import ExpansionPanel from "shared/components/contents/expansionPanel/ExpansionPanel";
var Accordion = function (props) {
    var _a = props.panels, panels = _a === void 0 ? [] : _a;
    if (panels && !Array.isArray(panels)) {
        panels = [panels];
    }
    var _b = useState(false), expanded = _b[0], setExpanded = _b[1];
    var handleChange = function (index) { return function (event, isExpanded) {
        setExpanded(isExpanded ? index : false);
    }; };
    var allPanels = panels.map(function (panel, index) {
        var isExpanded = index === expanded;
        return (_jsx(Box, { sx: { mb: isExpanded ? 4 : 2 }, children: _jsx(ExpansionPanel, __assign({}, panel, { index: index, expanded: isExpanded, onChange: handleChange(index) })) }, panel.id));
    });
    return _jsx(_Fragment, { children: allPanels });
};
export default Accordion;
