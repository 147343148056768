var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import Link from "shared/components/contents/link/Link";
import Block from "shared/components/Block";
import { createRef, Fragment, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import GlossaryItem from "../glossaryItem/GlossaryItem";
var Glossary = function (props) {
    var _a, _b, _c, _d;
    var location = props.location;
    var _e = props.items, items = _e === void 0 ? [] : _e;
    var _f = useState(null), lettersTop = _f[0], setLettersTop = _f[1];
    var _g = useState([]), letters = _g[0], setLetters = _g[1];
    var hash = location.hash;
    if (items && !Array.isArray(items)) {
        items = [items];
    }
    var filteredItems = items.filter(function (item) { return item && item.word; });
    filteredItems.sort(function (a, b) { return a.word.localeCompare(b.word); });
    var groupedItems = {};
    filteredItems.forEach(function (item) {
        var word = item.word;
        var firstLetter = word.toUpperCase().split("")[0];
        groupedItems[firstLetter] = groupedItems[firstLetter] || [];
        groupedItems[firstLetter].push(item);
    });
    var lettersRef = createRef();
    var theme = useTheme();
    var isMobile = !useMediaQuery(theme.breakpoints.up("md"));
    useEffect(function () {
        if (isMobile) {
            setLettersTop((window.innerHeight - lettersRef.current.getBoundingClientRect().height) / 2);
        }
    }, [lettersRef, isMobile]);
    useEffect(function () {
        // Si trop de lettres, alors on remplace l'excedent par "..." au milieu de la liste
        // qui pointera vers la première lettre retirée
        var allLetters = Object.keys(groupedItems).map(function (item) { return ({ letter: item, hash: item }); });
        if (isMobile) {
            var maxLetters = 15;
            var nbLetters = Object.keys(groupedItems).length;
            if (nbLetters > maxLetters) {
                var nbSubstract = nbLetters - maxLetters;
                var indexStartSubstract = Math.round(maxLetters / 2);
                allLetters.splice(indexStartSubstract, nbSubstract, {
                    letter: "...",
                    hash: allLetters[indexStartSubstract].hash,
                });
            }
        }
        setLetters(allLetters);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [items, isMobile]);
    return (_jsxs(Fragment, { children: [_jsx(Box
            // @ts-ignore
            , { 
                // @ts-ignore
                sx: __assign(__assign({}, (_d = (_c = (_b = (_a = theme.components) === null || _a === void 0 ? void 0 : _a.glossaryStyled) === null || _b === void 0 ? void 0 : _b.lettersStyled) === null || _c === void 0 ? void 0 : _c.styleOverrides) === null || _d === void 0 ? void 0 : _d.root), { display: { xs: lettersTop ? "flex" : "none", lg: "flex" }, fontSize: { xs: "1.05rem", lg: "1.25rem" }, fontWeight: { xs: 400, lg: 600 }, position: { xs: "fixed", md: "-webkit-sticky", lg: "sticky" }, flexDirection: { xs: "column", lg: "row" }, alignItems: { xs: "center", lg: "stretch" }, top: { xs: lettersTop ? lettersTop : "initial", lg: 0 } }), ref: lettersRef, children: letters.map(function (item) {
                    var _a, _b, _c, _d;
                    return (_jsx(Link, { smooth: true, url: "".concat(location.pathname, "#").concat(item.hash), sx: __assign(__assign({}, (_d = (_c = (_b = (_a = theme.components) === null || _a === void 0 ? void 0 : _a.glossaryStyled) === null || _b === void 0 ? void 0 : _b.letterLinkStyled) === null || _c === void 0 ? void 0 : _c.styleOverrides) === null || _d === void 0 ? void 0 : _d.root), { color: { xs: "common.white", lg: hash === "#".concat(item.hash) ? "secondary.dark" : "secondary.main" }, fontSize: { md: hash === "#".concat(item.hash) && "1.75rem" }, position: "relative" }), children: item.letter }, item.letter));
                }) }), _jsx(Box, { sx: { pt: { xs: 1, lg: 0 } }, children: Object.keys(groupedItems).map(function (letter) {
                    var _a, _b, _c, _d;
                    return (_jsxs(Block, { sx: { pt: { xs: 1, lg: 0 } }, id: letter, children: [_jsx(Box
                            // @ts-ignore
                            , { 
                                // @ts-ignore
                                sx: (_d = (_c = (_b = (_a = theme.components) === null || _a === void 0 ? void 0 : _a.glossaryStyled) === null || _b === void 0 ? void 0 : _b.letterTitleStyled) === null || _c === void 0 ? void 0 : _c.styleOverrides) === null || _d === void 0 ? void 0 : _d.root, children: _jsx(Typography, { variant: "h2", children: letter }) }), groupedItems[letter].map(function (item) { return (_jsx(GlossaryItem, __assign({}, item), item.id)); })] }, letter));
                }) })] }));
};
export default withRouter(Glossary);
