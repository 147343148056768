var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { createElement as _createElement } from "react";
import { jsx as _jsx } from "react/jsx-runtime";
import Box from "@mui/material/Box";
import Accordion from "shared/components/contents/accordion/Accordion";
import Attachment from "shared/components/contents/attachment/Attachment";
import BlockTitle from "shared/components/contents/blockTitle/BlockTitle";
import Button from "shared/components/contents/button/Button";
import CodeBlock from "shared/components/contents/codeBlock/CodeBlock";
import Container from "shared/components/contents/container/Container";
import ExpansionPanel from "shared/components/contents/expansionPanel/ExpansionPanel";
import ExpansionText from "shared/components/contents/expansionText/ExpansionText";
import Glossary from "shared/components/contents/glossary/Glossary";
import Grid from "shared/components/contents/grid/Grid";
import Image from "shared/components/contents/image/Image";
import ImageBlock from "shared/components/contents/imageBlock/ImageBlock";
import ImagesGallery from "shared/components/contents/imagesGallery/ImagesGallery";
import InfoBlock from "shared/components/contents/infoBlock/InfoBlock";
import Link from "shared/components/contents/link/Link";
import Margin from "shared/components/contents/margin/Margin";
import Numeric from "shared/components/contents/numeric/Numeric";
import SubPage from "shared/components/contents/subPage/SubPage";
import Text from "shared/components/contents/text/Text";
import Timeline from "shared/components/contents/timeLine/TimeLine";
import TimelineStep from "shared/components/contents/timeLineStep/TimeLineStep";
import Video from "shared/components/contents/video/Video";
import Block from "shared/components/Block";
import ContentsTypes from "shared/enums/contentsTypes";
import { mapSrcSet } from "shared/utils/imageUtil";
import CmsHooks from "shared/utils/register/CmsHooks";
import React from "react";
import { HOOKS } from "./register/definitions";
import { formatPageData } from "../../frontoffice/utils/templates/formatPageData";
import useGlobalStore from "shared/store/GlobalStore";
import LinkEditor from "administration/components/LinkEditor";
export var sortContentsProps = function (contents) {
    var newContents = contents.sort(function (item1, item2) { return parseInt(item1.order, 10) - parseInt(item2.order, 10); });
    if (contents.children) {
        newContents.children = sortContentsProps(contents.children);
    }
    return newContents;
};
export var getDynamicComponent = function (type, value, id, index) {
    var getDynamicComponentFromProject = CmsHooks.getHook(HOOKS.templatePropsUtils_getDynamicComponent);
    if (type && value !== undefined) {
        if (typeof getDynamicComponentFromProject === "function") {
            var component = getDynamicComponentFromProject({
                type: type,
                value: value,
                id: id,
                index: index,
            });
            if (component) {
                return component;
            }
        }
        switch (type) {
            case ContentsTypes.TEXT:
                return _jsx(Text, { children: value });
            case ContentsTypes.CHECKBOX:
                return value === "true";
            case ContentsTypes.NUMERIC:
                return _jsx(Numeric, { number: value });
            case ContentsTypes.BUTTON:
                if (typeof value === "object" && !Array.isArray(value)) {
                    var text_1 = value.text, link = value.link, primary = value.primary, color = value.color, btnIsOutlined = value.btnIsOutlined, variant_1 = value.variant, others_1 = __rest(value, ["text", "link", "primary", "color", "btnIsOutlined", "variant"]);
                    var isBo = useGlobalStore.getState().isBo;
                    return (_jsx(Button, __assign({}, others_1, { color: color || (primary && "primary") || (primary === false && "secondary"), variant: variant_1 || (btnIsOutlined && "outlined") || (btnIsOutlined === false && "contained"), component: isBo ? LinkEditor : Link }, link, { children: text_1 })));
                }
                break;
            case ContentsTypes.BLOCKTITLE:
                var id_1 = value.id, text = value.text, variant = value.variant, component = value.component, textAlign = value.textAlign, fontWeight = value.fontWeight, others = __rest(value, ["id", "text", "variant", "component", "textAlign", "fontWeight"]);
                return (_jsx(BlockTitle, __assign({}, others, { id: id_1, text: text, variant: variant, component: component, fontWeight: fontWeight, textAlign: textAlign })));
            case ContentsTypes.FILE:
            case ContentsTypes.FILE_IMAGE:
                var file = void 0;
                try {
                    file = JSON.parse(value);
                    if (file.publicUrlBySize) {
                        file.srcSet = mapSrcSet(file.publicUrlBySize);
                    }
                }
                catch (e) {
                    file = typeof value === "object" ? value : {};
                }
                return file;
            case ContentsTypes.PAGE:
                if (typeof value === "object") {
                    var image = value.image;
                    var _a = image || {}, title = _a.title, alt = _a.alt, url = _a.url;
                    return __assign(__assign({}, value), { image: image ? getDynamicComponent(ContentsTypes.IMAGE, { title: title, alt: alt, file: { url: url } }) : null });
                }
                break;
            case ContentsTypes.MENU_ITEM:
            case ContentsTypes.MENU_GROUP:
            case ContentsTypes.ACCORDION_PANEL:
            case ContentsTypes.LINK:
            case ContentsTypes.ICON:
            case ContentsTypes.INPUT:
            case ContentsTypes.INPUT_NUMBER:
            case ContentsTypes.URL:
            case ContentsTypes.SELECT:
            case ContentsTypes.SELECT_GRID:
            case ContentsTypes.RADIO_GROUP:
            case ContentsTypes.SHORT_TEXT:
            case ContentsTypes.GLOSSARY_ITEM:
            case ContentsTypes.TABS:
            case ContentsTypes.TAB:
            case ContentsTypes.CONTENTS_GROUP:
            case ContentsTypes.CONTENTS_GROUPS_LIST:
            case ContentsTypes.WARNING_BANNER:
            case ContentsTypes.SOCIAL_NETWORK:
            case ContentsTypes.SITE_SETTINGS:
            case ContentsTypes.COLOR:
            case ContentsTypes.SLIDER:
            case ContentsTypes.CODE_BLOCK_TEXT:
            case ContentsTypes.IMAGE_FILTERS:
            case ContentsTypes.IMAGES:
            case ContentsTypes.IMAGE_AS_FILE:
            case ContentsTypes.EDITABLE_INPUT:
                return value;
            case ContentsTypes.CODE_BLOCK:
                return _createElement(CodeBlock, __assign({}, value, { key: id_1 }));
            case ContentsTypes.DYNAMIC:
                return (value &&
                    React.Children.map(value.dynamicElements, function (child) { return child && _jsx(Block, { children: child }, child.props.id); }));
            case ContentsTypes.IMAGE: {
                return value && value.file && value.file.url && _jsx(Image, __assign({}, value));
            }
            case ContentsTypes.IMAGE_BLOCK: {
                return value && _createElement(ImageBlock, __assign({}, value, { key: id_1 }));
            }
            case ContentsTypes.IMAGES_GALLERY: {
                return _jsx(ImagesGallery, __assign({}, value), id_1);
            }
            case ContentsTypes.TIMELINE:
                return _jsx(Timeline, __assign({}, value));
            case ContentsTypes.STEP_V2:
                return _jsx(TimelineStep, __assign({}, value), id_1);
            case ContentsTypes.STEP_V2_CONTENT:
                return (value &&
                    React.Children.map(value.detailsElements, function (child) {
                        return child && (_jsx(Box, { mb: 1, children: child }, child.props.id));
                    }));
            case ContentsTypes.EXPANSION_PANEL:
                return _jsx(ExpansionPanel, __assign({}, value), id_1);
            case ContentsTypes.ATTACHMENT:
                return _jsx(Attachment, __assign({}, value), id_1);
            case ContentsTypes.ACCORDION:
                return _jsx(Accordion, __assign({}, value));
            case ContentsTypes.EXPANSION_PANEL_DETAILS:
                return (value &&
                    React.Children.map(value.detailsElements, function (child) {
                        return child && (_jsx(Box, { py: 1, id: child.props.id, children: child }, child.props.id));
                    }));
            case ContentsTypes.SUBPAGE:
                return _jsx(SubPage, { page: formatPageData(value), pageVersion: value, index: index !== null && index !== void 0 ? index : 0 }, id_1);
            case ContentsTypes.SUBPAGES_LIST: {
                return value && value.pages;
            }
            case ContentsTypes.VIDEO:
                return _jsx(Video, __assign({}, value));
            case ContentsTypes.GRID:
                return _jsx(Grid, __assign({}, value), id_1);
            case ContentsTypes.CONTAINER:
                return (value && (_jsx(Container, __assign({ display: "flex", flexDirection: "row" }, value, { children: value.containerElement }), id_1)));
            case ContentsTypes.MARGIN:
                return _jsx(Margin, __assign({}, value));
            case ContentsTypes.GLOSSARY:
                return _jsx(Glossary, __assign({}, value));
            case ContentsTypes.INFO_BLOCK:
                return _jsx(InfoBlock, __assign({}, value));
            case ContentsTypes.INFO_BLOCK_LIST:
                return value && value.infos;
            case ContentsTypes.EXPANSION_TEXT:
                return _jsx(ExpansionText, __assign({}, value), id_1);
            default:
                return null;
        }
    }
    return value;
};
