import ContentsTypes from "shared/enums/contentsTypes";
export var contentsGroup = {
    label: "contents group",
    type: ContentsTypes.CONTENTS_GROUP,
    value: "",
    children: [
        {
            label: "name",
            type: ContentsTypes.INPUT,
            key: "name",
            value: "",
        },
        {
            label: "contents",
            key: "contents",
        },
    ],
};
