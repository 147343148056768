import ContentsTypes from "shared/enums/contentsTypes";
export var image = {
    label: "Image",
    type: ContentsTypes.IMAGE,
    icon: "image",
    children: [
        {
            label: "file",
            key: "file",
            type: ContentsTypes.FILE_IMAGE,
            value: "{\"url\":\"".concat(typeof window !== "undefined" && window.location.origin, "/default-image.jpg\"}"),
        },
        {
            label: "Texte alternatif",
            key: "alt",
            type: ContentsTypes.INPUT,
            value: "image",
        },
        {
            label: "Titre (visible au survol)",
            key: "title",
            type: ContentsTypes.INPUT,
            value: "image",
        },
        {
            label: "Verticale",
            key: "position_verticale",
            type: ContentsTypes.SELECT,
            options: [
                { label: "Haute", value: "top" },
                { label: "Centrée", value: "center" },
                { label: "Basse", value: "bottom" },
            ],
            value: "top",
        },
        {
            label: "Horizontale",
            key: "position_horizontale",
            type: ContentsTypes.SELECT,
            options: [
                { label: "Gauche", value: "left" },
                { label: "Centrée", value: "center" },
                { label: "Droite", value: "right" },
            ],
            value: "center",
        },
        {
            label: "Ajustement",
            key: "position_ajustement",
            type: ContentsTypes.SELECT,
            options: [
                { label: "Aucun", value: "none" },
                { label: "Rogné", value: "cover" },
                { label: "Étiré", value: "fill" },
                { label: "Contraint", value: "contain" },
            ],
            value: "cover",
        },
        {
            label: "Filtres",
            key: "filters",
            type: ContentsTypes.IMAGE_FILTERS,
            value: "",
        },
    ],
};
