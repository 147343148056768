var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import contentsTypes from "shared/enums/contentsTypes";
import { pageImageFiltersKey } from "../../../shared/utils/imageUtil";
import { getDynamicComponent } from "../../../shared/utils/templatePropsUtils";
import generateTemplatePropsFromContents from "./generateTemplatePropsFromContents";
export var formatPageData = function (page) {
    var _a;
    var imageFiltersChild = (_a = page === null || page === void 0 ? void 0 : page.contents) === null || _a === void 0 ? void 0 : _a.find(function (child) { return child.key === pageImageFiltersKey; });
    if (!page)
        return page;
    return __assign(__assign({}, page), { title: page.title, image: page.image &&
            getDynamicComponent(contentsTypes.IMAGE, {
                file: { url: page.image.url },
                alt: page.image.alt,
                title: page.image.title,
                filters: imageFiltersChild && imageFiltersChild.children
                    ? generateTemplatePropsFromContents(imageFiltersChild.children)
                    : undefined,
            }), shortDescription: page.shortDescription &&
            getDynamicComponent(contentsTypes.TEXT, page.shortDescription, "shortdescription-".concat(page.id)), contents: generateTemplatePropsFromContents(page.contents) });
};
