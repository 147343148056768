import GridPreview from "shared/components/contents/grid/GridPreview";
import categories from "shared/enums/categoriesContents";
import ContentsTypes from "shared/enums/contentsTypes";
export var grid = {
    label: "Grille",
    type: ContentsTypes.GRID,
    category: categories.STRUCTURE,
    icon: "border-none",
    editionModal: true,
    editionModalPosition: "top",
    Preview: GridPreview,
    dynamicChildKey: "items",
    children: [
        {
            key: "size",
            label: "Nombre de colonnes",
            type: ContentsTypes.SELECT_GRID,
            options: [
                { label: "2", value: "6" },
                { label: "3", value: "4" },
                { label: "4", value: "3" },
                { label: "6", value: "2" },
            ],
            value: "6",
        },
        {
            key: "sizeXs",
            label: "Nombre de colonnes en mobile",
            type: ContentsTypes.SELECT_GRID,
            options: [
                { label: "1", value: "12" },
                { label: "2", value: "6" },
                { label: "3", value: "4" },
                { label: "4", value: "3" },
            ],
            value: "12",
        },
        {
            key: "justify",
            label: "Alignement des colonnes",
            type: ContentsTypes.SELECT,
            options: [
                { label: "À gauche", value: "flex-start" },
                { label: "Centré", value: "center" },
                { label: "À droite", value: "flex-end" },
            ],
            value: "flex-start",
        },
    ],
};
