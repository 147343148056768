import InfoBlockPreview from "shared/components/contents/infoBlock/InfoBlockPreview";
import InfoBlockIcon from "shared/components/icons/InfoBlockIcon";
import ContentsTypes from "shared/enums/contentsTypes";
export var infoBlock = {
    label: "Bloc d'information",
    type: ContentsTypes.INFO_BLOCK,
    IconComponent: InfoBlockIcon,
    editionModal: true,
    editionModalPosition: "top",
    Preview: InfoBlockPreview,
    children: [
        {
            label: "icon",
            key: "icon",
            type: ContentsTypes.ICON,
        },
        {
            label: "Texte",
            key: "text",
            type: ContentsTypes.TEXT,
        },
    ],
};
