import ContentsTypes from "shared/enums/contentsTypes";
export var blockTitle = {
    label: "Titre de bloc",
    type: ContentsTypes.BLOCKTITLE,
    icon: "text",
    editionModal: true,
    editionModalPosition: "top",
    children: [
        {
            label: "Titre",
            key: "text",
            type: ContentsTypes.INPUT,
            value: "",
        },
        {
            label: "Component",
            key: "component",
            type: ContentsTypes.SELECT,
            options: [
                { label: "h1", value: "h1" },
                { label: "h2", value: "h2" },
                { label: "h3", value: "h3" },
                { label: "h4", value: "h4" },
                { label: "h5", value: "h5" },
                { label: "h6", value: "h6" },
            ],
            value: "",
        },
        {
            label: "Variant",
            key: "variant",
            type: ContentsTypes.SELECT,
            options: [
                { label: "h1", value: "h1" },
                { label: "h2", value: "h2" },
                { label: "h3", value: "h3" },
                { label: "h4", value: "h4" },
                { label: "h5", value: "h5" },
                { label: "h6", value: "h6" },
            ],
            value: "",
        },
        {
            label: "Font",
            key: "fontWeight",
            type: ContentsTypes.SELECT,
            options: [
                { label: "light", value: "light" },
                { label: "regular", value: "regular" },
                { label: "medium", value: "mediummedium" },
                { label: "bold", value: "bold" },
            ],
            value: "",
        },
        {
            label: "Alignement",
            key: "textAlign",
            type: ContentsTypes.RADIO_GROUP,
            options: [
                { label: "à gauche", value: "left" },
                { label: "centré", value: "center" },
                { label: "à droite", value: "right" },
            ],
            value: "",
        },
    ],
};
