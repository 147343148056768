var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _this = this;
import dayjs from "dayjs";
var timer;
export var debounce = function (callback, delay) {
    return function () {
        var argument = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            argument[_i] = arguments[_i];
        }
        var args = argument;
        var context = _this;
        clearTimeout(timer);
        timer = setTimeout(function () {
            callback.apply && callback.apply(context, args);
        }, delay);
    };
};
// see momentjs.com to choose your format
export var getDateFromTimestamp = function (date, format) { return dayjs(date).format(format); };
export var frenchNameRegex = /[a-zA-Z0-9 àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]/g;
export var emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/g;
export var urlRegex = "^(https?):\\/\\/[^\\s$.?#].[^\\s]*$";
export var latitudeRegex = /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?)$/g;
export var longitudeRegex = /^[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/g;
var encodingValue = "encoding_Left_Chevron";
var encodingRegex = new RegExp(encodingValue, "g");
export var encodeCodeBlockText = function (value) { return value.replace(/</g, encodingValue); };
export var decodeCodeBlockText = function (value) {
    return value.replace(encodingRegex, "<");
};
export var removeProperties = function (data, properties) {
    var newObject = Object.keys(data)
        .filter(function (key) { return !properties.includes(key); })
        .reduce(function (filtered, key) {
        var _a;
        return (__assign(__assign({}, filtered), (_a = {}, _a[key] = data[key], _a)));
    }, {});
    if (newObject.children) {
        newObject.children = newObject.children.map(function (c) { return removeProperties(c, properties); });
    }
    return newObject;
};
export var fullToolbarEditor = [
    {
        name: "paragraph",
        items: [
            "JustifyLeft",
            "JustifyCenter",
            "JustifyRight",
            "JustifyBlock",
            "-",
            "Outdent",
            "Indent",
            "-",
            "NumberedList",
            "BulletedList",
        ],
    },
    { name: "links", items: ["Link", "Unlink"] },
    { name: "insert", items: ["Image", "-", "Table", "-", "HorizontalRule"] },
    "/",
    {
        name: "basicstyles",
        items: ["Bold", "Italic", "Underline", "Strike", "Subscript", "Superscript"],
    },
    { name: "colors", items: ["TextColor", "BGColor"] },
    { name: "styles", items: ["Format"] },
];
export var descriptionToolbarEditor = [
    {
        name: "basicstyles",
        items: ["Bold", "Italic", "Underline"],
    },
];
export var standardToolbarEditor = [
    {
        name: "basicstyles",
        items: ["Bold", "Italic", "Underline", "Strike", "Subscript", "Superscript"],
    },
    {
        name: "paragraph",
        items: [
            "JustifyLeft",
            "JustifyCenter",
            "JustifyRight",
            "JustifyBlock",
            "-",
            "Outdent",
            "Indent",
            "-",
            "NumberedList",
            "BulletedList",
        ],
    },
    { name: "links", items: ["Link", "Unlink"] },
    { name: "styles", items: ["Format"] },
];
export var downloadFile = function (fileData, fileName) {
    var href = URL.createObjectURL(fileData);
    var link = document.createElement("a");
    link.href = href;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
};
