var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Icon from "shared/components/Icon";
import Link from "shared/components/contents/link/Link";
var Attachment = function (props) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
    var theme = useTheme();
    var _p = props.title, title = _p === void 0 ? null : _p, _q = props.description, description = _q === void 0 ? null : _q, _r = props.linkText, linkText = _r === void 0 ? null : _r, _s = props.file, file = _s === void 0 ? { name: "", url: "" } : _s, others = __rest(props, ["title", "description", "linkText", "file"]);
    var _t = file || {}, name = _t.name, url = _t.url;
    var id = others.id, othersProps = __rest(others, ["id"]);
    return (_jsxs(Box
    // @ts-ignore //@fixme: Review typing once the new version of MUI is installed
    , __assign({ 
        // @ts-ignore //@fixme: Review typing once the new version of MUI is installed
        sx: __assign(__assign({}, (_d = (_c = (_b = (_a = theme === null || theme === void 0 ? void 0 : theme.components) === null || _a === void 0 ? void 0 : _a.attachmentStyled) === null || _b === void 0 ? void 0 : _b.containerStyled) === null || _c === void 0 ? void 0 : _c.styleOverrides) === null || _d === void 0 ? void 0 : _d.root), { position: "relative" }) }, othersProps, { children: [title && (_jsx(Typography, { variant: "h5", sx: { mb: description ? 1 : 2, mt: 0 }, children: title })), description && _jsx(Typography, { sx: { mb: 2.5 }, children: description }), _jsx(Box
            // @ts-ignore //@fixme: Review typing once the new version of MUI is installed
            , { 
                // @ts-ignore //@fixme: Review typing once the new version of MUI is installed
                sx: (_j = (_h = (_g = (_f = (_e = theme === null || theme === void 0 ? void 0 : theme.components) === null || _e === void 0 ? void 0 : _e.attachmentStyled) === null || _f === void 0 ? void 0 : _f.linkStyled) === null || _g === void 0 ? void 0 : _g.styleOverrides) === null || _h === void 0 ? void 0 : _h.root) !== null && _j !== void 0 ? _j : undefined, children: url ? (_jsx("a", { target: "_blank", rel: "noopener noreferrer", href: url, style: { textDecoration: "none" }, download: name || true, children: linkText })) : (linkText) }), _jsx(Link, { url: url, external: true, children: _jsx(Box, { sx: __assign(__assign({}, (_o = (_m = (_l = (_k = theme === null || theme === void 0 ? void 0 : theme.components) === null || _k === void 0 ? void 0 : _k.attachmentStyled) === null || _l === void 0 ? void 0 : _l.iconStyled) === null || _m === void 0 ? void 0 : _m.styleOverrides) === null || _o === void 0 ? void 0 : _o.root), { position: "absolute" }), children: _jsx(Icon, { icon: "eye", title: "Voir le document" }) }) })] })));
};
export default Attachment;
