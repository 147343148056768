import ContentsTypes from "shared/enums/contentsTypes";
export var glossaryItem = {
    label: "Définition",
    type: ContentsTypes.GLOSSARY_ITEM,
    // editionModal: true,
    children: [
        {
            key: "word",
            label: "Mot / Acronyme",
            type: ContentsTypes.INPUT,
            value: "",
        },
        {
            label: "Description",
            key: "description",
            type: ContentsTypes.SHORT_TEXT,
            value: "",
        },
    ],
};
