import ContentsTypes from "shared/enums/contentsTypes";
import ContactTemplate from "templates/contact/ContactTemplate";
export default {
    key: "CONTACT",
    label: "Contact",
    templateImage: "/template-contact.png",
    Component: ContactTemplate,
    initialContents: [
        {
            key: "dynamicPart",
            type: ContentsTypes.DYNAMIC,
            value: "",
        },
        {
            key: "dynamicFormPart",
            type: ContentsTypes.DYNAMIC,
            value: "",
        },
    ],
};
