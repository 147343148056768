import ContentsTypes from "shared/enums/contentsTypes";
export var imageFilters = {
    label: "Filtres",
    type: ContentsTypes.IMAGE_FILTERS,
    children: [
        {
            label: "Ajouter des filtres",
            key: "activated",
            type: ContentsTypes.CHECKBOX,
            value: "false",
        },
        {
            label: "Opacité",
            key: "opacity",
            type: ContentsTypes.SLIDER,
            value: "1",
            min: "0",
            max: "1",
            step: "0.05",
        },
        {
            label: "Flou",
            key: "blur",
            type: ContentsTypes.SLIDER,
            value: "0",
            min: "0",
            max: "5",
            step: "0.25",
        },
        {
            label: "Échelle de gris",
            key: "grayscale",
            type: ContentsTypes.SLIDER,
            value: "0",
            min: "0",
            max: "1",
            step: "0.05",
        },
        {
            label: "Luminosité",
            key: "brightness",
            type: ContentsTypes.SLIDER,
            value: "1",
            min: "0",
            max: "3",
            step: "0.1",
        },
        {
            label: "Saturation",
            key: "saturate",
            type: ContentsTypes.SLIDER,
            value: "1",
            min: "0",
            max: "3",
            step: "0.2",
        },
        {
            label: "Sépia",
            key: "sepia",
            type: ContentsTypes.SLIDER,
            value: "0",
            min: "0",
            max: "1",
            step: "0.05",
        },
    ],
};
