var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import ImageContainer from "shared/components/contents/imageContainer/ImageContainer";
import Link from "shared/components/contents/link/Link";
var sectionStyled = function (reverse) { return ({
    mt: 4,
    border: "1px solid",
    borderColor: "primary.light",
    borderRadius: reverse ? "0 75px" : "75px 0",
    boxShadow: "0px 4px 5px rgb(0 0 0 / 25%)",
    p: 3,
    bgcolor: "white",
}); };
var imageParentStyled = function (reverse) { return ({
    maxWidth: 550,
    borderRadius: reverse ? "0 60px" : "60px 0",
    overflow: "hidden",
    display: "block",
    padding: 0,
}); };
var HomeSubpage = function (props) {
    var id = props.id, page = props.page, pageVersion = props.pageVersion, index = props.index, reverse = props.reverse, dynamicPart = props.dynamicPart, subtitle = props.subtitle, others = __rest(props, ["id", "page", "pageVersion", "index", "reverse", "dynamicPart", "subtitle"]);
    var _a = page || {}, image = _a.image, title = _a.title, shortDescription = _a.shortDescription;
    return (_jsx(Box, __assign({ sx: sectionStyled(reverse) }, others, { children: _jsxs(Grid, { container: true, direction: reverse ? "row-reverse" : "row", justifyContent: "space-between", children: [_jsx(Grid, { item: true, xs: 12, md: 5, sx: imageParentStyled(reverse), children: _jsx(Link, { page: pageVersion, children: image ? (_jsx(ImageContainer, { ratio: 64, children: _jsx(_Fragment, { children: image }) })) : (_jsx("div", {})) }) }), _jsxs(Grid, { item: true, xs: 12, md: 6, children: [title && (_jsx(Typography, { variant: "h3", component: "h3", children: title })), subtitle && _jsx(Typography, { variant: "subtitle1", children: subtitle }), _jsx(Box, { sx: { mt: 2 }, children: shortDescription }), dynamicPart] })] }) }), id));
};
export default HomeSubpage;
