var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s;
import { theme } from "@docaposte-agility/da-design-system";
import { createTheme } from "@mui/material/styles";
import { attachmentStyled } from "./styles/attachmentStyled";
import { glossaryStyled } from "./styles/glossaryStyled";
import { infoBlockStyled } from "./styles/infoBlockStyled";
import { scrollableTabsStyled } from "./styles/scrollableTabsStyled";
import { searchBarStyled } from "./styles/searchBarStyled";
import { textStyled } from "./styles/textStyled";
import { timeLineStepStyled } from "./styles/timeLineStepStyled";
var overrideKey = "customization";
var primary = {
    light: "#1D23F5",
    main: "#0000FF",
    dark: "#0000FF",
    text: "#343434",
};
var secondary = {
    light: "#F2F2FF",
    main: "#E5E5FD",
    dark: "#E5E5FD",
};
var border = "#ECECEF";
var containedButton = {
    backgroundColor: primary.main,
    "&:hover": {
        backgroundColor: primary.dark,
    },
    "&:active": {
        backgroundColor: primary.dark,
    },
};
var outlinedButton = {
    color: primary.main,
    backgroundColor: "white",
    borderColor: primary.main,
    "&:hover": {
        color: "white",
        backgroundColor: primary.light,
        borderColor: primary.dark,
    },
    "&:active": {
        color: "white",
        backgroundColor: primary.light,
        borderColor: primary.dark,
    },
};
var common = {
    consent: {
        wrap: {
            background: "white",
            borderColor: border,
        },
    },
};
export default createTheme(theme, (_a = {
        palette: {
            primary: primary,
            secondary: secondary,
            border: border,
            common: common,
        }
    },
    _a[overrideKey] = {
        Text: {
            text: __assign(__assign({}, (((_d = (_c = (_b = theme.components) === null || _b === void 0 ? void 0 : _b.MuiTypography) === null || _c === void 0 ? void 0 : _c.styleOverrides) === null || _d === void 0 ? void 0 : _d.root) || {})), { "& h2": (_g = (_f = (_e = theme.components) === null || _e === void 0 ? void 0 : _e.MuiTypography) === null || _f === void 0 ? void 0 : _f.styleOverrides) === null || _g === void 0 ? void 0 : _g.h2, "& h3": (_k = (_j = (_h = theme.components) === null || _h === void 0 ? void 0 : _h.MuiTypography) === null || _j === void 0 ? void 0 : _j.styleOverrides) === null || _k === void 0 ? void 0 : _k.h3, "& h4": (_o = (_m = (_l = theme.components) === null || _l === void 0 ? void 0 : _l.MuiTypography) === null || _m === void 0 ? void 0 : _m.styleOverrides) === null || _o === void 0 ? void 0 : _o.h4, "& h5": (_r = (_q = (_p = theme.components) === null || _p === void 0 ? void 0 : _p.MuiTypography) === null || _q === void 0 ? void 0 : _q.styleOverrides) === null || _r === void 0 ? void 0 : _r.h5, "& a": (_s = theme.components) === null || _s === void 0 ? void 0 : _s.MuiLink }),
        },
        Attachment: {
            container: {
                backgroundColor: "#D1EBFF",
                "&:hover": {
                    backgroundColor: "#9cd3fd",
                },
            },
        },
        Accordion: {
            expandIcon: {
                color: primary.main,
            },
        },
    },
    _a.components = {
        searchBarStyled: searchBarStyled,
        timeLineStepStyled: timeLineStepStyled,
        textStyled: textStyled,
        scrollableTabsStyled: scrollableTabsStyled,
        infoBlockStyled: infoBlockStyled,
        glossaryStyled: glossaryStyled,
        attachmentStyled: attachmentStyled,
        MuiLink: {
            styleOverrides: {
                root: {},
            },
        },
        MuiButton: {
            styleOverrides: {
                contained: {
                    "&:hover": {
                        color: "white",
                    },
                    "&:active": {
                        color: "white",
                    },
                },
                containedPrimary: containedButton,
                containedSecondary: containedButton,
                outlinedPrimary: outlinedButton,
                outlinedSecondary: outlinedButton,
            },
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    backgroundColor: primary.light,
                },
            },
        },
        MuiContainer: {
            defaultProps: {
                maxWidth: "md",
            },
        },
        MuiInput: {
            styleOverrides: {
                root: {
                    backgroundColor: secondary.light,
                    borderRadius: 7,
                    padding: 5,
                },
                underline: {
                    "&::before": {
                        border: "none",
                    },
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                input: {
                    fontSize: "1.2rem",
                    minHeight: 26,
                },
            },
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    width: "100%",
                },
            },
        },
        MuiAccordionSummary: {
            styleOverrides: {
                root: {
                    color: "#fff",
                    backgroundColor: primary.main,
                    minHeight: 40,
                    borderRadius: "10px",
                    "&$expanded": {
                        borderRadius: "10px 10px 0 0",
                    },
                },
            },
        },
        MuiSnackbar: {
            styleOverrides: {
                root: {
                    maxWidth: "auto",
                },
            },
        },
    },
    _a));
