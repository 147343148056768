import AttachmentPreview from "shared/components/contents/attachment/AttachmentPreview";
import ContentsTypes from "shared/enums/contentsTypes";
export var attachment = {
    label: "Pièce jointe",
    type: ContentsTypes.ATTACHMENT,
    icon: "paperclip",
    editionModal: true,
    editionModalPosition: "top",
    Preview: AttachmentPreview,
    requiredByKey: ["file"],
    children: [
        {
            label: "Choisir un document",
            key: "file",
            type: ContentsTypes.FILE,
        },
        {
            label: "Titre",
            key: "title",
            type: ContentsTypes.INPUT,
            value: "Pièce jointe",
        },
        {
            label: "Description",
            key: "description",
            type: ContentsTypes.INPUT,
            value: "",
        },
        {
            label: "Texte du lien",
            key: "linkText",
            type: ContentsTypes.INPUT,
            value: "Télécharger le fichier",
        },
    ],
};
