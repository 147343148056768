import ContentsTypes from "shared/enums/contentsTypes";
import SeveritiesTypes, { severityConfigs } from "shared/enums/severitiesTypes";
export var warningBanner = {
    type: ContentsTypes.WARNING_BANNER,
    label: "Bandeau d'information",
    children: [
        {
            label: "enable",
            key: "enable",
            type: ContentsTypes.CHECKBOX,
            value: "false",
        },
        {
            label: "message",
            key: "message",
            type: ContentsTypes.TEXT,
            value: "",
        },
        {
            key: "severity",
            type: ContentsTypes.SELECT,
            value: SeveritiesTypes.ERROR,
            label: "Type d'information",
            options: Object.keys(severityConfigs).map(function (severity) { return ({
                label: severityConfigs[severity].label,
                value: severityConfigs[severity].key,
            }); }),
        },
    ],
};
