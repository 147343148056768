import React from "react";
import PropTypes from "prop-types";
import ImageBanner from "components/ImageBanner";
import Container from "@mui/material/Container";

const DynamicTemplate = (props) => {
  const { page } = props;

  const { contents } = page;

  const { dynamicPart } = contents;

  return (
    <Container>
      <ImageBanner page={page} />

      {dynamicPart}
    </Container>
  );
};

DynamicTemplate.propTypes = {
  page: PropTypes.shape(),
};

DynamicTemplate.defaultProps = {
  page: {},
};

export default DynamicTemplate;
