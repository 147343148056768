import React, { useState, useEffect, useContext } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import ArticleService from "services/ArticleService";
import SubpageSearchContext from "components/SubpageSearchContext";
import { useLocation } from "react-router-dom";

const subpagesStyled = {
  bgcolor: "#FFFFFF",
  borderRadius: "20px",
  pt: 3,
  px: 4,
  pb: 1,
  "& h2": {
    fontWeight: 600,
    fontSize: 20,
    color: "text.secondary",
  },
  "& h3": {
    fontWeight: "bold",
    fontSize: 16,
    color: "text.secondary",
  },
};

const SubpageSearch = (props) => {
  const { id, maxPages, perPage, title, className, template, pageParent } = props;

  const { pathname } = useLocation();

  const { page: currentPage, SubPageTemplate } = useContext(SubpageSearchContext);
  const { id: pageId, page } = currentPage || {};

  const [recentPages, setRecentPages] = useState([]);

  useEffect(() => {
    const getRecentPages = async (nbPages) => {
      const newRecentPages = await ArticleService.getRecentArticles({
        template,
        pageId,
        perPage: nbPages,
        pageParent: typeof pageParent === "object" ? pageParent.page.id : pageParent || null,
        siteId: page.siteId,
        currentPath: pathname,
      });
      setRecentPages(newRecentPages);
    };

    if (pageId && (template || pageParent) && perPage && perPage.match(/^[0-9]+$/) && Number(perPage) > 0) {
      if (Number(perPage) > maxPages) {
        getRecentPages(maxPages).finally(() => {
          console.warn(`SubpageSearch: perPage is greater than maxPages (${perPage} > ${maxPages})`);
        });
      } else {
        getRecentPages(perPage).finally(() => {
          console.warn(`SubpageSearch: perPage is lower than maxPages (${perPage} < ${maxPages})`);
        });
      }
    } else {
      setRecentPages([]);
    }
  }, [pageId, template, pageParent, perPage, maxPages, page, pathname]);

  return (
    <>
      {recentPages && recentPages.length > 0 && (
        <Box key={id} className={className} sx={subpagesStyled}>
          {title && (
            <Typography variant="h2" sx={{ mb: 2 }}>
              {title}
            </Typography>
          )}
          {recentPages.map((recentPage) => (
            <SubPageTemplate key={recentPage.id} page={recentPage} />
          ))}
        </Box>
      )}
    </>
  );
};

SubpageSearch.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  perPage: PropTypes.string,
  maxPages: PropTypes.number,
  className: PropTypes.string,
  template: PropTypes.string,
  pageParent: PropTypes.node,
};

SubpageSearch.defaultProps = {
  id: "",
  title: "",
  perPage: "",
  maxPages: 10,
  className: "",
  template: "",
  pageParent: null,
};

export default SubpageSearch;
