import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Box from "@mui/material/Box";
import Icon from "shared/components/Icon";
import { severityConfigs } from "shared/enums/severitiesTypes";
import { Fragment } from "react";
import { useSites } from "shared/hooks/SiteHook";
var getSeverityData = function (severityValue) {
    var severity = Object.keys(severityConfigs).find(function (s) { return severityConfigs[s].key === severityValue; }) || "";
    return severityConfigs[severity];
};
var WarningBanner = function () {
    var _a, _b, _c;
    var site = useSites().currentSite;
    var siteSettings = site.settings;
    var warningBanner = (siteSettings || {}).warningBanner;
    var _d = warningBanner || {}, _e = _d.enable, enable = _e === void 0 ? false : _e, severity = _d.severity, message = _d.message;
    if (!enable) {
        return null;
    }
    return (_jsx(Fragment, { children: _jsxs(Box, { sx: {
                bgcolor: severity && ((_a = getSeverityData(severity)) === null || _a === void 0 ? void 0 : _a.backgroundColor),
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                "& > *": {
                    px: 1,
                },
            }, children: [_jsx(Icon, { icon: (_b = getSeverityData(severity)) === null || _b === void 0 ? void 0 : _b.icon, style: { fontSize: "24px", color: severity && ((_c = getSeverityData(severity)) === null || _c === void 0 ? void 0 : _c.iconColor) }, title: "Bandeau d'information" }), _jsx("div", { children: message })] }) }));
};
export default WarningBanner;
