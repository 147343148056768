var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import JsonLd from "shared/components/JsonLd";
import { getTemplateComponentByKey } from "shared/utils/templateUtils";
import { createUrlWithoutQuery } from "shared/utils/urlUtils";
import Helmet from "react-helmet";
import { formatPageData } from "frontoffice/utils/templates/formatPageData";
import { useSites } from "shared/hooks/SiteHook";
var Page = function (props) {
    var page = props.page, _a = props.templatesSettings, templatesSettings = _a === void 0 ? {} : _a;
    var site = useSites().currentSite;
    var siteSettings = site.settings, host = site.host, siteName = site.name;
    var socialNetworks = (siteSettings || {}).socialNetworks;
    var _b = siteSettings || {}, _c = _b.logoHeader, logoHeader = _c === void 0 ? null : _c, logoFooter = _b.logoFooter, logoFavicon = _b.logoFavicon;
    var faviconPath = (logoFavicon === null || logoFavicon === void 0 ? void 0 : logoFavicon.url) || "/favicon.ico";
    var _d = page !== null && page !== void 0 ? page : {}, title = _d.title, description = _d.description, image = _d.image, template = _d.template, fullPath = _d.fullPath;
    if (socialNetworks && !Array.isArray(socialNetworks)) {
        socialNetworks = [socialNetworks];
    }
    if (!page || !page.id) {
        return null;
    }
    var TemplateTag = getTemplateComponentByKey(template);
    var getLogoPath = function () {
        var logo = logoHeader || logoFooter;
        return logo && logo.props && logo.props.file ? logo.props.file.url : "";
    };
    var structuredData = "{\n    \"@context\": \"https://schema.org\",\n    \"@type\": \"Organization\",\n    \"name\": \"".concat(siteName, "\",\n    \"url\": \"").concat(host).concat(fullPath, "\",\n    \"logo\":\"").concat(host).concat(getLogoPath(), "\",\n    \"sameAs\": [\n      ").concat(socialNetworks &&
        socialNetworks.reduce(function (str, socialNetwork, index) {
            return "".concat(str, "\"").concat(socialNetwork.url, "\"").concat(socialNetworks.length !== index + 1 ? "," : "", " ");
        }, ""), "\n    ]\n  }");
    return (_jsxs(_Fragment, { children: [_jsxs(Helmet, { children: [_jsx("title", { children: title }), _jsx("meta", { name: "Description", content: description || "" }), _jsx("meta", { property: "og:url", content: "".concat(host).concat(fullPath) }), _jsx("meta", { property: "og:title", content: title || "" }), _jsx("meta", { property: "og:description", content: description || "" }), _jsx("link", { rel: "icon", type: "image/x-icon", href: faviconPath }), image && _jsx("meta", { property: "og:image", content: image.url || "" }), _jsx("meta", { property: "og:type", content: "website" }), _jsx("link", { rel: "canonical", href: createUrlWithoutQuery("".concat(host).concat(fullPath)) })] }), _jsx(JsonLd, { structuredData: structuredData }), _jsx(TemplateTag, __assign({ page: formatPageData(page) }, templatesSettings))] }));
};
export default Page;
