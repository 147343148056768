import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import Text from "shared/components/contents/text/Text";
import Timeline from "shared/components/contents/timeLine/TimeLine";
import TimelineStep from "shared/components/contents/timeLineStep/TimeLineStep";
var steps = new Array(3)
    .fill("")
    .map(function (p, i) { return (_jsx(TimelineStep, { id: i, title: _jsxs(Text, { children: ["`Titre $", i + 1, "`"] }), content: _jsx(Text, { children: "Zone de texte libre ".concat(i + 1) }) }, i)); });
var TimelinePreview = function () {
    return _jsx(Timeline, { steps: steps });
};
export default TimelinePreview;
