import ContentsTypes from "shared/enums/contentsTypes";
export var icon = {
    label: "Icône",
    type: ContentsTypes.ICON,
    value: "",
    children: [
        {
            label: "Nom",
            key: "icon",
            type: ContentsTypes.INPUT,
            value: "",
        },
        {
            label: "Type",
            key: "type",
            type: ContentsTypes.SELECT,
            options: [
                { label: "Solid", value: "fas" },
                { label: "Regular", value: "far" },
                { label: "Light", value: "fal" },
                { label: "Duotone", value: "fad" },
                { label: "Brands", value: "fab" },
            ],
            value: "fal",
        },
        {
            label: "Couleur",
            type: ContentsTypes.COLOR,
            key: "color",
            value: "",
        },
        {
            label: "Couleur de fond",
            type: ContentsTypes.COLOR,
            key: "background",
            value: "",
        },
    ],
};
