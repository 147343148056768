var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import classnames from "classnames";
import sanitizeHtml from "sanitize-html";
export var componentClassName = "Da-Text";
var sanitizerOptions = __assign(__assign({}, sanitizeHtml.defaults), { allowedTags: sanitizeHtml.defaults.allowedTags.concat(["h2", "img", "u", "s", "sub", "address", "sup"]), allowedAttributes: __assign(__assign({}, sanitizeHtml.defaults.allowedAttributes), { "*": ["id", "class", "style", "title"], a: ["href", "name", "target"], img: ["src", "target", "alt"] }), allowedSchemesByTag: __assign(__assign({}, sanitizeHtml.defaults.allowedSchemesByTag), { img: ["https", "data"] }) });
var Text = function (props) {
    var _a, _b, _c;
    var theme = useTheme();
    var _d = props.children, children = _d === void 0 ? null : _d, _e = props.className, className = _e === void 0 ? "" : _e, others = __rest(props, ["children", "className"]);
    var childrenProps = {};
    if (typeof children === "string") {
        childrenProps.dangerouslySetInnerHTML = { __html: sanitizeHtml(decodeURIComponent(children), sanitizerOptions) };
    }
    else {
        childrenProps.children = children;
    }
    return (_jsx(Box, __assign({ sx: (_c = (_b = (_a = theme === null || theme === void 0 ? void 0 : theme.components) === null || _a === void 0 ? void 0 : _a.textStyled) === null || _b === void 0 ? void 0 : _b.styleOverrides) === null || _c === void 0 ? void 0 : _c.root, className: classnames(className, componentClassName) }, childrenProps, others)));
};
export default Text;
