var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Typography from "@mui/material/Typography";
import Box from "@mui/system/Box";
import ImageContainer from "shared/components/contents/imageContainer/ImageContainer";
import { useState } from "react";
var Trombinoscope = function (props) {
    var id = props.id, image = props.image, imageHover = props.imageHover, position = props.position, name = props.name, nickname = props.nickname, link = props.link, others = __rest(props, ["id", "image", "imageHover", "position", "name", "nickname", "link"]);
    var _a = useState(image || imageHover || undefined), currentImage = _a[0], setCurrentImage = _a[1];
    return (_jsxs(Box, __assign({ sx: {
            borderRadius: 4,
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
        } }, others, { children: [(image || imageHover) && (_jsx(Box, { sx: { maxHeight: 450, overflow: "hidden" }, onMouseOver: function () { return imageHover && setCurrentImage(imageHover); }, onMouseOut: function () { return image && setCurrentImage(image); }, children: _jsx(ImageContainer, { ratio: "", children: currentImage }) })), _jsxs(Box, { sx: { borderColor: "#F5F5F5", borderStyle: "solid", borderWidth: 2 }, children: [position && (_jsx(Box, { sx: {
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            bgcolor: "#FFC617",
                            borderRadius: 2,
                            px: 2,
                            py: 0.625,
                            fontWeight: 500,
                            color: "#FFFFFF",
                            width: "65%",
                            minWidth: 180,
                            mx: "auto",
                            mt: -2,
                        }, children: position })), _jsxs(Box, { sx: {
                            textAlign: "center",
                            p: 2.5,
                        }, children: [name && (_jsx(Typography, { variant: "h4", sx: { color: "#585858" }, children: name })), nickname && (_jsx(Typography, { variant: "body1", sx: { color: "#CCCCCC", fontWeight: 500, my: 2 }, children: nickname })), link && (_jsx("a", { href: link, children: _jsx("img", { src: "/linkedin_logo.svg", alt: "Logo LinkedIn", width: 20 }) }))] })] })] }), id));
};
Trombinoscope.defaultProps = {
    id: "",
    image: null,
    imageHover: null,
    position: "",
    name: "",
    nickname: "",
    link: "",
};
export default Trombinoscope;
