import { jsx as _jsx } from "react/jsx-runtime";
import { createInstance, MatomoProvider } from "@datapunt/matomo-tracker-react";
import useEnvironment from "shared/hooks/useEnvironment";
import { useEffect, useState } from "react";
export var MatomoContextProvider = function (_a) {
    var isBo = _a.isBo, children = _a.children;
    var environment = useEnvironment();
    var _b = useState(null), instance = _b[0], setInstance = _b[1];
    useEffect(function () {
        if (!isBo && environment && environment.matomo_track_url && environment.matomo_site_id) {
            var options = {
                urlBase: environment.matomo_track_url,
                siteId: Number(environment.matomo_site_id),
                configurations: {
                    disableCookies: true,
                },
            };
            setInstance(createInstance(options));
        }
    }, [isBo, environment]);
    if (!instance) {
        return children;
    }
    // @ts-ignore fixme: monitor the evolution of the lib to better manage the children's
    return _jsx(MatomoProvider, { value: instance, children: children });
};
