import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CacheProvider } from "@emotion/react";
import clientFront from "clientFront";
import createEmotionCache from "./createEmotionCache";
import merge from "lodash.merge";
import React from "react";
import { HydrationBoundary, QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { BrowserRouter as Router } from "react-router-dom";
import CmsHooks from "shared/utils/register/CmsHooks";
import App from "frontoffice/App";
import { HOOKS } from "shared/utils/register/definitions";
import { hydrateRoot } from "react-dom/client";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
clientFront();
var cache = createEmotionCache({});
var hookQueryClientOptions = CmsHooks.getHook(HOOKS.clientFront_queryClientOptions) || {};
var queryClientOptions = merge({}, {
    defaultOptions: {
        queries: {
            staleTime: Infinity,
        },
    },
}, hookQueryClientOptions);
var queryClient = new QueryClient(queryClientOptions);
var dehydratedState = window["__REACT_QUERY_STATE__"];
function Main() {
    React.useEffect(function () {
        var _a;
        var jssStyles = document.querySelector("#jss-server-side");
        if (jssStyles) {
            (_a = jssStyles.parentNode) === null || _a === void 0 ? void 0 : _a.removeChild(jssStyles);
        }
    }, []);
    return (_jsxs(QueryClientProvider, { client: queryClient, children: [window["__REACT_QUERY_DEBUG__"] && _jsx(ReactQueryDevtools, { initialIsOpen: false }), _jsx(HydrationBoundary, { state: dehydratedState, children: _jsx(CacheProvider, { value: cache, children: _jsx(Router, { children: _jsx(App, {}) }) }) })] }));
}
hydrateRoot(document.querySelector("#root"), _jsx(Main, {}));
