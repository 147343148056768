import contentsTypes from "shared/enums/contentsTypes";
import AgileItTemplate from "templates/agile-it/AgileItTemplate";
export default {
    key: "AGILE-IT",
    label: "Agile-it",
    templateImage: "/template-blog.png",
    Component: AgileItTemplate,
    initialContents: [
        {
            key: "dynamicPart",
            type: contentsTypes.DYNAMIC,
            value: "",
        },
        {
            key: "subpages",
            type: contentsTypes.SUBPAGES_LIST,
            value: "",
            children: [],
        },
    ],
};
