import { jsx as _jsx } from "react/jsx-runtime";
import Accordion from "shared/components/contents/accordion/Accordion";
import Text from "shared/components/contents/text/Text";
import { v4 as uuidV4 } from "uuid";
var details = _jsx(Text, { children: "Dans cette zone vous serez libre d'ajouter diff\u00E9rents contenus" });
var panels = new Array(3).fill("").map(function (p, i) { return ({
    summary: "Panneau d\u00E9pliant ".concat(i + 1),
    details: details,
    id: uuidV4(),
}); });
var AccordionPreview = function () {
    return _jsx(Accordion, { panels: panels });
};
export default AccordionPreview;
