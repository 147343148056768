import AdminPageVersionsService from "administration/services/AdminPageVersionsService";
import { useSites } from "shared/hooks/SiteHook";
import { useCallback } from "react";
import { useCurrentPage } from "./AdminPageHook";
import { useQuery } from "@tanstack/react-query";
import { PageVersionStatus } from "shared/types/cms/page/version/page-version.status.enum";
import useAdminPageVersionStore from "administration/store/AdminPageVersionStore";
import { useShallow } from "zustand/react/shallow";
import useAdminEditorStore from "administration/store/AdminEditorStore";
export var usePageVersion = function () {
    var currentSite = useSites().currentSite;
    var currentPage = useCurrentPage().currentPage;
    var _a = useQuery({
        queryKey: ["pageversions", currentSite.id, currentPage === null || currentPage === void 0 ? void 0 : currentPage.id],
        queryFn: function () { return AdminPageVersionsService.getPageVersions(currentPage === null || currentPage === void 0 ? void 0 : currentPage.id); },
        enabled: currentSite != null && currentPage != null,
        initialData: [],
        select: function (versions) {
            return versions.sort(function (_a, _b) {
                var status1 = _a.status;
                var status2 = _b.status;
                if (status1 === status2 || ![status1, status2].includes(PageVersionStatus.ARCHIVED)) {
                    return 0;
                }
                return status1 === PageVersionStatus.ARCHIVED ? 1 : -1;
            });
        },
    }), pageVersions = _a.data, refetch = _a.refetch;
    return { pageVersions: pageVersions, refreshPageVersions: refetch };
};
export var useHandleChangePageVersion = function () {
    var setCurrentPageVersion = (useAdminPageVersionStore(useShallow(function (state) { return ({
        setCurrentPageVersion: state.setCurrentPageVersion,
    }); })) || { id: null, contents: undefined }).setCurrentPageVersion;
    var setCurrentPageVersionId = useAdminEditorStore(function (state) { return ({
        setCurrentPageVersionId: state.setCurrentPageVersionId,
    }); }).setCurrentPageVersionId;
    var handleChangePageVersion = useCallback(function (pageVersion) {
        setCurrentPageVersionId(pageVersion.id);
        setCurrentPageVersion(pageVersion);
    }, [setCurrentPageVersion, setCurrentPageVersionId]);
    return { handleChangePageVersion: handleChangePageVersion };
};
