import { create } from "zustand";
var useSnackbarStore = create(function (set, get) { return ({
    open: false,
    queue: [],
    displayError: function (message, severity) {
        var state = get();
        state.queue.push({ message: message, severity: "error" });
        set({
            duration: 5000,
            open: true,
            queue: state.queue,
        });
    },
    displaySuccess: function (message) {
        var state = get();
        state.queue.push({ message: message, severity: "success" });
        set({
            duration: 5000,
            open: true,
            queue: state.queue,
        });
    },
    handleCloseSnackbar: function () {
        set({ open: false });
    },
    handleErrorStack: function () {
        var state = get();
        if (state.queue.length >= 1) {
            state.queue.shift();
            set({ open: state.queue.length > 0, queue: state.queue });
        }
    },
}); });
export default useSnackbarStore;
