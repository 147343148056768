var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Grid, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, } from "@mui/material";
import { TableHeadCellOfConsent } from "shared/types/cookies.entity";
var CookiesTable = function (_a) {
    var cookiesRows = _a.cookiesRows, checked = _a.checked, _b = _a.onChange, onChange = _b === void 0 ? function () { return undefined; } : _b, _c = _a.disabled, disabled = _c === void 0 ? false : _c, _d = _a.description, description = _d === void 0 ? null : _d, subtitle = _a.subtitle, switchLabel = _a.switchLabel;
    var TableHeadCells = __spreadArray([], Object.values(TableHeadCellOfConsent), true);
    // const [switchStateLabel, setSwitchStateLabel] = useState<ConsentStatusLabel | undefined>();
    // const [labelLeftPosition, setLabelLeftPosition] = useState<CSSPosition>(CSSPosition.LEFT_NINE);
    // useEffect(() => {
    //   if (disabled) {
    //     setSwitchStateLabel(ConsentStatusLabel.REQUIRED);
    //     setLabelLeftPosition(CSSPosition.LEFT_SEVEN);
    //   } else if (checked) {
    //     setSwitchStateLabel(ConsentStatusLabel.ACTIVATED);
    //     setLabelLeftPosition(CSSPosition.LEFT_NINE);
    //   } else {
    //     setSwitchStateLabel(ConsentStatusLabel.DISABLED);
    //     setLabelLeftPosition(CSSPosition.LEFT_THREE);
    //   }
    // }, [checked, disabled]);
    return (_jsxs(_Fragment, { children: [_jsxs(Grid, { container: true, alignItems: "center", wrap: "nowrap", children: [_jsx(Grid, { item: true, xs: "auto", md: 1, sx: { position: "relative" }, children: _jsx(Switch, { checked: checked, onChange: onChange, disabled: disabled }) }), _jsx(Typography, { component: "span", variant: "h6", children: subtitle })] }), description && (_jsx(Grid, { container: true, justifyContent: "flex-end", sx: { mt: "12px" }, children: _jsx(Grid, { item: true, xs: 12, md: 11, children: _jsx(Typography, { component: "span", variant: "body2", children: description }) }) })), cookiesRows && (_jsx(Grid, { container: true, justifyContent: "flex-end", children: _jsx(Grid, { item: true, xs: 12, md: 11, children: _jsx(TableContainer, { children: _jsxs(Table, { "aria-label": "Description des cookies", children: [_jsx(TableHead, { children: _jsx(TableRow, { children: TableHeadCells.map(function (cell) { return (_jsx(TableCell, { children: cell }, cell)); }) }) }), _jsx(TableBody, { children: cookiesRows.map(function (row) { return (_jsxs(TableRow, { children: [_jsx(TableCell, { children: row.name }), _jsx(TableCell, { children: row.finality }), _jsx(TableCell, { children: row.conservation })] }, row.id)); }) })] }) }) }) }))] }));
};
export default CookiesTable;
