import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import Input from "@mui/material/Input";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import FormHelperText from "@mui/material/FormHelperText";
import { frenchNameRegex, emailRegex } from "shared/utils/commonUtils";
import useEnvironment from "shared/hooks/useEnvironment";
import ReCAPTCHA from "react-google-recaptcha";
import Icon from "shared/components/Icon";
import ContactService from "services/ContactService";
import CaptchaService from "services/CaptchaService";
import useCookieStore from "shared/store/CookieStore";

const loaderStyled = {
  position: "absolute",
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  bgcolor: "#eff4fd",
  opacity: "0.5",
  zIndex: 1000,
  "& svg": {
    color: "secondary.main",
  },
};

const inputStyled = {
  "& .cke_editable, & input": {
    border: "none",
  },
  "& textarea": {
    pt: 0.5,
  },
};

const formValidatedStyled = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
  px: 0,
  py: 10,
};

export const recaptchaStyled = {
  "& .grecaptcha-badge": {
    bottom: { xs: "123px !important", lg: 0 },
    zIndex: "525",
  },
};

const ContactForm = (props) => {
  const { id, recipient, ...others } = props;

  const { recaptcha_site_key_invisible, mail_attachment_size, mail_allowed_attachment_type } = useEnvironment();

  const [loader, setLoader] = useState(false);
  const [formSended, setFormSended] = useState(false);
  const [errors, setErrors] = useState({});
  const [sendError, setSendError] = useState(null);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [form, setForm] = useState({
    name: "",
    firstName: "",
    email: "",
    subject: "",
    message: "",
    consent: false,
  });
  const [filesForm, setFilesForm] = useState(null);
  const recaptchaRef = React.createRef();

  const { googleRecaptchaConsent } = useCookieStore((state) => ({
    googleRecaptchaConsent: state.googleRecaptchaConsent,
  }));

  const handleSubmit = (event) => {
    event.preventDefault();
    handleValidate(new FormData(event.target));
  };

  const handleFormChange = (e) => {
    const { type, value, checked, name: fieldName } = e.target;
    setForm({
      ...form,
      [fieldName]: type === "checkbox" ? Boolean(checked) : value,
    });
  };

  useEffect(() => {
    const { name, firstName, email, subject, message, consent } = form;

    const reducer = (previousValue, currentValue) => {
      return (previousValue || 0) + (currentValue || 0);
    };
    const filesSize = Array.isArray(filesForm) && filesForm.map((fileForm) => fileForm.size).reduce(reducer, 0);

    setErrors({
      name: !(name && name.match(frenchNameRegex)),
      firstName: !(firstName && firstName.match(frenchNameRegex)),
      email: !(email && email.match(emailRegex)),
      subject: !subject,
      message: !message,
      fileForm: filesSize > mail_attachment_size,
      consent: !consent,
    });
  }, [form, filesForm, mail_attachment_size]);

  const showError = (key) => formSubmitted && errors[key];

  const InputAdornmentError = (
    <InputAdornment error position="end" sx={{ color: "error.main", fontSize: "1.71rem" }}>
      <Icon icon="exclamation-circle" title="Erreur" />
    </InputAdornment>
  );

  const isFormValid = Object.values(errors).reduce((a, b) => a && !b, true);

  const sendMail = () => {
    const formData = new FormData();
    Array.isArray(filesForm) &&
      filesForm.forEach((fileForm) => {
        formData.append("attachment", fileForm, fileForm.name);
      });
    formData.append("recipient", recipient);
    formData.append("name", form.name);
    formData.append("firstName", form.firstName);
    formData.append("email", form.email);
    formData.append("subject", form.subject);
    formData.append("message", decodeURIComponent(encodeURIComponent(form.message).replace(/%0A/g, "<br/>")));
    ContactService.postMail(formData).then((response) => {
      if (response && response.status && response.status === 400) {
        setSendError(response.message);
      } else {
        setFormSended(true);
        setForm({
          name: "",
          firstName: "",
          email: "",
          subject: "",
          message: "",
        });
        setFilesForm(null);
      }
      setLoader(false);
    });
  };

  const checkUser = async (value) => {
    const recaptchaValidated = await CaptchaService.verifyCaptcha(value);
    if (recaptchaValidated.success) {
      sendMail();
    } else {
      setLoader(false);
    }
  };

  const handleValidate = (formData) => {
    setFormSubmitted(true);
    if (isFormValid) {
      setLoader(true);
      if (!recaptcha_site_key_invisible) {
        sendMail();
      } else {
        recaptchaRef.current.execute();
      }
    }
  };

  const handleUploadFile = (e) => {
    const fileToUpload = e.target.files[0];
    setFilesForm(filesForm ? [...filesForm, fileToUpload] : [fileToUpload]);
    e.target.value = null;
  };

  const handleRemoveFile = (fileFormName) => {
    setFilesForm(filesForm.length > 1 ? filesForm.filter((fileForm) => fileForm.name !== fileFormName) : null);
  };

  const handleBackToForm = () => {
    setFormSubmitted(false);
    setFormSended(false);
  };

  return (
    <Box key={id} {...others}>
      {!formSended && (
        <Box
          component="form"
          action="/"
          method="POST"
          sx={{
            "& p": {
              fontWeight: 600,
            },
          }}
          onSubmit={handleSubmit}
        >
          {loader && (
            <Grid container justify="center" alignItems="center" sx={loaderStyled}>
              <CircularProgress />
            </Grid>
          )}
          <FormControl hiddenLabel margin="dense" error={showError("name")}>
            <Typography color="primary">Nom</Typography>
            <Input
              id="name"
              name="name"
              value={form.name}
              error={showError("name")}
              endAdornment={showError("name") && InputAdornmentError}
              onChange={handleFormChange}
              sx={inputStyled}
              disableUnderline
            />
            {showError("name") && <FormHelperText>Votre nom n&apos;est pas valide</FormHelperText>}
          </FormControl>

          <FormControl hiddenLabel margin="dense" error={showError("firstName")}>
            <Typography color="primary">Prénom</Typography>
            <Input
              id="firstName"
              name="firstName"
              value={form.firstName}
              error={showError("firstName")}
              endAdornment={showError("firstName") && InputAdornmentError}
              onChange={handleFormChange}
              sx={inputStyled}
              disableUnderline
            />
            {showError("firstName") && <FormHelperText>Votre prénom n&apos;est pas valide</FormHelperText>}
          </FormControl>

          <FormControl hiddenLabel margin="dense" error={showError("email")}>
            <Typography color="primary">E-mail</Typography>
            <Input
              id="email"
              name="email"
              value={form.email}
              error={showError("email")}
              endAdornment={showError("email") && InputAdornmentError}
              onChange={handleFormChange}
              type="email"
              sx={inputStyled}
              disableUnderline
            />
            {showError("email") && (
              <FormHelperText>Le format de l&apos;adresse mail n&apos;est pas correct</FormHelperText>
            )}
          </FormControl>

          <FormControl hiddenLabel margin="dense" error={showError("subject")}>
            <Typography color="primary">Sujet</Typography>
            <Input
              id="subject"
              name="subject"
              value={form.subject}
              error={showError("subject")}
              endAdornment={showError("subject") && InputAdornmentError}
              onChange={handleFormChange}
              sx={inputStyled}
              disableUnderline
            />
            {showError("subject") && <FormHelperText>Champ obligatoire</FormHelperText>}
          </FormControl>

          <FormControl hiddenLabel margin="dense" error={showError("message")}>
            <Typography color="primary">Message</Typography>
            <Input
              id="message"
              name="message"
              value={form.message}
              error={showError("message")}
              endAdornment={showError("message") && InputAdornmentError}
              onChange={handleFormChange}
              multiline
              rows={10}
              sx={inputStyled}
              disableUnderline
            />
            {showError("message") && <FormHelperText>Champ obligatoire</FormHelperText>}
          </FormControl>

          <input
            accept={mail_allowed_attachment_type}
            id="input-file-attachment"
            type="file"
            style={{ display: "none" }}
            onChange={handleUploadFile}
          />
          <label htmlFor="input-file-attachment">
            <Button variant="outlined" component="span" sx={{ mt: 2, mb: 1 }}>
              {`Pièces jointes ${mail_allowed_attachment_type ? `, uniquement (${mail_allowed_attachment_type})` : ""}`}
            </Button>
          </label>
          {Array.isArray(filesForm) &&
            filesForm.map((fileForm, index) => {
              return (
                fileForm.name && (
                  <FormHelperText key={fileForm.name + index}>
                    <Icon icon="paperclip" title="Pièce jointe" /> {fileForm.name}{" "}
                    <Icon
                      icon="times"
                      onClick={() => handleRemoveFile(fileForm.name)}
                      style={{ paddingLeft: 8, cursor: "pointer" }}
                    />
                  </FormHelperText>
                )
              );
            })}
          {showError("fileForm") && (
            <FormHelperText error>
              <Icon icon="exclamation-circle" title="Erreur" />
              &nbsp;La taille des pièces jointes dépasse la taille maximale autorisée.
            </FormHelperText>
          )}
          <FormControl error={showError("consent")}>
            <FormControlLabel
              control={
                <Checkbox name="consent" checked={form.consent} onChange={handleFormChange} sx={{ color: "red" }} />
              }
              label={
                <>
                  Les données transmises dans le formulaire pourront être utilisées par DOCAPOSTE AGILITY pour vous
                  contacter. Pour plus d'informations sur le traitement des données, consultez notre{" "}
                  <a href="/politique-de-confidentialite" target="_blank" rel="noopener noreferrer">
                    Politique de confidentialité et de protection des données personnelles.
                  </a>
                </>
              }
              sx={{
                ml: 0,
                alignItems: "flex-start",
                "& .MuiCheckbox-root": {
                  borderColor: showError("consent") ? "error.main" : "primary.main",
                  mt: "5px",
                },
                "&:not(.Mui-disabled):hover .MuiCheckbox-root:not(.Mui-checked)": {
                  borderColor: showError("consent") ? "error.main" : "primary.main",
                  // }
                },
                "& .MuiFormControlLabel-label": {
                  flex: 1,
                },
              }}
            />
            {showError("consent") && (
              <FormHelperText>Vous devez accepter les conditions ci-dessus pour continuer</FormHelperText>
            )}
          </FormControl>

          {recaptcha_site_key_invisible && googleRecaptchaConsent && (
            <Box sx={recaptchaStyled}>
              <ReCAPTCHA
                ref={recaptchaRef}
                size="invisible"
                sitekey={recaptcha_site_key_invisible}
                onChange={(recaptchaValue) => {
                  checkUser(recaptchaValue);
                }}
              />
            </Box>
          )}

          <Box display="flex" flexDirection="row-reverse">
            <Tooltip
              title={
                recaptcha_site_key_invisible && !googleRecaptchaConsent
                  ? 'Vous devez accepter les cookies "Google reCAPTCHA" pour utiliser cette fonctionnalité'
                  : ""
              }
            >
              <Box>
                <Button
                  disabled={recaptcha_site_key_invisible && !googleRecaptchaConsent}
                  variant="contained"
                  color="primary"
                  type="submit"
                  sx={{ mt: 2 }}
                >
                  Envoyer
                </Button>
              </Box>
            </Tooltip>
          </Box>
          {sendError && (
            <FormHelperText error sx={{ fontSize: "1rem" }}>
              <Icon icon="exclamation-circle" title="Erreur" />
              &nbsp;{sendError}
            </FormHelperText>
          )}
        </Box>
      )}
      {formSended && (
        <Box sx={formValidatedStyled}>
          <Box pb={3}>
            <Icon
              icon="check-circle"
              type="fas"
              title="Message envoyé"
              style={{ fontSize: "48px", color: "#24821a" }}
            />
          </Box>
          <Box pb={[1.5]}>
            <Typography variant="h5" sx={{ fontSize: "16px", fontWeight: "600" }}>
              Votre message a bien été envoyé
            </Typography>
          </Box>
          <Button variant="outlined" onClick={handleBackToForm} sx={{ mt: 2 }}>
            Retour au formulaire
          </Button>
        </Box>
      )}
    </Box>
  );
};

ContactForm.propTypes = {
  recipient: PropTypes.string,
};

ContactForm.defaultProps = {
  recipient: null,
};

export default ContactForm;
