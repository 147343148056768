import useEnvironment from "shared/hooks/useEnvironment";
import CookiesConsentService, { COOKIE_CONSENT_GOOGLE_RECAPTCHA_NAME } from "shared/services/CookiesConsentService";
import { useCallback, useEffect } from "react";
import useCookieStore from "shared/store/CookieStore";
export var useCookiesBanner = function () {
    var _a = useCookieStore(function (state) { return ({
        handleCookiesModalContext: state.handleCookiesModal,
        openCookiesModal: state.openCookiesModal,
        isDisabledBanner: state.isDisabledBanner,
        disabledBanner: state.disabledBanner,
        setIsDisabledBanner: state.setIsDisabledBanner,
        setGoogleRecaptchaConsent: state.setGoogleRecaptchaConsent,
    }); }), handleCookiesModalContext = _a.handleCookiesModalContext, openCookiesModal = _a.openCookiesModal, isDisabledBanner = _a.isDisabledBanner, setIsDisabledBanner = _a.setIsDisabledBanner, disabledBanner = _a.disabledBanner, setGoogleRecaptchaConsent = _a.setGoogleRecaptchaConsent;
    var environment = useEnvironment();
    useEffect(function () {
        setIsDisabledBanner(CookiesConsentService.isDisableCookiesBanner());
    }, []);
    var handleCookiesModal = useCallback(function () {
        if (openCookiesModal && !isDisabledBanner) {
            disabledBanner();
        }
        handleCookiesModalContext();
    }, [openCookiesModal, isDisabledBanner, handleCookiesModalContext, disabledBanner]);
    var handleRefuseAll = useCallback(function () {
        if (environment === null || environment === void 0 ? void 0 : environment.recaptcha_site_key_invisible) {
            CookiesConsentService.refuseCookieConsent(COOKIE_CONSENT_GOOGLE_RECAPTCHA_NAME);
            setGoogleRecaptchaConsent(false);
        }
        disabledBanner();
    }, [environment, disabledBanner, setGoogleRecaptchaConsent]);
    var handleAcceptAll = useCallback(function () {
        if (environment === null || environment === void 0 ? void 0 : environment.recaptcha_site_key_invisible) {
            CookiesConsentService.acceptCookieConsent(COOKIE_CONSENT_GOOGLE_RECAPTCHA_NAME);
            setGoogleRecaptchaConsent(true);
        }
        disabledBanner();
    }, [environment, disabledBanner, setGoogleRecaptchaConsent]);
    return {
        isDisabledBanner: isDisabledBanner,
        handleCookiesModal: handleCookiesModal,
        handleRefuseAll: handleRefuseAll,
        handleAcceptAll: handleAcceptAll,
    };
};
