import ExpansionPanelPreview from "shared/components/contents/expansionPanel/ExpansionPanelPreview";
import ExpansionPanelIcon from "shared/components/icons/ExpansionPanelIcon";
import ContentType from "shared/enums/contentsTypes";
export var expansionPanel = {
    label: "Panneau dépliant",
    type: ContentType.EXPANSION_PANEL,
    IconComponent: ExpansionPanelIcon,
    Preview: ExpansionPanelPreview,
    editionModal: true,
    editionModalPosition: "top",
    requiredByKey: ["summary"],
    children: [
        {
            label: "Titre du paneau",
            key: "summary",
            type: ContentType.INPUT,
            value: "Titre",
        },
        {
            label: "details",
            key: "details",
            type: ContentType.EXPANSION_PANEL_DETAILS,
        },
    ],
};
