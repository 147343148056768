import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Box, Tab, Tabs } from "@mui/material";
import SwipeableViews from "react-swipeable-views-react-18-fix";
var TabsComponent = function (props) {
    var tabs = props.tabs;
    var tabsArray = (tabs === null || tabs === void 0 ? void 0 : tabs.tabs) || [];
    var _a = useState(0), value = _a[0], setValue = _a[1];
    var handleChange = function (event, newValue) {
        setValue(newValue);
    };
    if (!Array.isArray(tabsArray)) {
        tabsArray = [tabsArray];
    }
    return (_jsxs(Box, { children: [_jsx(Tabs, { value: value, onChange: handleChange, children: tabsArray === null || tabsArray === void 0 ? void 0 : tabsArray.map(function (tab) { return _jsx(Tab, { label: tab.title }, tab.id); }) }), _jsx(SwipeableViews, { index: value, children: tabsArray === null || tabsArray === void 0 ? void 0 : tabsArray.map(function (tab, index) { return (_jsx(Box, { role: "tabpanel", id: "".concat(tab.title, "-").concat(index), "aria-labelledby": "tab-".concat(tab.title, "-").concat(index), p: 1, children: tab.children })); }) })] }));
};
export default TabsComponent;
