import axios from "axios";
import { useQuery } from "@tanstack/react-query";
export default function useEnvironment() {
    var environment = useQuery({
        queryKey: ["environment"],
        queryFn: function () { return axios.get("/environment.json").then(function (res) { return res.data; }); },
        initialData: {},
    }).data;
    return environment;
}
