import { jsx as _jsx } from "react/jsx-runtime";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import SubPageContext from "shared/components/contents/subPage/SubPageContext";
import ArticleSubpage, { PrincipalArticleSubpage } from "templates/articles/ArticleSubpage";
var getSubTemplate = function (templateKey, index) { return (index === 0 ? PrincipalArticleSubpage : ArticleSubpage); };
var ArticlesTemplate = function (props) {
    var page = props.page;
    var contents = (page || {}).contents;
    var subpages = contents.subpages;
    return (_jsx(Container, { children: _jsx(Grid, { container: true, justifyContent: "center", spacing: 3, children: _jsx(SubPageContext.Provider, { value: {
                    getSubTemplate: getSubTemplate,
                }, children: subpages }) }) }));
};
export default ArticlesTemplate;
