import { jsx as _jsx } from "react/jsx-runtime";
import { Alert } from "@docaposte-agility/da-design-system";
import MuiSnackbar from "@mui/material/Snackbar";
import React, { useEffect } from "react";
var ERROR_STACK_INITIALIZATION_TIME = 5000;
export default function Snackbar(props) {
    var open = props.open, onClose = props.onClose, _a = props.duration, duration = _a === void 0 ? 3000 : _a, _b = props.anchorOrigin, anchorOrigin = _b === void 0 ? undefined : _b, queue = props.queue, handleErrorStack = props.handleErrorStack;
    var handleClose = React.useCallback(function (event, reason) {
        if (reason === "clickaway") {
            return;
        }
        onClose(event, reason);
    }, [onClose]);
    useEffect(function () {
        var id = setInterval(function () {
            open && handleErrorStack();
        }, ERROR_STACK_INITIALIZATION_TIME);
        return function () { return clearInterval(id); };
    }, [handleErrorStack, open]);
    return (_jsx(MuiSnackbar, { anchorOrigin: anchorOrigin, open: open, autoHideDuration: duration, message: queue.map(function (item, index) {
            return (_jsx(Alert, { onClose: handleClose, severity: item.severity, variant: "filled", sx: { width: "100%", margin: "15px 0" }, children: item.message }, index));
        }) }));
}
