import { jsx as _jsx } from "react/jsx-runtime";
import Page from "frontoffice/components/Page";
import { enhancePageVersionContents } from "shared/utils/templateUtils";
import CmsHooks from "shared/utils/register/CmsHooks";
import { HOOKS } from "shared/utils/register/definitions";
import generateTemplatePropsFromContents from "frontoffice/utils/templates/generateTemplatePropsFromContents";
import { usePageVersion } from "frontoffice/hooks/PageVersionHook";
var PAGE_NOT_FOUND = "404 Not Found";
var PageSwitcher = function (props) {
    var templatesSettings = props.templatesSettings;
    var _a = usePageVersion(), currentPageVersion = _a.currentPageVersion, loading = _a.loading;
    var NotFound = CmsHooks.getHook(HOOKS.PageSwitcher_404) || (function () { return PAGE_NOT_FOUND; });
    if (!currentPageVersion) {
        if (!loading) {
            return _jsx(NotFound, {});
        }
        return null;
    }
    var templateSettingsProps = {};
    if (templatesSettings) {
        var currentTemplateSettings = templatesSettings.find(function (c) { return c.key === currentPageVersion.template; });
        templateSettingsProps = currentTemplateSettings
            ? generateTemplatePropsFromContents(currentTemplateSettings.children || [])
            : {};
    }
    return _jsx(Page, { page: enhancePageVersionContents(currentPageVersion), templatesSettings: templateSettingsProps });
};
export default PageSwitcher;
