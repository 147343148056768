import ContentsTypes from "shared/enums/contentsTypes";
export var imageFile = {
    label: "Image",
    type: ContentsTypes.IMAGE_AS_FILE,
    icon: "image",
    children: [
        {
            label: "file",
            key: "file",
            type: ContentsTypes.FILE_IMAGE,
            value: "",
        },
        {
            label: "Texte alternatif",
            key: "alt",
            type: ContentsTypes.INPUT,
            value: "",
        },
        {
            label: "Titre (visible au survol)",
            key: "title",
            type: ContentsTypes.INPUT,
            value: "",
        },
    ],
};
