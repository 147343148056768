import ButtonPreview from "shared/components/contents/button/ButtonPreview";
import ContentsTypes from "shared/enums/contentsTypes";
export var button = {
    label: "Bouton",
    type: ContentsTypes.BUTTON,
    icon: "hand-pointer",
    Preview: ButtonPreview,
    editionModal: true,
    editionModalPosition: "top",
    children: [
        {
            label: "text",
            key: "text",
            type: ContentsTypes.INPUT,
            value: "Texte",
        },
        {
            label: "Couleur",
            key: "color",
            type: ContentsTypes.RADIO_GROUP,
            width: "50%",
            options: [
                { label: "Primaire", value: "primary" },
                { label: "Secondaire", value: "secondary" },
            ],
            value: "primary",
        },
        {
            label: "Style",
            key: "variant",
            type: ContentsTypes.RADIO_GROUP,
            width: "50%",
            options: [
                { label: "Principal", value: "contained", title: "Boutons d'actions principales (contained)" },
                { label: "Secondaire", value: "outlined", title: "Boutons d'actions secondaires (outlined)" },
            ],
            value: "contained",
        },
        {
            label: "Taille du bouton",
            key: "size",
            type: ContentsTypes.RADIO_GROUP,
            width: "50%",
            options: [
                { label: "Petit", value: "small" },
                { label: "Moyen", value: "medium" },
                { label: "Grand", value: "large" },
            ],
            value: "medium",
        },
        {
            label: "Prendre toute la largeur",
            key: "fullWidth",
            type: ContentsTypes.CHECKBOX,
            width: "50%",
            value: "false",
        },
        {
            key: "justifyContent",
            label: "Alignement",
            type: ContentsTypes.RADIO_GROUP,
            options: [
                { label: "À gauche", value: "flex-start" },
                { label: "Centré", value: "center" },
                { label: "À droite", value: "flex-end" },
            ],
            value: "flex-start",
        },
        {
            label: "Lien du bouton",
            key: "link",
            type: ContentsTypes.LINK,
            value: "",
        },
        {
            label: "Icone",
            key: "icon",
            type: ContentsTypes.ICON,
            value: "",
        },
    ],
};
