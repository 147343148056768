var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import Presentation from "templates/home/Presentation";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import SubPageContext from "shared/components/contents/subPage/SubPageContext";
import HomeSubpage from "templates/home/HomeSubpage";
var HomeSubpageReverse = function (props) { return _jsx(HomeSubpage, __assign({}, props, { reverse: true })); };
var getSubTemplate = function (templateKey, index) { return (index % 2 === 1 ? HomeSubpageReverse : HomeSubpage); };
var HomeTemplate = function (props) {
    var page = props.page;
    var contents = (page || {}).contents;
    var subpages = contents.subpages;
    return (_jsxs(Container, { children: [_jsx(Presentation, __assign({}, props)), _jsx(Divider, { sx: { mt: 8, mb: 3, color: "primary.light" } }), _jsx(Grid, { container: true, justifyContent: "center", children: _jsx(Grid, { item: true, xs: 12, md: 10, children: _jsx(SubPageContext.Provider, { value: { getSubTemplate: getSubTemplate }, children: _jsx(_Fragment, { children: subpages }) }) }) })] }));
};
export default HomeTemplate;
