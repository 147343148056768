import { Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
var Numeric = function (props) {
    var number = props.number;
    number = number.replace(",", ".");
    var intPart = number.split(".")[0];
    var _a = number.split("."), floatingPart = _a[1];
    // eslint-disable-next-line  @typescript-eslint/no-unused-vars
    intPart = intPart.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    return _jsxs(_Fragment, { children: ["intPart + (floatingPart ? `.$", floatingPart, "` : \"\")"] });
};
export default Numeric;
