import { jsx as _jsx } from "react/jsx-runtime";
import Accordion from "shared/components/contents/accordion/Accordion";
import Text from "shared/components/contents/text/Text";
import { v4 as uuidv4 } from "uuid";
var details = _jsx(Text, { children: "Dans cette zone vous serez libre d'ajouter diff\u00E9rents contenus" });
var panels = {
    summary: "Panneau dépliant",
    details: details,
    id: uuidv4(),
};
var ExpansionPanelPreview = function () {
    return _jsx(Accordion, { panels: panels });
};
export default ExpansionPanelPreview;
