import AdminPageVersionsService from "administration/services/AdminPageVersionsService";
import AdminPagesService from "administration/services/AdminPagesService";
import { useCallback } from "react";
import { useQuery } from "@tanstack/react-query";
import useAdminEditorStore from "administration/store/AdminEditorStore";
export var useHomePageLocation = function () {
    var siteId = useAdminEditorStore(function (state) { return ({ siteId: state.currentSiteId }); }).siteId;
    var _a = useQuery({
        queryKey: ["homepage", siteId],
        queryFn: function () {
            return AdminPageVersionsService.find({
                params: {
                    join: ["page"],
                    filter: ["path||eq||/", "page.siteId||eq||".concat(siteId), "page.parentId||isnull"],
                },
            });
        },
        enabled: siteId != null,
    }), homePage = _a.data, isLoading = _a.isLoading;
    var result;
    if (homePage != null) {
        result = homePage[0];
    }
    return { homePage: result, isLoading: isLoading };
};
export var useCurrentPage = function (config) {
    if (config === void 0) { config = { enabled: true }; }
    var _a = useAdminEditorStore(function (state) { return ({
        currentPageId: state.currentPageId,
        setCurrentPageId: state.setCurrentPageId,
    }); }), currentPageId = _a.currentPageId, setCurrentPageId = _a.setCurrentPageId;
    var setCurrentPage = useCallback(function (page) {
        setCurrentPageId(page.id);
    }, [setCurrentPageId]);
    var _b = useQuery({
        queryKey: ["page", currentPageId],
        queryFn: function () { return AdminPagesService.get(currentPageId); },
        enabled: currentPageId != null && (config === null || config === void 0 ? void 0 : config.enabled),
        staleTime: Infinity,
    }), data = _b.data, isError = _b.isError;
    if (isError && (config === null || config === void 0 ? void 0 : config.onError)) {
        config.onError();
    }
    return { setCurrentPage: setCurrentPage, currentPage: data };
};
