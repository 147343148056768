import ExpansionTextPreview from "shared/components/contents/expansionText/ExpansionTextPreview";
import ContentsTypes from "shared/enums/contentsTypes";
export var expansionText = {
    label: "Texte dépliant",
    type: ContentsTypes.EXPANSION_TEXT,
    icon: "chevron-down",
    editionModal: true,
    editionModalPosition: "top",
    Preview: ExpansionTextPreview,
    children: [
        {
            label: "Libellé replié",
            key: "reducedLabel",
            type: ContentsTypes.INPUT,
            value: "Voir plus",
        },
        {
            label: "Libellé déplié",
            key: "expandedLabel",
            type: ContentsTypes.INPUT,
            value: "Voir moins",
        },
        {
            label: "Déplié par défaut",
            key: "defaultExpanded",
            type: ContentsTypes.CHECKBOX,
            value: "false",
        },
        {
            label: "text",
            key: "text",
            type: ContentsTypes.TEXT,
        },
    ],
};
