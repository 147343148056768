import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/system/Box";
import BlockTitle from "shared/components/contents/blockTitle/BlockTitle";

const DescriptionBlock = (props) => {
  const { id, title, text, backgroundColor, ...others } = props;

  return (
    <Box
      key={id}
      sx={{
        bgcolor: backgroundColor,
        pt: 2,
        pb: 1,
        px: 1,
        borderRadius: 1.25,
      }}
      {...others}
    >
      <Box
        sx={{
          pb: 2,
          px: 4,
          borderStyle: "solid",
          borderRadius: 1.25,
          borderTopWidth: 2,
          borderLeftWidth: 2,
          borderRightWidth: 2,
          borderBottomWidth: 4,
          borderTopColor: "#D0D0FF",
          borderLeftColor: "#D0D0FF",
          borderRightColor: "#D0D0FF",
          borderBottomColor: "#FFC617",
        }}
      >
        {title && (
          <BlockTitle
            sx={{
              mt: -2.5,
              py: 1,
              "& h2": {
                bgcolor: backgroundColor,
              },
            }}
            text={title}
          />
        )}
        {text}
      </Box>
    </Box>
  );
};

DescriptionBlock.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.node,
  backgroundColor: PropTypes.string,
};

DescriptionBlock.defaultProps = {
  id: "",
  title: "",
  text: "",
  backgroundColor: "#FFFFFF",
};

export default DescriptionBlock;
