var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { sanitizeUrl } from "@braintree/sanitize-url";
import MuiLink from "@mui/material/Link";
import classnames from "classnames";
import { forwardRef } from "react";
import { HashLink } from "react-router-hash-link";
import { useHandleChangePageVersion } from "administration/hooks/AdminPageVersionHook";
import { componentClassName } from "shared/components/contents/link/Link";
import useAdminEditorStore from "administration/store/AdminEditorStore";
var RouterLink = forwardRef(function (props, ref) { return _jsx(HashLink, __assign({ innerRef: ref }, props)); });
var LinkEditor = forwardRef(function (props, ref) {
    var _a = props.url, url = _a === void 0 ? "" : _a, _b = props.page, page = _b === void 0 ? null : _b, _c = props.external, external = _c === void 0 ? false : _c, _d = props.title, title = _d === void 0 ? null : _d, _e = props.children, children = _e === void 0 ? null : _e, _f = props.onClick, onClick = _f === void 0 ? function () { return null; } : _f, _g = props.className, className = _g === void 0 ? "" : _g, others = __rest(props, ["url", "page", "external", "title", "children", "onClick", "className"]);
    var handleChangePageVersion = useHandleChangePageVersion().handleChangePageVersion;
    var _h = useAdminEditorStore(function (state) { return ({
        setCurrentPageId: state.setCurrentPageId,
        setCurrentPageVersionId: state.setCurrentPageVersionId,
    }); }), setCurrentPageId = _h.setCurrentPageId, setCurrentPageVersionId = _h.setCurrentPageVersionId;
    if (!url && !page) {
        return (_jsx("a", __assign({ onClick: function (e) { return e.preventDefault(); }, className: classnames(className, componentClassName) }, others, { children: children })));
    }
    var handleClickLink = function (e) {
        if (typeof onClick === "function") {
            onClick(e);
        }
        if (page && !external) {
            handleChangePageVersion(page);
            setCurrentPageId(page.pageId);
            setCurrentPageVersionId(page.id);
        }
    };
    var dynamicProps = {
        component: RouterLink,
        target: external ? "_blank" : undefined,
        rel: external ? "noopener noreferrer" : undefined,
    };
    var path = page ? page.fullPath || "/" : sanitizeUrl(url);
    if (external || !path.startsWith("/")) {
        dynamicProps["href"] = path;
        dynamicProps.component = "a";
    }
    else {
        dynamicProps["to"] = {
            pathname: "/editor",
            search: "",
            hash: "",
        };
    }
    var titleLink = title || (page ? page.title : undefined);
    // W3C: remove attribute "to" in span component
    if (typeof document !== "undefined") {
        var seeMoreLink = document.getElementById("spanLink");
        if (seeMoreLink) {
            seeMoreLink.removeAttribute("to");
        }
    }
    return (_jsx(MuiLink, __assign({ ref: ref, underline: "none", onClick: handleClickLink, color: "textPrimary" }, dynamicProps, { className: classnames(className, componentClassName), title: titleLink }, others, { children: children })));
});
export default LinkEditor;
