import CookiesConsentService, { COOKIE_CONSENT_GOOGLE_RECAPTCHA_NAME } from "shared/services/CookiesConsentService";
import { create } from "zustand";
var useCookieStore = create(function (set, get) { return ({
    openCookiesModal: false,
    setOpenCookiesModal: function (value) {
        set({ openCookiesModal: value });
    },
    handleCookiesModal: function () {
        set({ openCookiesModal: !get().openCookiesModal });
    },
    isDisabledBanner: true,
    setIsDisabledBanner: function (value) {
        set({ isDisabledBanner: value });
    },
    disabledBanner: function () {
        CookiesConsentService.disableCookiesBanner();
        set({ isDisabledBanner: true });
    },
    googleRecaptchaConsent: CookiesConsentService.getCookieConsent(COOKIE_CONSENT_GOOGLE_RECAPTCHA_NAME),
    setGoogleRecaptchaConsent: function (value) {
        set({ googleRecaptchaConsent: value });
    },
}); });
export default useCookieStore;
