var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useMatomo } from "@datapunt/matomo-tracker-react";
import useEnvironment from "shared/hooks/useEnvironment";
import CookiesConsentService, { COOKIE_CONSENT_GOOGLE_RECAPTCHA_NAME } from "shared/services/CookiesConsentService";
import { isCookieExist } from "shared/utils/cookieUtils";
import { useEffect, useMemo, useState } from "react";
import CmsHooks from "shared/utils/register/CmsHooks";
import { HOOKS } from "shared/utils/register/definitions";
import useCookieStore from "shared/store/CookieStore";
import { ContentLabelType, getDataStaticForConsent } from "../config/definitions";
var useConsentExisting = function () {
    var consentConfig = CmsHooks.getHook(HOOKS.CONSENT_CONFIG);
    if (consentConfig && !("consentData" in consentConfig)) {
        throw Error("Error, the basic configuration of cookies is mandatory to collect user consent");
    }
    return consentConfig;
};
var useCookiesModal = function () {
    var consentConfig = useConsentExisting();
    var environment = useEnvironment();
    var pushInstruction = useMatomo().pushInstruction;
    var _a = useCookieStore(function (state) { return ({
        openCookiesModal: state.openCookiesModal,
        googleRecaptchaConsent: state.googleRecaptchaConsent,
        setGoogleRecaptchaConsent: state.setGoogleRecaptchaConsent,
    }); }), openCookiesModal = _a.openCookiesModal, googleRecaptchaConsent = _a.googleRecaptchaConsent, setGoogleRecaptchaConsent = _a.setGoogleRecaptchaConsent;
    var _b = useState(false), isMatomoUserOptOut = _b[0], setIsMatomoUserOptOut = _b[1];
    useEffect(function () {
        setIsMatomoUserOptOut(isCookieExist("mtm_consent_removed"));
    }, []);
    var handleSwitchGoogleRecaptcha = function () {
        if (googleRecaptchaConsent) {
            CookiesConsentService.refuseCookieConsent(COOKIE_CONSENT_GOOGLE_RECAPTCHA_NAME);
        }
        else {
            CookiesConsentService.acceptCookieConsent(COOKIE_CONSENT_GOOGLE_RECAPTCHA_NAME);
        }
        setGoogleRecaptchaConsent(!googleRecaptchaConsent);
    };
    var handleSwitchMatomoUserOptOut = function () {
        if (isMatomoUserOptOut) {
            pushInstruction("forgetUserOptOut");
        }
        else {
            pushInstruction("optUserOut");
        }
        setIsMatomoUserOptOut(!isMatomoUserOptOut);
    };
    var consentData = useMemo(function () {
        var requiredCookiesRows = getDataStaticForConsent(ContentLabelType.LOCAL_STORAGE);
        var googleRecaptchaCookiesRows = [];
        if ((environment === null || environment === void 0 ? void 0 : environment.recaptcha_site_key_invisible) && consentConfig && consentConfig.captcha) {
            googleRecaptchaCookiesRows.push.apply(googleRecaptchaCookiesRows, consentConfig.captcha);
        }
        var matomoEnabled = (environment === null || environment === void 0 ? void 0 : environment.matomo_track_url) && (environment === null || environment === void 0 ? void 0 : environment.matomo_site_id);
        if (matomoEnabled && consentConfig && consentConfig.consentData) {
            requiredCookiesRows.push.apply(requiredCookiesRows, consentConfig.consentData);
        }
        return {
            googleRecaptchaCookiesRows: googleRecaptchaCookiesRows,
            googleRecaptchaConsent: googleRecaptchaConsent,
            requiredCookiesRows: requiredCookiesRows,
            isMatomoUserOptOut: isMatomoUserOptOut,
            matomoEnabled: matomoEnabled,
        };
    }, [consentConfig, environment, googleRecaptchaConsent, isMatomoUserOptOut]);
    return __assign(__assign({}, consentData), { openCookiesModal: openCookiesModal, handleSwitchGoogleRecaptcha: handleSwitchGoogleRecaptcha, handleSwitchMatomoUserOptOut: handleSwitchMatomoUserOptOut });
};
export default useCookiesModal;
