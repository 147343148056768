var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Grid";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import ImageContainer from "shared/components/contents/imageContainer/ImageContainer";
import Link from "shared/components/contents/link/Link";
var imageParentStyled = {
    position: "relative",
    "& > a": {
        borderRadius: 20,
        overflow: "hidden",
        display: "block",
    },
};
var tagStyled = {
    bgcolor: "#ecf4ff",
    color: "#00008c",
    borderRadius: "5px",
    p: 1,
    mr: 1,
    mb: 1,
    fontWeight: "600",
    fontSize: "1.2rem",
};
var ArticleSubpage = function (props) {
    var page = props.page, pageVersion = props.pageVersion, principal = props.principal;
    var _a = page || {}, image = _a.image, title = _a.title, shortDescription = _a.shortDescription, tags = _a.tags;
    return (_jsxs(_Fragment, { children: [_jsx(Grid, { item: true, md: principal ? 12 : 4, children: _jsxs(Grid, { spacing: 3, container: true, children: [image && (_jsx(Grid, { item: true, md: principal ? 5 : 12, xs: 12, children: _jsx(Box, { sx: imageParentStyled, children: _jsx(Link, { page: pageVersion, children: image && (_jsx(ImageContainer, { ratio: 59, children: _jsx(_Fragment, { children: image }) })) }) }) })), _jsx(Grid, { item: true, md: principal ? 5 : 12, xs: 12, children: _jsxs(Link, { page: pageVersion, children: [tags && tags.length > 0 && (_jsx(Box, { sx: { display: "flex", flexFlow: "wrap" }, children: tags.map(function (tag) { return (_jsx(Box, { component: "span", sx: tagStyled, children: tag }, tag)); }) })), title && (_jsx(Typography, { variant: "h3", sx: { fontSize: "1.75rem" }, children: title })), shortDescription && _jsx(Box, { sx: { my: 1 }, children: shortDescription })] }) })] }) }), principal ? (_jsx(Grid, { item: true, xs: 12, children: _jsx(Divider, { sx: { my: 3 } }) })) : ("")] }));
};
export default ArticleSubpage;
export var PrincipalArticleSubpage = function (props) { return _jsx(ArticleSubpage, __assign({}, props, { principal: true })); };
