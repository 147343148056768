var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import Box from "@mui/material/Box";
import MuiButton from "@mui/material/Button";
import Icon from "shared/components/Icon";
export var componentClassName = "MuiButton-root";
var Button = function (props) {
    var _a = props.justifyContent, justifyContent = _a === void 0 ? "flex-start" : _a, others = __rest(props, ["justifyContent"]);
    var icon = props.icon;
    if (typeof icon === "string") {
        icon = { icon: icon };
    }
    var btn = _jsx(MuiButton, __assign({ endIcon: icon && icon.icon && _jsx(Icon, __assign({}, icon, { title: "Voir" })) }, others));
    if (justifyContent && justifyContent !== "flex-start") {
        return (_jsx(Box, { display: "flex", justifyContent: justifyContent, children: btn }));
    }
    return btn;
};
export default Button;
