import { optionalHooks } from "./definitions";
var CmsHooksRegister = /** @class */ (function () {
    function CmsHooksRegister() {
    }
    Object.defineProperty(CmsHooksRegister, "register", {
        get: function () {
            return CmsHooksRegister.registry;
        },
        enumerable: false,
        configurable: true
    });
    CmsHooksRegister.registerHook = function (key, value) {
        if (CmsHooksRegister.hasHook(key)) {
            throw new Error("Hook ".concat(key, " already registered"));
        }
        CmsHooksRegister.registry[key] = value;
    };
    CmsHooksRegister.hasHook = function (key) {
        return CmsHooksRegister.registry.hasOwnProperty(key);
    };
    CmsHooksRegister.isOptionalHook = function (key) {
        return optionalHooks.includes(key);
    };
    CmsHooksRegister.registry = {
        Bo_AdminWebsitePath: "/editor",
        Bo_AdminUsersPath: "/users",
    };
    CmsHooksRegister.getHook = function (key) {
        try {
            if (!CmsHooksRegister.hasHook(key))
                throw new Error("Hook ".concat(key, " not registered"));
            return CmsHooksRegister.registry[key];
        }
        catch (error) {
            if (CmsHooksRegister.isOptionalHook(key)) {
                return null;
            }
            throw error;
        }
    };
    return CmsHooksRegister;
}());
export default CmsHooksRegister;
