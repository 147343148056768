import ContentsTypes from "shared/enums/contentsTypes";
import TrombinoscopeTemplate from "templates/trombinoscope/TrombinoscopeTemplate";
export default {
    key: "TROMBINOSCOPE",
    label: "Trombinoscope Vitrine",
    templateImage: "/template-trombinoscope.png",
    Component: TrombinoscopeTemplate,
    initialContents: [
        {
            key: "dynamicPart",
            type: ContentsTypes.DYNAMIC,
            value: "",
        },
    ],
};
