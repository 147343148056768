import ContentsTypes from "shared/enums/contentsTypes";
export var tabs = {
    type: ContentsTypes.TAB,
    label: "Onglet",
    children: [
        {
            key: "title",
            label: "Titre",
            type: ContentsTypes.INPUT,
            value: "",
        },
        {
            label: "children",
            key: "children",
            type: ContentsTypes.DYNAMIC,
        },
    ],
};
