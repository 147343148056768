import ContentsTypes from "shared/enums/contentsTypes";
export var galerieImage = {
    label: "Galerie d'images",
    type: ContentsTypes.IMAGES_GALLERY,
    icon: "images",
    editionModal: true,
    editionModalPosition: "top",
    children: [
        {
            label: "images",
            key: "images",
            type: ContentsTypes.IMAGES,
            requiredByKey: ["file"],
            children: [
                {
                    label: "image",
                    key: "image",
                    type: ContentsTypes.IMAGE_AS_FILE,
                },
            ],
        },
        {
            label: "Hauteur des images",
            key: "height",
            type: ContentsTypes.INPUT_NUMBER,
            value: "200",
        },
        {
            label: "Boucle infinie",
            key: "infinite",
            type: ContentsTypes.CHECKBOX,
            value: "true",
        },
        {
            label: "Diaporama automatique",
            key: "autoPlay",
            type: ContentsTypes.CHECKBOX,
            value: "true",
        },
        {
            label: "Intervalle entre les images (en millisecondes)",
            key: "slideInterval",
            type: ContentsTypes.INPUT_NUMBER,
            value: "3000",
        },
        {
            label: "Afficher les vignettes",
            key: "showThumbnails",
            type: ContentsTypes.CHECKBOX,
            value: "true",
        },
        {
            label: "Position des vignettes",
            key: "thumbnailPosition",
            type: ContentsTypes.SELECT,
            options: [
                { label: "En haut", value: "top" },
                { label: "À droite", value: "right" },
                { label: "En bas", value: "bottom" },
                { label: "À gauche", value: "left" },
            ],
            value: "bottom",
        },
        {
            label: "Afficher les boutons de navigation",
            key: "showNav",
            type: ContentsTypes.CHECKBOX,
            value: "true",
        },
        {
            label: "Afficher le numéro d'image",
            key: "showIndex",
            type: ContentsTypes.CHECKBOX,
            value: "false",
        },
        {
            label: "Afficher les balles",
            key: "showBullets",
            type: ContentsTypes.CHECKBOX,
            value: "true",
        },
        {
            label: "Afficher le bouton de lecture",
            key: "showPlayButton",
            type: ContentsTypes.CHECKBOX,
            value: "false",
        },
        {
            label: "Afficher le bouton d'affichage en plein écran",
            key: "showFullscreenButton",
            type: ContentsTypes.CHECKBOX,
            value: "false",
        },
    ],
};
