import ContentsTypes from "shared/enums/contentsTypes";
export var link = {
    label: "Lien",
    type: ContentsTypes.LINK,
    children: [
        {
            label: "Url",
            key: "url",
            type: ContentsTypes.URL,
            value: "",
        },
        {
            label: "Page",
            key: "page",
            type: ContentsTypes.PAGE,
            value: "",
        },
        {
            key: "title",
            label: "Titre du lien",
            type: ContentsTypes.INPUT,
            value: "",
        },
        {
            label: "Ouvrir dans un nouvel onglet",
            key: "external",
            type: ContentsTypes.CHECKBOX,
            value: "false",
        },
    ],
};
