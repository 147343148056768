export var getFilterStyle = function (filters) {
    if (filters === void 0) { filters = {}; }
    var activated = filters.activated, opacity = filters.opacity, blur = filters.blur, grayscale = filters.grayscale, brightness = filters.brightness, saturate = filters.saturate, sepia = filters.sepia;
    if (!activated) {
        return undefined;
    }
    return "opacity(".concat(opacity, ") blur(").concat(blur, "px) grayscale(").concat(grayscale, ") brightness(").concat(brightness, ") saturate(").concat(saturate, ") sepia(").concat(sepia, ")");
};
export var pageImageFiltersKey = "pageImageFilters";
export var mapSrcSet = function (publicUrlBySize) {
    return Object.entries(publicUrlBySize).map(function (_a) {
        var size = _a[0], url = _a[1];
        return "".concat(url, " ").concat(size, "w");
    });
};
