import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/system/Box";
import ImageContainer from "shared/components/contents/imageContainer/ImageContainer";
import BlockTitle from "shared/components/contents/blockTitle/BlockTitle";

const BlockWithTitle = (props) => {
  const { id, image, title, justify, backgroundColor, dynamicPart, ...others } = props;

  return (
    <Box
      key={id}
      sx={{
        bgcolor: backgroundColor,
        mt: image ? 8 : 2,
        pt: image ? 0 : 2.5,
        px: 5,
      }}
      {...others}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: justify,
          px: 2.5,
        }}
      >
        {image && (
          <Box sx={{ mt: -5.625, height: 90 }}>
            <ImageContainer ratio="">{image}</ImageContainer>
          </Box>
        )}
        {title && <BlockTitle sx={{ ml: 1 }} text={title} />}
      </Box>
      {dynamicPart}
    </Box>
  );
};

BlockWithTitle.propTypes = {
  id: PropTypes.string,
  image: PropTypes.shape(),
  title: PropTypes.string,
  justify: PropTypes.string,
  backgroundColor: PropTypes.string,
  dynamicPart: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
};

BlockWithTitle.defaultProps = {
  id: "",
  image: null,
  title: "",
  justify: "flex-start",
  backgroundColor: "#FFFFFF",
  dynamicPart: null,
};

export default BlockWithTitle;
