import { jsx as _jsx } from "react/jsx-runtime";
import { MenuItem } from "@mui/material";
import { FlagPicture } from "./FlagPicture";
import { StyleSiteTranslationSelection } from "./SiteTranslationSelection.style";
import { useTranslationSelection } from "./hook/useTranslationSelection";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MuiSelect from "@mui/material/Select";
export var SiteTranslationSelection = function () {
    var _a = useTranslationSelection(), handleToggleSelect = _a.handleToggleSelect, handleChange = _a.handleChange, open = _a.open, flagCountrySelect = _a.flagCountrySelect, siteList = _a.siteList;
    return (_jsx(StyleSiteTranslationSelection, { "data-testid": "translation-selection", children: _jsx(MuiSelect, { IconComponent: KeyboardArrowDownIcon, open: open, onClose: handleToggleSelect, onOpen: handleToggleSelect, value: flagCountrySelect === null || flagCountrySelect === void 0 ? void 0 : flagCountrySelect.lang, name: "lang", onChange: handleChange, inputProps: {
                id: "open-select",
            }, children: siteList === null || siteList === void 0 ? void 0 : siteList.map(function (flag, key) { return (_jsx(MenuItem, { value: flag.lang, children: _jsx(FlagPicture, { lang: flag.lang, title: flag.lang.toUpperCase(), hasWidthLabel: true }) }, key)); }) }) }));
};
