import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Typography } from "@mui/material";
import useTheme from "@mui/material/styles/useTheme";
import useMediaQuery from "@mui/material/useMediaQuery";
import ImageContainer from "shared/components/contents/imageContainer/ImageContainer";
var imageParentStyled = {
    position: "relative",
    borderRadius: "0 60px",
    m: "0 auto",
    overflow: "hidden",
    mb: 6.25,
};
var backgroundStyled = {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    bgcolor: "primary.dark",
    opacity: "0.4",
};
var titleStyled = {
    fontWeight: 600,
    fontSize: 32,
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
    "& h1": {
        color: "#FFFFFF",
    },
};
var ImageBanner = function (props) {
    var page = props.page;
    var title = page.title, image = page.image;
    var theme = useTheme();
    var isMobile = !useMediaQuery(theme.breakpoints.up("sm"));
    return (_jsxs(Box, { sx: imageParentStyled, children: [image && _jsx(ImageContainer, { ratio: isMobile ? 48 : 24, children: image }), _jsx(Box, { sx: backgroundStyled }), _jsx(Box, { sx: titleStyled, children: _jsx(Typography, { variant: "h1", children: title }) })] }));
};
ImageBanner.defaultProps = {
    page: {},
};
export default ImageBanner;
