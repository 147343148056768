var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { createElement as _createElement } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Icon from "shared/components/Icon";
import ImageContainer from "shared/components/contents/imageContainer/ImageContainer";
var PresentationBox = function (props) {
    var id = props.id, icon = props.icon, title = props.title, subtitle = props.subtitle, description = props.description, image = props.image, others = __rest(props, ["id", "icon", "title", "subtitle", "description", "image"]);
    return (_createElement("div", __assign({}, others, { key: id }),
        image && (_jsx(Box, { sx: { borderRadius: "60px 0", overflow: "hidden" }, children: _jsx(ImageContainer, { ratio: 64, children: image }) })),
        icon && _jsx(Icon, __assign({}, icon)),
        title && (_jsx(Typography, { variant: "h5", component: "h4", sx: { mb: "10px" }, children: title })),
        subtitle && (_jsx(Typography, { variant: "subtitle1", sx: { mb: "10px" }, children: subtitle })),
        description && (_jsx(Typography, { variant: "body1", sx: { color: "text.secondary", mb: "10px" }, children: description }))));
};
PresentationBox.defaultProps = {
    id: "",
    title: "",
    subtitle: "",
    description: "",
    icon: null,
    image: null,
};
export default PresentationBox;
