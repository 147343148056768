import ContentsTypes from "shared/enums/contentsTypes";
export var contentsGroupList = {
    label: "contents group",
    type: ContentsTypes.CONTENTS_GROUPS_LIST,
    value: "",
    children: [
        {
            label: "groups",
            type: ContentsTypes.CONTENTS_GROUP,
            key: "groups",
        },
    ],
};
