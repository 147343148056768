import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback } from "react";
import { Box } from "@mui/material";
import { StyleFlagPicture } from "./FlagPicture.style";
export var FlagPicture = function (_a) {
    var lang = _a.lang, title = _a.title, _b = _a.width, width = _b === void 0 ? 30 : _b, hasWidthLabel = _a.hasWidthLabel, _c = _a.isBo, isBo = _c === void 0 ? false : _c;
    var flagEmoji = useCallback(function () {
        return lang
            .toLowerCase()
            .split("")
            .map(function (letter) { return (letter.charCodeAt(0) % 32) + 0x1f1e5; })
            .map(function (emojiCode) { return String.fromCodePoint(emojiCode); })
            .join("");
    }, [lang]);
    return (_jsxs(StyleFlagPicture, { width: width, isbo: isBo.toString(), children: [hasWidthLabel && _jsx(Box, { children: title }), _jsx(Box, { children: flagEmoji() })] }));
};
FlagPicture.displayName = "FlagPicture";
