import AccordionPreview from "shared/components/contents/accordion/AccordionPreview";
import AccordionIcon from "shared/components/icons/AccordionIcon";
import ContentsTypesEnum from "shared/enums/contentsTypes";
export var accordion = {
    type: ContentsTypesEnum.ACCORDION,
    label: "Accordéon",
    IconComponent: AccordionIcon,
    Preview: AccordionPreview,
    editionModalPosition: "top",
    value: "",
    dynamicChildKey: "panels",
    children: [
        {
            label: "Panels",
            key: "panels",
            type: ContentsTypesEnum.ACCORDION_PANEL,
        },
    ],
};
