import axios from "axios";
var configs = {
    getPageInfos: function (pathname) { return ({
        url: "/getPageInfos",
        params: { pathname: pathname },
    }); },
    findPagesByTemplateKeys: function (templates) { return ({
        url: "/findPagesByTemplates",
        params: { templates: templates },
    }); },
};
var PageService = /** @class */ (function () {
    function PageService() {
    }
    PageService.getConfig = function (methodName, options) {
        var getter = configs[methodName];
        if (typeof getter === "function") {
            return getter(options);
        }
        return {};
    };
    PageService.getPageInfos = function (pathname) {
        return axios(configs.getPageInfos(pathname)).then(function (response) { return response.data; });
    };
    return PageService;
}());
export default PageService;
