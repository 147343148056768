import { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { queryParamSiteKey } from "shared/utils/queryParamsKeys";
import { createQueryParams } from "shared/utils/urlUtils";
import { isString } from "shared/utils/guards";
import { useSites } from "shared/hooks/SiteHook";
export var useTranslationSelection = function () {
    var historyPush = useHistory().push;
    var pathname = useLocation().pathname;
    var _a = useSites(), site = _a.currentSite, siteList = _a.allSites;
    var _b = useState(false), open = _b[0], setOpen = _b[1];
    var _c = useState(site), flagCountrySelect = _c[0], setFlagCountrySelect = _c[1];
    var handleToggleSelect = function (e) {
        e.preventDefault();
        setOpen(function (s) { return !s; });
    };
    var handleChange = function (e) {
        var _a;
        //e.preventDefault();
        var siteId = e.target.value;
        if (isString(siteId)) {
            var site_1 = siteList === null || siteList === void 0 ? void 0 : siteList.find(function (s) { return s.id === siteId; });
            site_1 && setFlagCountrySelect(site_1);
            historyPush("".concat(pathname, "?").concat(createQueryParams((_a = {},
                _a[queryParamSiteKey] = siteId,
                _a.lang = site_1 === null || site_1 === void 0 ? void 0 : site_1.lang,
                _a))));
        }
    };
    return {
        handleToggleSelect: handleToggleSelect,
        handleChange: handleChange,
        open: open,
        flagCountrySelect: flagCountrySelect,
        siteList: siteList,
    };
};
