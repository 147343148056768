export var Categories;
(function (Categories) {
    Categories["STRUCTURE"] = "STRUCTURE";
    Categories["SPECIFIC"] = "SPECIFIC";
})(Categories || (Categories = {}));
export var getCategoryLabel = function (category) {
    switch (category) {
        case Categories.STRUCTURE:
            return "Structure";
        case Categories.SPECIFIC:
            return "Composants spécifiques";
        default:
            return "Composants de base";
    }
};
export default Categories;
