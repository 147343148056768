import axios from "axios";

class ArticleService {
  static getRecentArticles = ({ template, pageId, perPage, pageParent: parentId, siteId, currentPath }) => {
    return axios
      .get("/getRecentArticles", { params: { template, pageId, perPage, parentId, siteId, currentPath } })
      .then((response) => {
        return response.data;
      });
  };
}

export default ArticleService;
