var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { pageVersionImageKey } from "administration/services/AdminPageVersionsService";
import CmsHooks from "shared/utils/register/CmsHooks";
import { HOOKS } from "./register/definitions";
export var getTemplateByKey = function (key) {
    return (CmsHooks.getHook(HOOKS.TEMPLATES) || []).find(function (template) { return template.key === key || key && template.key.toLowerCase() === key.toLowerCase(); }) || { Component: function () { return null; } };
};
export var getTemplateComponentByKey = function (key) {
    if (!key)
        return function () { return null; };
    var template = getTemplateByKey(key);
    return template ? template.Component : function () { return null; };
};
export var enhancePageVersionContents = function (pageVersion) {
    if (!pageVersion) {
        return null;
    }
    var templateKey = pageVersion.template, contents = pageVersion.contents;
    var template = getTemplateByKey(templateKey);
    var _a = template.initialContents, initialContents = _a === void 0 ? [] : _a;
    var pageVersionImage = contents === null || contents === void 0 ? void 0 : contents.find(function (child) { return child.key === pageVersionImageKey; });
    var pageVersionImageParsed = (pageVersionImage === null || pageVersionImage === void 0 ? void 0 : pageVersionImage.value) ? JSON.parse(pageVersionImage.value) : null;
    return __assign(__assign(__assign({}, pageVersion), (pageVersionImageParsed ? { image: __assign(__assign({}, pageVersionImageParsed), pageVersion.image) } : {})), { contents: initialContents.map(function (content) {
            var pageVersionContent = contents.find(function (c) { return c.key === content.key; }) || {};
            return __assign(__assign({}, content), pageVersionContent);
        }) });
};
