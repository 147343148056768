import { Box } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Slide from "@mui/material/Slide";
import useTheme from "@mui/material/styles/useTheme";
import useMediaQuery from "@mui/material/useMediaQuery";
import Icon from "shared/components/Icon";
import Link from "shared/components/contents/link/Link";
import { useMenu } from "shared/services/SectionService";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import SearchBar from "../SearchBar";
import NavigationItem from "./NavigationItem";
import generateTemplatePropsFromContents from "frontoffice/utils/templates/generateTemplatePropsFromContents";
import { isArray } from "shared/utils/guards";
import { useSites } from "shared/hooks/SiteHook";
import useGlobalStore from "shared/store/GlobalStore";
import LinkEditor from "administration/components/LinkEditor";
import { useHomePageLocation } from "administration/hooks/AdminPageHook";

const styleMenuItems = {
  display: "flex",
  alignItems: "center",
};

const styleMenuItemsMobile = {
  position: { xs: "fixed", md: "relative" },
  overflow: { xs: "hidden", md: "inherit" },
  flexDirection: { xs: "column", md: "row" },
  zIndex: { xs: 51, md: "auto" },
  bgcolor: { xs: "#F8FCFF", md: "inherit" },
  bottom: { xs: 0, md: "auto" },
  top: { xs: 0, md: "auto" },
  right: { xs: 0, md: "auto" },
  left: { xs: "100%", md: "auto" },
  textAlign: { xs: "center", md: "inherit" },
  px: 3,
  py: 1.25,
  overflowY: { xs: "auto", md: "inherit" },
  transition: { xs: "all .5s ease-in-out", md: "inherit" },
  boxShadow: { xs: "0px 0px 5px rgba(0,0,0,.2)", md: "inherit" },
};

const styleNavigationbar = {
  position: { md: "relative", xs: "fixed" },
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  minHeight: "64px",
  top: { xs: 0 },
  left: { xs: 0 },
  right: { xs: 0 },
  zIndex: { xs: 51 },
  bgcolor: { xs: "#F8FCFF", md: "white" },
  px: { xs: 3, md: 0 },
  py: { xs: 1.25, md: 2.5 },
  m: { xs: 0 },
};

const styleMenuMobile = {
  display: { md: "none", xs: "block" },
  color: "primary.main",
  fontSize: "1.5rem",
  cursor: "pointer",
};

const styleSearchBar = {
  width: "100%",
  ml: { xs: 0 },
  mt: { xs: "30px", md: 0 },
  minHeight: "inherit",
};

const styleMenuItemMobileActive = {
  left: { xs: 0 },
};

const Navigation = () => {
  const location = useLocation();
  const { pathname } = location;
  const [menuMobileActive, setMenuMobileActive] = useState(false);
  const [showSearchBar, setShowSearchBar] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  useEffect(() => {
    if (!isMobile && menuMobileActive) setMenuMobileActive(false);
  }, [isMobile, menuMobileActive, location]);

  const { currentSite: site } = useSites();
  const { menu } = useMenu(site.id);
  const { isBo } = useGlobalStore((state) => ({ isBo: state.isBo }));
  const { homePage } = useHomePageLocation();

  const menuContents = (menu && generateTemplatePropsFromContents(menu.contents)) || {};

  let menuItems = menuContents.menus || [];

  if (!isArray(menuItems)) {
    menuItems = [menuItems];
  }

  const handleSearchBar = () => {
    setShowSearchBar(!showSearchBar);
  };

  useEffect(() => {
    if (pathname !== "/rechercher") setShowSearchBar(false);
  }, [pathname, menuMobileActive]);

  const closeIcon = (
    <IconButton aria-label="Bouton de recherche" onClick={handleSearchBar} size="large">
      <Icon icon="times" type="fal" />
    </IconButton>
  );

  const { settings: siteSettings } = site || {};
  const { logoHeader = null } = siteSettings || {};

  return (
    <Box sx={{ ...styleNavigationbar }}>
      {isBo ? (
        <LinkEditor sx={{ width: "160px" }} page={homePage}>
          {logoHeader || <img alt="logo" src="/logo-agile-it.svg" />}
        </LinkEditor>
      ) : (
        <Link sx={{ width: "160px" }} url="/">
          {logoHeader || <img alt="logo" src="/logo-agile-it.svg" />}
        </Link>
      )}

      <Box sx={{ ...styleMenuMobile }}>
        <i className="fas fa-bars" onClick={() => setMenuMobileActive(true)} />
      </Box>
      <Box sx={{ ...styleMenuItems, ...styleMenuItemsMobile, ...(menuMobileActive && styleMenuItemMobileActive) }}>
        <Box
          sx={{ ...styleMenuMobile, textAlign: "right", transform: `translateY(${menuMobileActive ? "0" : "-100px"})` }}
        >
          <i className="fas fa-times" onClick={() => setMenuMobileActive(false)} />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: { md: "row", xs: "column" },
            alignItems: { md: "flex-end", xs: "center" },
          }}
        >
          {menuItems.map((menuItem) => (
            <NavigationItem key={menuItem.id} menuItem={menuItem} depth={0} />
          ))}
        </Box>
        <Box>
          {!showSearchBar && <Icon icon="search" type="fal" onClick={handleSearchBar} />}
          {showSearchBar && (
            <Slide direction={isMobile ? "up" : "left"} in={showSearchBar} mountOnEnter unmountOnExit>
              <Box sx={styleSearchBar}>
                <SearchBar closeIcon={closeIcon} />
              </Box>
            </Slide>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Navigation;
