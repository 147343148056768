import TimelinePreview from "shared/components/contents/timeLine/TimelinePreview";
import TimelineIcon from "shared/components/icons/TimelineIcon";
import ContentsTypes from "shared/enums/contentsTypes";
export var timeline = {
    label: "Historique",
    type: ContentsTypes.TIMELINE,
    editionModalPosition: "top",
    IconComponent: TimelineIcon,
    Preview: TimelinePreview,
    dynamicChildKey: "steps",
    children: [
        {
            label: "Étape",
            type: ContentsTypes.STEP_V2,
            key: "steps",
        },
    ],
};
