import home from "templates/home/home";
import dynamic from "templates/dynamic/dynamic";
import agileIT from "templates/agile-it/agile-it";
import articles from "templates/articles/articles";
import articleDetail from "templates/article-detail/article-detail";
import dynamicVitrine from "templates/dynamic-vitrine/dynamic-vitrine";
import trombinoscope from "templates/trombinoscope/trombinoscope";
import contact from "templates/contact/contact";
import onePage from "templates/one-page/one-page";
import documentation from "./documentation/documentation";
export default [
    home,
    dynamic,
    agileIT,
    articles,
    articleDetail,
    onePage,
    dynamicVitrine,
    trombinoscope,
    contact,
    documentation,
];
