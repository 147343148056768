import ContentsTypes from "shared/enums/contentsTypes";
export var tab = {
    label: "Onglets",
    type: ContentsTypes.TABS,
    children: [
        {
            label: "tabs",
            key: "tabs",
            type: ContentsTypes.TAB,
        },
    ],
};
