var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import Box from "@mui/material/Box";
var svgIconStyled = {
    display: "inline-block",
    textAlign: "center",
    height: "1em",
    width: "1em",
    "&> svg": {
        verticalAlign: "middle",
    },
};
export default (function (Component) {
    var SVGIconComponent = function (props) {
        return (_jsx(Box, { sx: svgIconStyled, children: _jsx(Component, __assign({}, props)) }));
    };
    return SVGIconComponent;
});
