import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ImageContainer from "shared/components/contents/imageContainer/ImageContainer";
import ArticleDetailSubpage from "templates/article-detail/ArticleDetailSubpage";
import SubpageSearchContext from "components/SubpageSearchContext";
import { Stack } from "@mui/material";
var tagStyled = {
    bgcolor: "#ecf4ff",
    color: "#00008c",
    borderRadius: "5px",
    p: 1,
    mr: 1,
    mb: 1,
    fontWeight: "600",
    fontSize: "1.2rem",
};
var DocumentationTemplate = function (props) {
    var page = props.page, subpageSearch = props.subpageSearch;
    var _a = page || {}, image = _a.image, title = _a.title, contents = _a.contents, tags = _a.tags;
    var dynamicPart = contents.dynamicPart;
    return (_jsx(Container, { children: _jsxs(Grid, { container: true, children: [_jsxs(Grid, { item: true, xs: 12, children: [_jsxs(Stack, { direction: { xs: "column-reverse", sm: "row" }, justifyContent: "space-between", alignItems: "center", children: [_jsxs(Stack, { children: [title && (_jsx(Typography, { variant: "h1", mb: 2, children: title })), _jsx(Box, { children: tags && tags.length > 0 && (_jsx(Box, { sx: { display: "flex", flexFlow: "wrap" }, children: tags.map(function (tag) { return (_jsx(Box, { component: "span", sx: tagStyled, children: tag }, tag)); }) })) })] }), _jsx(Box, { sx: { width: "350px", height: "auto" }, children: image && (_jsx(ImageContainer, { ratio: "", children: _jsx(_Fragment, { children: image }) })) })] }), _jsx(Box, { sx: { mt: 3 }, children: dynamicPart })] }), _jsx(Grid, { item: true, xs: 12, md: 4, children: _jsx(SubpageSearchContext.Provider, { value: { page: page, SubPageTemplate: ArticleDetailSubpage }, children: _jsx(_Fragment, { children: subpageSearch }) }) })] }) }));
};
export default DocumentationTemplate;
