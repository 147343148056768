import ContentsTypes from "shared/enums/contentsTypes";
export var imageBlock = {
    label: "Image",
    type: ContentsTypes.IMAGE_BLOCK,
    icon: "image",
    editionModal: true,
    editionModalPosition: "top",
    value: "",
    children: [
        {
            label: "image",
            key: "image",
            type: ContentsTypes.IMAGE,
            requiredByKey: ["file"],
        },
        {
            label: "Largeur de l'image (en %)",
            key: "width",
            type: ContentsTypes.SLIDER,
        },
        {
            label: "Largeur de l'image (en %) sur mobile",
            key: "mobileWidth",
            type: ContentsTypes.SLIDER,
        },
        {
            key: "justifyContent",
            label: "Alignement",
            type: ContentsTypes.SELECT,
            options: [
                { label: "À gauche", value: "flex-start" },
                { label: "Centré", value: "center" },
                { label: "À droite", value: "flex-end" },
            ],
            value: "flex-start",
        },
        {
            label: "Lien de l'image",
            key: "link",
            type: ContentsTypes.LINK,
        },
    ],
};
