var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
var TimelineStep = function (props) {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    var theme = useTheme();
    var id = props.id, _j = props.title, title = _j === void 0 ? null : _j, _k = props.content, content = _k === void 0 ? null : _k;
    return (_jsxs(Box
    // @ts-ignore //@fixme: Review typing once the new version of MUI is installed
    , { 
        // @ts-ignore //@fixme: Review typing once the new version of MUI is installed
        sx: __assign(__assign({}, (((_d = (_c = (_b = (_a = theme === null || theme === void 0 ? void 0 : theme.components) === null || _a === void 0 ? void 0 : _a.timeLineStepStyled) === null || _b === void 0 ? void 0 : _b.stepStyled) === null || _c === void 0 ? void 0 : _c.styleOverrides) === null || _d === void 0 ? void 0 : _d.root) || {})), { position: "relative" }), children: [_jsx(Box, { component: "span", 
                // @ts-ignore //@fixme: Review typing once the new version of MUI is installed
                sx: __assign(__assign({}, (_h = (_g = (_f = (_e = theme === null || theme === void 0 ? void 0 : theme.components) === null || _e === void 0 ? void 0 : _e.timeLineStepStyled) === null || _f === void 0 ? void 0 : _f.bubbleStyled) === null || _g === void 0 ? void 0 : _g.styleOverrides) === null || _h === void 0 ? void 0 : _h.root), { position: "absolute" }) }), _jsx(Box, { sx: {
                    fontWeight: 600,
                    pb: 0.5,
                }, children: title }), content] }, id));
};
export default TimelineStep;
