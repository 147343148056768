import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Block from "shared/components/Block";
import PageTitle from "components/PageTitle";
import SubPageContext from "shared/components/contents/subPage/SubPageContext";
import AgileItSubpage from "templates/agile-it/AgileItSubpage";
var AgileItTemplate = function (props) {
    var page = props.page;
    var _a = page || {}, shortDescription = _a.shortDescription, title = _a.title, contents = _a.contents;
    var subpages = contents.subpages;
    return (_jsxs(Container, { children: [_jsxs(Grid, { item: true, sx: { mb: 3 }, children: [_jsx(PageTitle, { children: _jsx("div", { children: title }) }), _jsx(Block, { children: shortDescription })] }), _jsx(Grid, { item: true, children: _jsx(Grid, { container: true, justifyContent: "center", children: _jsx(SubPageContext.Provider, { value: { subTemplate: AgileItSubpage }, children: subpages }) }) })] }));
};
export default AgileItTemplate;
