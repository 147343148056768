var ContentsTypes;
(function (ContentsTypes) {
    ContentsTypes["ACCORDION"] = "ACCORDION";
    ContentsTypes["ACCORDION_PANEL"] = "ACCORDION_PANEL";
    ContentsTypes["ATTACHMENT"] = "ATTACHMENT";
    ContentsTypes["BUTTON"] = "BUTTON";
    ContentsTypes["BLOCKTITLE"] = "BLOCKTITLE";
    ContentsTypes["CARD"] = "CARD";
    ContentsTypes["CODE_BLOCK"] = "CODE_BLOCK";
    ContentsTypes["CODE_BLOCK_TEXT"] = "CODE_BLOCK_TEXT";
    ContentsTypes["COLOR"] = "COLOR";
    ContentsTypes["CONTAINER"] = "CONTAINER";
    ContentsTypes["CONTENTS_GROUP"] = "CONTENTS_GROUP";
    ContentsTypes["CONTENTS_GROUPS_LIST"] = "CONTENTS_GROUPS_LIST";
    ContentsTypes["CHECKBOX"] = "CHECKBOX";
    ContentsTypes["DYNAMIC"] = "DYNAMIC";
    ContentsTypes["EDITABLE_INPUT"] = "EDITABLE_INPUT";
    ContentsTypes["EXPANSION_PANEL"] = "EXPANSION_PANEL";
    ContentsTypes["EXPANSION_PANEL_DETAILS"] = "EXPANSION_PANEL_DETAILS";
    ContentsTypes["EXPANSION_TEXT"] = "EXPANSION_TEXT";
    ContentsTypes["FILE"] = "FILE";
    ContentsTypes["FILE_IMAGE"] = "FILE_IMAGE";
    ContentsTypes["GLOSSARY"] = "GLOSSARY";
    ContentsTypes["GLOSSARY_ITEM"] = "GLOSSARY_ITEM";
    ContentsTypes["GRID"] = "GRID";
    ContentsTypes["IMAGE"] = "IMAGE";
    ContentsTypes["IMAGE_AS_FILE"] = "IMAGE_AS_FILE";
    ContentsTypes["IMAGE_BLOCK"] = "IMAGE_BLOCK";
    ContentsTypes["IMAGES"] = "IMAGES";
    ContentsTypes["IMAGES_GALLERY"] = "IMAGES_GALLERY";
    ContentsTypes["IMAGE_FILTERS"] = "IMAGE_FILTERS";
    ContentsTypes["ICON"] = "ICON";
    ContentsTypes["INFO_BLOCK"] = "INFO_BLOCK";
    ContentsTypes["INFO_BLOCK_LIST"] = "INFO_BLOCK_LIST";
    ContentsTypes["INPUT"] = "INPUT";
    ContentsTypes["INPUT_NUMBER"] = "INPUT_NUMBER";
    ContentsTypes["LINK"] = "LINK";
    ContentsTypes["RADIO_GROUP"] = "RADIO_GROUP";
    ContentsTypes["MARGIN"] = "MARGIN";
    ContentsTypes["MENU_ITEM"] = "MENU_ITEM";
    ContentsTypes["MENU_GROUP"] = "MENU_GROUP";
    ContentsTypes["NUMERIC"] = "NUMERIC";
    ContentsTypes["PAGE"] = "PAGE";
    ContentsTypes["SELECT"] = "SELECT";
    ContentsTypes["SELECT_GRID"] = "SELECT_GRID";
    ContentsTypes["SHORT_TEXT"] = "SHORT_TEXT";
    ContentsTypes["STEP"] = "STEP";
    ContentsTypes["STEP_V2"] = "STEP_V2";
    ContentsTypes["STEP_V2_CONTENT"] = "STEP_V2_CONTENT";
    ContentsTypes["TABS"] = "TABS";
    ContentsTypes["TAB_CARDS"] = "TAB_CARDS";
    ContentsTypes["TAB"] = "TAB";
    ContentsTypes["TABS_CARDS"] = "TABS_CARDS";
    ContentsTypes["TEXT"] = "TEXT";
    ContentsTypes["TIMELINE"] = "TIMELINE";
    ContentsTypes["TEMPLATES_SETTINGS"] = "TEMPLATES_SETTINGS";
    ContentsTypes["TEMPLATE_SETTINGS"] = "TEMPLATE_SETTINGS";
    ContentsTypes["SITE_SETTINGS"] = "SITE_SETTINGS";
    ContentsTypes["SLIDER"] = "SLIDER";
    ContentsTypes["SOCIAL_NETWORK"] = "SOCIAL_NETWORK";
    ContentsTypes["SUBPAGES_LIST"] = "SUBPAGES_LIST";
    ContentsTypes["SUBPAGE"] = "SUBPAGE";
    ContentsTypes["URL"] = "URL";
    ContentsTypes["VIDEO"] = "VIDEO";
    ContentsTypes["WARNING_BANNER"] = "WARNING_BANNER";
    ContentsTypes["TEAM_UNIT"] = "TEAM_UNIT";
    ContentsTypes["PRESENTATION_CARD"] = "PRESENTATION_CARD";
    ContentsTypes["DESCRIPTION_CARD_WITH_LOGO"] = "DESCRIPTION_CARD_WITH_LOGO";
    ContentsTypes["TABS_COMPONENT"] = "TABS_COMPONENT";
})(ContentsTypes || (ContentsTypes = {}));
export default ContentsTypes;
