import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Stack, Typography } from "@mui/material";
import { useHistory, generatePath } from "react-router";
import { useCurrentPage } from "administration/hooks/AdminPageHook";
import useGlobalStore from "shared/store/GlobalStore";
var DescriptionCardWithLogo = function (props) {
    var logo = props.logo, _a = props.title, title = _a === void 0 ? "" : _a, _b = props.description, description = _b === void 0 ? "" : _b, redirection = props.redirection;
    var history = useHistory();
    var isBo = useGlobalStore(function (state) { return ({ isBo: state.isBo }); }).isBo;
    var setCurrentPage = useCurrentPage({ enabled: isBo }).setCurrentPage;
    var handleClick = function () {
        isBo ? setCurrentPage(redirection.page) : history.push(generatePath(redirection.path));
    };
    return (_jsxs(Stack, { flexDirection: "row", sx: {
            backgroundColor: "#fff",
            px: 2,
            py: 3.5,
            width: "380px",
            borderRadius: 1,
            boxShadow: "0px 4px 4px rgb(0 0 0 / 25%)",
            ":hover": { cursor: "pointer" },
        }, onClick: redirection ? handleClick : undefined, children: [_jsx(Box, { sx: { width: 64, height: 64, mr: 2 }, children: logo }), _jsxs(Stack, { sx: { maxWidth: "260px" }, children: [_jsx(Typography, { variant: "h1", mb: 0.5, color: "#000000", sx: { textOverflow: "ellipsis", overflow: "hidden" }, children: title }), _jsx(Typography, { variant: "subtitle2", color: "#666D92", sx: { textOverflow: "ellipsis", overflow: "hidden" }, children: description })] })] }));
};
export default DescriptionCardWithLogo;
