import ContainerPreview from "shared/components/contents/container/ContainerPreview";
import ContainerIcon from "shared/components/icons/ContainerIcon";
import categories from "shared/enums/categoriesContents";
import ContentsTypes from "shared/enums/contentsTypes";
export var container = {
    label: "Conteneur",
    type: ContentsTypes.CONTAINER,
    category: categories.STRUCTURE,
    IconComponent: ContainerIcon,
    editionModal: true,
    editionModalPosition: "top",
    Preview: ContainerPreview,
    dynamicChildKey: "containerElement",
    children: [
        {
            key: "justifyContent",
            label: "Alignement",
            type: ContentsTypes.SELECT,
            options: [
                { label: "À gauche", value: "flex-start" },
                { label: "Centré", value: "center" },
                { label: "À droite", value: "flex-end" },
            ],
            value: "center",
        },
        {
            label: "Largeur du contenu (en %)",
            key: "width",
            type: ContentsTypes.SLIDER,
            value: "50",
            min: "0",
            max: "100",
            step: "10",
        },
        {
            label: "Largeur du contenu (en %) sur mobile",
            key: "mobileWidth",
            type: ContentsTypes.SLIDER,
            min: "0",
            max: "100",
            step: "10",
        },
    ],
};
