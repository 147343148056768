var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { theme as daDesignSystemTheme } from "@docaposte-agility/da-design-system";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import MatomoCMSPageTracking from "frontoffice/components/MatomoCMSPageTracking";
import PageSwitcher from "frontoffice/components/PageSwitcher";
import CookiesBanner from "shared/components/cookies/CookiesBanner";
import Snackbar from "shared/components/Snackbar";
import ContentsTypes from "shared/enums/contentsTypes";
import { Route, Switch } from "react-router-dom";
import { MatomoContextProvider } from "./contexts/MatomoContextProvider";
import CmsHooks from "shared/utils/register/CmsHooks";
import { HOOKS } from "shared/utils/register/definitions";
import useSnackbarStore from "shared/store/SnackbarStore";
import { useSites } from "shared/hooks/SiteHook";
var App = function () {
    var _a, _b, _c, _d;
    var Layout = CmsHooks.getHook(HOOKS.App_Layout) || (function (_a) {
        var children = _a.children;
        return children;
    });
    var routes = CmsHooks.getHook(HOOKS.App_routes);
    var projectTheme = CmsHooks.getHook(HOOKS.THEME);
    var getTheme = CmsHooks.getHook(HOOKS.GET_THEME);
    var withTracking = CmsHooks.getHook(HOOKS.TRACKING_ACTIVE);
    var site = useSites().currentSite;
    var snackbar = useSnackbarStore(function (state) { return state; });
    var contents = site.contents, name = site.name;
    var templatesSettings = contents && contents.filter(function (template) { return template.type === ContentsTypes.TEMPLATES_SETTINGS; });
    var theme = (typeof getTheme === "function" && getTheme({ site: site })) || projectTheme || createTheme();
    return (_jsx(ThemeProvider, { theme: createTheme({
            components: {
                MuiAlert: (_a = daDesignSystemTheme === null || daDesignSystemTheme === void 0 ? void 0 : daDesignSystemTheme.components) === null || _a === void 0 ? void 0 : _a.MuiAlert,
                MuiAlertTitle: (_b = daDesignSystemTheme === null || daDesignSystemTheme === void 0 ? void 0 : daDesignSystemTheme.components) === null || _b === void 0 ? void 0 : _b.MuiAlertTitle,
                MuiSnackbarContent: (_c = daDesignSystemTheme === null || daDesignSystemTheme === void 0 ? void 0 : daDesignSystemTheme.components) === null || _c === void 0 ? void 0 : _c.MuiSnackbarContent,
                MuiSnackbar: (_d = daDesignSystemTheme === null || daDesignSystemTheme === void 0 ? void 0 : daDesignSystemTheme.components) === null || _d === void 0 ? void 0 : _d.MuiSnackbar,
            },
        }, theme), children: _jsx(MatomoContextProvider, { children: _jsxs(_Fragment, { children: [withTracking && _jsx(MatomoCMSPageTracking, {}), _jsxs(_Fragment, { children: [_jsx(CookiesBanner, { name: name }), _jsx(Snackbar, __assign({}, snackbar, { onClose: snackbar.handleCloseSnackbar })), _jsx(Layout, { children: _jsxs(Switch, { children: [routes && routes.map(function (route) { return _jsx(Route, __assign({}, route), route.path); }), _jsx(Route, { path: "/", children: _jsx(PageSwitcher, { templatesSettings: templatesSettings }) })] }) })] })] }) }) }));
};
export default App;
