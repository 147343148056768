var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
import Icon from "shared/components/Icon";
var InfoBlock = function (props) {
    var _a, _b, _c, _d;
    var theme = useTheme();
    var _e = props.icon, icon = _e === void 0 ? null : _e, _f = props.text, text = _f === void 0 ? null : _f;
    return (_jsxs(Grid, { container: true, children: [icon && (_jsx(Grid, { item: true, 
                // @ts-ignore //@fixme: Review typing once the new version of MUI is installed
                sx: __assign(__assign({}, (_d = (_c = (_b = (_a = theme.components) === null || _a === void 0 ? void 0 : _a.infoBlockStyled) === null || _b === void 0 ? void 0 : _b.iconStyled) === null || _c === void 0 ? void 0 : _c.styleOverrides) === null || _d === void 0 ? void 0 : _d.root), { display: { xs: "none", md: "flex" } }), children: _jsx(Icon, __assign({}, icon, { title: "Information" })) })), _jsx(Grid, { item: true, xs: true, sx: {
                    bgcolor: "#fff",
                    p: { md: 4 },
                }, children: text })] }));
};
export default InfoBlock;
