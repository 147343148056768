import { jsx as _jsx } from "react/jsx-runtime";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
var Loader = function () {
    return (_jsx(Box, { sx: {
            "& .MuiCircularProgress-circle": {
                stroke: "#3787FF",
            },
        }, children: _jsx(CircularProgress, { size: 36 }) }));
};
export default Loader;
