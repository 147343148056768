import React, { useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import Toolbar from "@mui/material/Toolbar";
import { useHistory, useLocation } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { createQueryParams } from "shared/utils/urlUtils";
import Icon from "shared/components/Icon";
import Autosuggest from "components/Autosuggest";
import Link from "shared/components/contents/link/Link";
import SearchService from "shared/services/SearchService";
import useSnackbarStore from "shared/store/SnackbarStore";
import { useSites } from "shared/hooks/SiteHook";

const inputStyled = {
  bgcolor: "white",
  border: `1px solid #D0D0FF`,
  px: 2,
  py: 0.25,
  fontSize: "1em",
  "& input": {
    fontSize: "1.1rem",
  },
  "& button": {
    mr: -1.5,
    color: "primary.dark",
  },
};

const autoSuggestStyled = (theme) => ({
  container: {
    width: "100%",
    position: "relative",
    padding: 0,
  },
  suggestionsContainer: {
    position: "absolute",
    left: 0,
    right: 0,
    backgroundColor: theme.palette.common.white,
    border: `1px solid #D0D0FF`,
    borderBottom: "none",
    borderTop: "none",
    zIndex: 100,
  },
  suggestionsList: {
    listStyleType: "none",
    paddingLeft: 0,
    margin: 0,
  },
});

const suggestionStyled = {
  p: 1,
  borderBottom: `1px solid #D0D0FF`,
  "&:hover, &[aria-selected=true]": {
    bgcolor: "secondary.light",
  },
};

const SearchBar = (props) => {
  const { closeIcon } = props;

  const { pathname, search } = useLocation();
  const history = useHistory();
  const [value, setValue] = useState("");
  const theme = useTheme();

  const { displayError } = useSnackbarStore((state) => ({
    displayError: state.displayError,
  }));

  const { currentSite: site } = useSites();

  const goToSearchPage = () => {
    if (value) {
      // onSearch();
      history.push({
        pathname: `/rechercher`,
        search: `?${createQueryParams({ q: value })}`,
        state: { prevPath: pathname + search },
      });
    }
  };

  const handleIconClick = () => {
    goToSearchPage();
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      goToSearchPage();
    }
  };

  const getSuggestions = async (newValue) => {
    try {
      const sugg = await SearchService.search({ query: newValue, from: 0, size: 5, siteId: site.id });
      const { hits } = sugg || {};
      const { hits: results } = hits || {};
      return (results && Array.isArray(results) && results.map((res) => res._source)) || [];
    } catch (e) {
      displayError(
        "Le service de recherche est actuellement indisponible. Veuillez nous excuser pour la gêne occasionnée.",
      );
      return [];
    }
  };

  const getSuggestionValue = (suggestion) => suggestion.title;

  const renderSuggestion = (suggestion) => {
    let breadcrumb = suggestion.breadcrumb.map((b) => b.title);
    if (breadcrumb && breadcrumb.length > 3) {
      breadcrumb = [breadcrumb[0], breadcrumb[1], "...", breadcrumb[breadcrumb.length - 1]];
    }
    const subtitle = breadcrumb.join(` / `);
    return (
      <Box sx={{ px: 2, py: 0.5, ...suggestionStyled }}>
        <Link page={suggestion}>
          <b>{suggestion.title}</b>
          <Box sx={{ fontSize: "0.875rem", mt: 0.5, fontWeight: 300 }}>{subtitle}</Box>
        </Link>
      </Box>
    );
  };

  const onChange = (event, { newValue }) => {
    setValue(newValue);
  };

  const onSuggestionSelected = () => {
    // onSearch();
    setValue("");
  };

  const renderInputComponent = (inputProps) => (
    <Input
      inputProps={{
        "aria-label": "Barre de recherche",
      }}
      role="combobox"
      aria-expanded="false"
      autoFocus
      sx={inputStyled}
      disableUnderline
      endAdornment={
        <InputAdornment position="end">
          <IconButton
            sx={{ fontSize: "1.2rem" }}
            aria-label="Bouton de recherche"
            onClick={handleIconClick}
            size="large"
          >
            <Icon icon="search" title="Rechercher" />
          </IconButton>
          {closeIcon}
        </InputAdornment>
      }
      onKeyDown={handleKeyDown}
      {...inputProps}
      fullWidth
    />
  );

  const inputProps = {
    placeholder: "Lancez votre recherche",
    value,
    onChange,
    onKeyDown: handleKeyDown,
  };

  // Accessibilité: remove un role="listbox" non pertinent
  if (typeof document !== "undefined") {
    const suggestContainer = document.getElementById("react-autowhatever-1");
    if (suggestContainer) {
      suggestContainer.removeAttribute("role");
    }
  }

  return (
    <Toolbar disableGutters sx={{ width: "100%", position: "relative", p: 0 }}>
      <Autosuggest
        getSuggestions={getSuggestions}
        debounce={300}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        renderInputComponent={renderInputComponent}
        onSuggestionSelected={onSuggestionSelected}
        inputProps={inputProps}
        classes={autoSuggestStyled(theme)}
        noResult={
          <Box style={{ p: 0.5, fontSize: "0.9rem", ...suggestionStyled }}>
            Aucun résultat pour la recherche : <b>&laquo;{value}&raquo;</b>
          </Box>
        }
      />
    </Toolbar>
  );
};

SearchBar.propTypes = {
  closeIcon: PropTypes.node,
};

SearchBar.defaultProps = {
  closeIcon: null,
};

export default SearchBar;
