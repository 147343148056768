import React from "react";
import PropTypes from "prop-types";
import ImageContainer from "shared/components/contents/imageContainer/ImageContainer";
import Box from "@mui/system/Box";
import Container from "@mui/material/Container";
import PageTitle from "components/PageTitle";

const TrombinoscopeTemplate = (props) => {
  const { page } = props;

  const { image, contents, title } = page;

  const { dynamicPart } = contents;

  return (
    <>
      {image && (
        <Box sx={{ bgcolor: "#F5F5F5" }}>
          <Container>
            <Box
              sx={{
                position: "relative",
                mb: 6.25,
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  height: { xs: 390, md: 500 },
                  overflow: "hidden",
                }}
              >
                <ImageContainer ratio="">{image}</ImageContainer>
              </Box>
            </Box>
          </Container>
        </Box>
      )}
      <Container>
        <PageTitle>{title}</PageTitle>
        {dynamicPart}
      </Container>
    </>
  );
};

TrombinoscopeTemplate.propTypes = {
  page: PropTypes.shape(),
};

TrombinoscopeTemplate.defaultProps = {
  page: {},
};

export default TrombinoscopeTemplate;
