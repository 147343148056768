import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Grid, Typography } from "@mui/material";
import ImageContainer from "shared/components/contents/imageContainer/ImageContainer";
var imageParentStyled = {
    position: "relative",
    display: "flex",
    justifyContent: "flex-end",
    borderRadius: "0 60px",
    overflow: "hidden",
    boxShadow: "0px 4px 5px rgb(0 0 0 / 25%)",
};
var Presentation = function (props) {
    var page = props.page;
    var _a = page || {}, title = _a.title, shortDescription = _a.shortDescription, image = _a.image, contents = _a.contents;
    var presentationDynamic = contents.presentationDynamic, subtitle = contents.subtitle;
    return (_jsxs(Grid, { container: true, justifyContent: "space-between", direction: "row-reverse", spacing: 2, children: [_jsx(Grid, { item: true, xs: 12, md: 5, children: image && (_jsx(Box, { sx: imageParentStyled, children: _jsx(ImageContainer, { ratio: 75, children: _jsx(_Fragment, { children: image }) }) })) }), _jsxs(Grid, { item: true, xs: 12, md: 6, children: [subtitle && (_jsx(Typography, { variant: "subtitle1", component: "h2", children: subtitle })), _jsx(Typography, { variant: "h1", children: title }), _jsx(Box, { sx: { my: 3.75 }, children: shortDescription }), presentationDynamic] })] }));
};
export default Presentation;
