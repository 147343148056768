import { getDynamicComponent } from "shared/utils/templatePropsUtils";
var generateTemplatePropsFromContents = function (contents) {
    var props = {};
    if (Array.isArray(contents)) {
        contents.forEach(function (content) {
            var value = content.value;
            var key = content.key, children = content.children, type = content.type, id = content.id;
            if (children && children.length) {
                value = generateTemplatePropsFromContents(children);
                if (id != null) {
                    value.id = id;
                }
            }
            var formattedValue = getDynamicComponent(type, value, id, contents.filter(function (c) { return c.key === key; }).indexOf(content));
            // if key already exists, then it will be an array
            if (typeof props[key] !== "undefined") {
                if (!Array.isArray(props[key])) {
                    props[key] = [props[key]];
                }
                props[key].push(formattedValue);
            }
            else {
                props[key] = formattedValue;
            }
        });
    }
    return props;
};
export default generateTemplatePropsFromContents;
