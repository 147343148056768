import ContentsTypes from "shared/enums/contentsTypes";
import OnePageTemplate from "templates/one-page/OnePageTemplate";
export default {
    key: "ONE-PAGE",
    label: "Page",
    templateImage: "/template-dynamic.png",
    Component: OnePageTemplate,
    initialContents: [
        {
            key: "dynamicPart",
            type: ContentsTypes.DYNAMIC,
            value: "",
        },
    ],
};
